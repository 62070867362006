import ApiClient from '@/assets/js/ApiClient.js'
import Crud from '../../crud'
import { i18n } from '@/main.js'

var crud = new Crud('results/benchmarks')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    customAddView: () => 'results/AddEditBenchmark',
    customEditView: () => 'results/AddEditBenchmark',
    canClone: () => () => true,
    detailActions () {
      return [
        { label: 'Means aktualisieren', action: 'calculateMean' }
      ]
    },
    title: (state, getters) => () => {
      const item = getters.detailItem
      if (item) {
        return item.name
      } else {
        return i18n.t('results.benchmarks')
      }
    },
    fields () {
      return [
        { text: i18n.t('description'), width: 300, value: 'name' },
        {
          text: 'Bezeichnung im Chart',
          help: 'Sichtbare Bezeichnung auf Chart',
          width: 200,
          value: 'name_chart',
          hideList: true,
          type: 'translate'
        },
        {
          text: 'Messungen',
          width: 200,
          value: 'measures_name'
        },
        {
          text: 'Kontextmenu',
          width: 200,
          value: 'benchmark_measures_names'
        },
        {
          text: 'Filterwerte',
          value: 'filter',
          width: -1,
          type: 'json',
          renderItem: (value) => {
            var tmp = String()
            Object.keys(value).forEach((key, index) => {
              if (index >= 1) {
                tmp += ' - '
              }
              Object.keys(value[key]).forEach((item, index) => {
                if (index === 1) {
                  tmp += ':'
                }
                tmp += `${value[key][item]}`
              })
            })
            return tmp
          }
        },
        { text: i18n.t('created-at'), value: 'created', format: 'DateTime', width: 150, readOnly: true, hideEdit: true, hideCreate: true },
        { text: i18n.t('modified'), value: 'modified', format: 'DateTime', width: 150, readOnly: true, hideEdit: true, hideCreate: true }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions,
    async calculateMean ({ dispatch }, primaryKey) {
      return ApiClient.put(`/results/benchmarks/${primaryKey}/calculate/`).then(response => {
        dispatch('load', { root: true })
        return response.data
      }).catch((err) => {
        dispatch('addNotification', { title: 'Fehler', message: err.response.data.error, error: true, permanent: true }, { root: true })
      })
    }
  }
}
