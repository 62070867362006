<style type="text/css">
  .apexcharts-legend{
    flex-direction: row;
  }
</style>
<template>
  <div class="border-2 p-2 h-full w-full bg-white dark:bg-chart-dark dark:border-neutral-600" v-view.once="onceHandler">
    <VueApexCharts ref="chart" v-if="visible || print"  width="100%" height="100%" :options="chartOptions" :series="series"></VueApexCharts>
    <chart-menu v-if="readOnly === false" :chart="chart"></chart-menu>
    <div v-if="!visible" class="bg-neutral-200 animate-pulse h-full w-full dark:bg-neutral-800"></div>
    <div v-if="this.data.length === 0" class="absolute top-1/2 left-1/2 -translate-x-1/2 text-lg font-bold">
      {{ $t('no-data') }}
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { clone, uniqBy, round } from 'lodash'
import { mapGetters } from 'vuex'
import utilsMixin from '@/mixins/utils'
import ChartMenu from '@/components/charts/ChartMenu.vue'

export default {
  mixins: [utilsMixin],
  name: 'LikertChart',
  components: {
    VueApexCharts,
    ChartMenu
  },
  props: {
    chart: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      default: 'de'
    },
    annotations: {
      type: Object,
      required: false,
      default: () => {}
    },
    isEditedChart: {
      type: Boolean,
      default: false
    },
    print: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      totals: {},
      apexChart: null,
      visible: false,
      seriesData: [],
      width: null,
      isDarkMode: false
    }
  },
  computed: {
    ...mapGetters({
      filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId'
    }),
    data () {
      return this.filteredDataForChartId(this.chart.i, this.chart.filters)
    },
    series () {
      return this.seriesData
    },
    isSplit () {
      return this.chart.splits && this.chart.splits.length > 0
    },
    selectedSeries () {
      return this.chart.data
    },
    splitSeries () {
      return this.chart.splits.length > 0 ? this.chart.splits[0].data : []
    },
    canLikert () {
      return this.chart.config !== undefined
    },
    colorMinus10 () {
      return this.chart.viewSettings?.indexOf('color_less_10') > -1
    },
    stacked () {
      return this.chart.viewSettings?.indexOf('stacked_likert') > -1
    },
    viewSettings () {
      return this.chart.viewSettings
    },
    config () {
      return this.chart.config
    },
    answerKeys () {
      if (this.isSplit) {
        var tmp = null
        if (this.chart.data[0].keys) {
          tmp = this.chart.data.map(d => d.keys).flat()
        } else {
          tmp = this.chart.data
        }
        const keys = tmp.reduce(function (acc, value) {
          if (acc[value.measure.id] === undefined) {
            acc[value.measure.id] = []
          }
          acc[value.measure.id].push(value.key)
          return acc
        }, {})
        return keys
      }
      return this.chart.answerKeys
    },
    getTitle () {
      return this.splitTitle(this.title, this.getWidth, { fontSize: '20px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    getSubTitle () {
      return this.splitTitle(this.subtitle, this.getWidth, { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    colorSheme () {
      return this.isDarkMode ? 'dark' : 'light'
    },
    chartOptions () {
      return {
        title: {
          text: this.getTitle,
          align: 'center',
          offsetY: this.readOnly ? 0 : 35,
          margin: 35,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#333333'
          }
        },
        subtitle: {
          text: this.getSubTitle,
          align: 'center',
          margin: this.getSubTitle ? 5 : 0,
          offsetX: 0,
          offsetY: this.getTitle.length * 25 + (this.readOnly ? 25 : 55),
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#777777'
          }
        },
        theme: {
          mode: this.colorSheme
        },
        chart: {
          id: 'basic-bar',
          type: 'bar',
          stacked: true,
          fontFamily: 'Nunito, sans-serif',
          toolbar: {
            show: false,
            tools: {
              offsetX: -30
            }
          },
          events: {
            mounted: (chart) => {
              if (this.$refs.chart === undefined) return
              this.width = this.$refs.chart.$el.clientWidth
              chart.windowResizeHandler()

              this.fixLabelOrder(chart)
              this.apexChart = chart
            },
            updated: (chartContext) => {
              if (this.$refs.chart === undefined) return
              this.width = this.$refs.chart.$el.clientWidth
              this.fixLabelOrder(chartContext)
            }
          }
        },
        colors: this.colors,
        xaxis: {
          categories: this.data.length > 0 ? this.categories : [],
          tickAmount: this.data.length > 0 ? 10 : 0,
          labels: {
            formatter: function (val) {
              return Math.abs(Math.round(val)) + '%'
            },
            minHeight: 45
          }
        },
        yaxis: {
          min: this.stacked ? 0 : -100,
          max: 100,
          labels: {
            maxWidth: 500,
            style: {
              fontSize: '13px'
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return Math.abs(val) + '%'
            }
          },
          x: {
            formatter: (val, { dataPointIndex, seriesIndex }) => {
              const total = Object.values(this.totals[dataPointIndex]).reduce((a, b) => a + b, 0)
              if (this.isSplit) {
                return `${this.$t('count')}: ${this.totals[dataPointIndex][seriesIndex]}, Total: ${total}`
              } else {
                const title = this.answers[dataPointIndex][seriesIndex]
                return `${title} (${this.$t('count')}: ${this.totals[dataPointIndex][seriesIndex]}, Total: ${total})`
              }
            }
          }
        },
        fill: {
          colors: [
            ({ seriesIndex, dataPointIndex }) => {
              const total = Object.values(this.totals[dataPointIndex]).reduce((a, b) => a + b, 0)
              if (this.colorMinus10 && total < 10) {
                const rgb = this.hexToRgb(this.colors[seriesIndex])
                const v = (rgb.r + rgb.g + rgb.b) / 3
                return this.rgbToHex(v, v, v)
              } else {
                return this.colors[seriesIndex]
              }
            }
          ]
        },
        legend: {
          show: true,
          showForSingleSeries: true
        },
        dataLabels: {
          enabled: false
        },
        annotations: this.annotations,
        stroke: {
          width: 1,
          colors: [this.colorSheme === 'dark' ? '#aaa' : '#fff']
        },
        grid: {
          borderColor: this.colorSheme === 'dark' ? '#555' : '#90A4AE',
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '85%'
          }
        }
      }
    },
    colors () {
      const colorShade = (col, amt) => {
        col = col.replace(/^#/, '')
        if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

        let [r, g, b] = col.match(/.{2}/g);
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

        r = Math.max(Math.min(255, r), 0).toString(16)
        g = Math.max(Math.min(255, g), 0).toString(16)
        b = Math.max(Math.min(255, b), 0).toString(16)

        const rr = (r.length < 2 ? '0' : '') + r
        const gg = (g.length < 2 ? '0' : '') + g
        const bb = (b.length < 2 ? '0' : '') + b

        return `#${rr}${gg}${bb}`
      }

      const config = Object.values(this.config)[0]

      var tmp = []
      var value = 0
      var amout = round(100 / config.negativeValues.length)

      config.negativeValues.forEach(() => {
        tmp.push(colorShade('#cc374d', value))
        value = value - amout
      })
      if (this.stacked) {
        tmp.reverse()
      }
      value = 0
      amout = round(100 / config.positiveValues.length)
      config.positiveValues.forEach(() => {
        tmp.push(colorShade('#008FFB', value))
        value = value - amout
      })
      return tmp
    },
    categories () {
      var result = []
      const tmpSeries = this.isSplit ? this.splitSeries : this.selectedSeries
      const hasMultipleMeasures = uniqBy(tmpSeries, v => v.measure ? v.measure.id : 0).length > 1

      tmpSeries.forEach(v => {
        var tmpValue = {}
        if (v.keys) {
          tmpValue.label = v.title
        } else {
          tmpValue = v
        }
        var label = this.translate(tmpValue, 'label', this.language) || v.key
        if (hasMultipleMeasures && v.measure) {
          label = `${v.measure.name}: ${label}`
        }
        var tmp = this.splitter(label, 60)
        result.push(tmp)
      })

      return result
    },
    title () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'title', this.language)
      } else {
        return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
      }
    },
    subtitle () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'subtitle', this.language)
      } else {
        return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
      }
    },
    answers () {
      var objects = []
      Object.entries(this.config).forEach(([key, value]) => {
        var neg = clone(value.negativeValues)
        if (!this.stacked) {
          neg.reverse()
        }

        const tmp = neg.concat(value.positiveValues)
        objects.push({ measure: parseInt(key), values: tmp })
      })

      var result = []

      this.selectedSeries.forEach((serie) => {
        var labels = []
        var attributes = []
        if (serie.keys) {
          attributes = serie.keys
        } else {
          attributes = [serie]
        }

        objects.forEach(obj => {
          obj.values.forEach(value => {
            attributes.forEach(attr => {
              const entry = attr.data.find(d => d.measure.id === obj.measure && String(d.value) === String(value))
              if (entry !== undefined) {
                labels.push(this.translate(entry, 'label', this.language) || entry.key)
              }
            })
          })
        })
        result.push(labels)
      })
      this.selectedSeries.filter(s => s.data === undefined).forEach((data) => {
        var labels = []
        data.keys.forEach((serie) => {
          objects.forEach(obj => {
            obj.values.forEach(value => {
              const entry = serie.data.find(d => d.measure.id === obj.measure && String(d.value) === String(value))
              if (entry !== undefined) {
                labels.push(this.translate(entry, 'label', this.language) || entry.key)
              }
            })
          })
        })
        result.push(labels)
      })
      return result
    },

    getWidth () {
      return this.width
    },
    maxAnswers () {
      return this.config.negativeValues.length + this.config.positiveValues.length
    }
  },
  methods: {
    setupData () {
      if (!this.visible || this.data.length <= 0) {
        this.seriesData = []
        return
      }
      const config = Object.values(this.config)[0]
      var result = []
      const tmpSeries = this.isSplit ? this.splitSeries : this.selectedSeries
      // prepare negative values
      config.negativeValues.forEach((v, ix) => {
        var obj = {}
        obj.name = [...new Set(this.answers.map(a => a[ix]))].join(' | ')
        obj.data = Array(tmpSeries.length).fill(0)
        result.push(obj)
      })

      // prepare positive values
      config.positiveValues.forEach((v, ix) => {
        var obj = {}
        obj.name = [...new Set(this.answers.map(a => a[ix + config.negativeValues.length]))].join(' | ')
        obj.data = Array(tmpSeries.length).fill(0)
        result.push(obj)
      })

      tmpSeries.forEach((serie, ix) => {
        if (this.isSplit) {
          this.aggregateSplitData(result, ix, serie)
        } else {
          var total = 0
          this.totals[ix] = {}
          // Has Dimensonen?
          if (serie.keys) {
            serie.keys.forEach((k) => {
              total = this.aggregateData(result, ix, k, total)
            })
          } else {
            total = this.aggregateData(result, ix, serie, total)
          }
          result.forEach(v => {
            v.data[ix] = round(((v.data[ix] / total) * 100), 1)
          })
        }
      })
      this.seriesData = result
    },
    onceHandler () {
      this.visible = true
    },
    hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null
    },
    rgbToHex (r, g, b) {
      return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).split('.')[0]
    },
    getImgURI () {
      return this.apexChart.dataURI().then(({ imgURI }) => {
        return imgURI
      })
    },
    fixLabelOrder (chart) {
      if (this.stacked) return
      const legends = chart.el.querySelectorAll('.apexcharts-legend-series')
      // swap negativ values legends
      const max = Object.values(this.config)[0].negativeValues.length
      var order = max
      legends.forEach((legend, index) => {
        legend.style.order = order > 0 ? order : index + 1
        order -= 1
      })
    },
    aggregateData (result, serieIx, serie, total) {
      const attKey = serie.key

      this.data.forEach(value => {
        const measure = value.measure
        if (serie.answerKeys[measure]) {
          serie.answerKeys[measure].forEach((answerKey) => {
            if (this.config[measure] && value.attributes[answerKey] !== null) {
              const isInNeg = this.config[measure].negativeValues.includes(String(value.attributes[answerKey]))
              const isInPos = this.config[measure].positiveValues.includes(String(value.attributes[answerKey]))
              if ((isInNeg || isInPos) && answerKey === attKey) {
                total += 1
                var ix = -1
                if (isInNeg) {
                  if (this.stacked) {
                    ix = this.config[measure].negativeValues.indexOf(String(value.attributes[answerKey]))
                    result[ix].data[serieIx] += 1
                  } else {
                    ix = this.config[measure].negativeValues.length - 1 - this.config[measure].negativeValues.indexOf(String(value.attributes[answerKey]))
                    result[ix].data[serieIx] -= 1
                  }
                } else {
                  ix = this.config[measure].negativeValues.length + this.config[measure].positiveValues.indexOf(String(value.attributes[answerKey]))
                  result[ix].data[serieIx] += 1
                }
                this.totals[serieIx][ix] = (parseInt(this.totals[serieIx][ix]) || 0) + 1
              }
            }
          })
        }
      })
      return total
    },
    aggregateSplitData (result, serieIx, serie) {
      var total = 0
      const attKey = serie.key
      const attValue = String(serie.value)
      const attCustomFunc = serie.customFunc
      this.totals[serieIx] = {}
      this.data.forEach(value => {
        const attrKeyValue = String(value.attributes[attKey])
        if (attrKeyValue === attValue || (attCustomFunc && attCustomFunc(attrKeyValue))) {
          const measure = value.measure
          if (this.answerKeys[measure] && this.config[measure]) {
            this.answerKeys[measure].forEach((answerKey) => {
              const answer = String(value.attributes[answerKey])
              if (value.attributes[answerKey] !== null) {
                const isInNeg = this.config[measure].negativeValues.includes(answer)
                const isInPos = this.config[measure].positiveValues.includes(answer)

                if ((isInNeg || isInPos) && ((attCustomFunc && attCustomFunc(value.attributes[attKey])) || (attrKeyValue === attValue))) {
                  total += 1
                  var ix = -1
                  if (isInNeg) {
                    if (this.stacked) {
                      ix = this.config[measure].negativeValues.indexOf(answer)
                      result[ix].data[serieIx] += 1
                    } else {
                      ix = this.config[measure].negativeValues.length - 1 - this.config[measure].negativeValues.indexOf(answer)
                      result[ix].data[serieIx] -= 1
                    }
                  } else {
                    ix = this.config[measure].negativeValues.length + this.config[measure].positiveValues.indexOf(answer)
                    result[ix].data[serieIx] += 1
                  }

                  this.totals[serieIx][ix] = (parseInt(this.totals[serieIx][ix]) || 0) + 1
                }
              }
            })
          }
        }
      })
      result.forEach(v => {
        v.data[serieIx] = round(((v.data[serieIx] / total) * 100), 1)
      })
    },
    transpose (a) {
      return Object.keys(a[0]).map(c => {
        return a.map(r => { return r[c] })
      })
    },
    // swap negative values for display
    swapNegativeValues (a, middle) {
      var tmp = clone(a)
      tmp = tmp.slice(0, middle + 1).reverse().concat(a.slice(middle + 1))
      return tmp
    }
  },
  mounted () {
    this.setupData()
    this.isDarkMode = document.documentElement.classList.contains('dark')
  },
  watch: {
    selectedSeries: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    config: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    splitSeries: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    viewSettings: {
      handler () {
        this.setupData()
      }
    },
    data: {
      handler () {
        this.setupData()
      }
    },
    visible: {
      handler () {
        this.setupData()
      }
    }
  }
}
</script>
