<style type="text/css">
  .apexcharts-bar-area {
    margin-top: 30px;
  }
</style>
<template>
  <div class="border-2 p-2 h-full w-full bg-white dark:bg-chart-dark dark:border-neutral-600" v-view.once="onceHandler">
    <VueApexCharts v-if="(visible || print)  && !hideGraphic" ref="chart"  width="100%" height="100%" :options="chartOptions" :series="series"></VueApexCharts>
    <chart-menu v-if="visible && readOnly === false" :chart="chart"></chart-menu>
    <div v-if="!visible" class="bg-neutral-200 animate-pulse h-full w-full dark:bg-neutral-800"></div>
    <div v-if="this.data.length === 0 || hideGraphic" class="absolute top-1/2 left-1/2 -translate-x-1/2 text-lg font-bold">
      {{ $t('no-data') }}
    </div>
    <div v-if="showCategoryLimitModal" class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-start z-50">
    <div class="absolute w-full h-full bg-neutral-900 opacity-50"></div>
    <div class="bg-white dark:bg-neutral-900 w-[40%] mx-auto rounded shadow-lg z-50 overflow-y-auto mt-32">
      <div class="modal-content py-4 text-left px-6">
        <h2 class="font-semibold">
          Das Limit der darstellbaren Kategorien für diesen Grafiktyp wurde überschritten.
        </h2>
        <p class="text-sm">
          Bitte wählen Sie einen anderen Grafiktyp oder passen Sie die für die Grafik benutzten Attribute an.
        </p>
        <button class="my-2" @click="showCategoryLimitModal = false">Ok</button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { clone, uniqBy, round } from 'lodash'
import { mapGetters } from 'vuex'
import utilsMixin from '@/mixins/utils'
import ChartMenu from '@/components/charts/ChartMenu.vue'
export default {
  mixins: [utilsMixin],
  name: 'MeanChart',
  components: {
    VueApexCharts,
    ChartMenu
  },
  props: {
    chart: {
      type: Object,
      required: true
    },
    isEditedChart: {
      type: Boolean,
      default: false
    },
    annotations: {
      type: Object,
      required: false,
      default: () => {}
    },
    language: {
      type: String,
      default: 'de'
    },
    print: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      height: 0,
      width: 0,
      visible: false,
      maxValue: 0,
      hasSameScale: false,
      seriesData: [],
      baseColors: ['#00E396', '#008FFB', '#FEB019', '#FF4560', '#775DD0', '#3F51B5', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B'],
      benchmarkColors: ['#775DD0', '#76b5c5', '#1e81b0'],
      isDarkMode: false,
      showCategoryLimitModal: false,
      hideGraphic: false,
      MAX_CATEGORIES: 15
    }
  },
  computed: {
    ...mapGetters({
      resultBenchmarkForMeasureId: 'results/evaluationStore/resultBenchmarkForMeasureId',
      benchmarkNames: 'results/evaluationStore/benchmarkNames',
      filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId'
    }),
    data () {
      return this.filteredDataForChartId(this.chart.i, this.chart.filters)
    },
    groups () {
      return this.chart.groups
    },
    chartType () {
      return this.chart.type
    },
    isRadar () {
      return this.chartType === 'radar'
    },
    viewSettings () {
      return this.chart.viewSettings
    },
    series () {
      return this.seriesData
    },
    isScaleActive () {
      return !this.hasSameScale
    },
    title () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'title', this.language)
      } else {
        return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
      }
    },
    subtitle () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'subtitle', this.language)
      } else {
        return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
      }
    },
    isSplit () {
      return this.chart.splits.length > 0
    },
    selectedSeries () {
      return this.chart.data
    },
    splitSeries () {
      return this.chart.splits.length > 0 ? this.chart.splits[0].data : []
    },
    horizontal () {
      return this.chart.viewSettings?.indexOf('horizontal') > -1
    },
    showDatalabels () {
      return this.chart.viewSettings?.indexOf('datalabels') > -1
    },
    canLikert () {
      return this.chart.config !== undefined
    },
    colorMinus10 () {
      return this.chart.viewSettings?.indexOf('color_less_10') > -1
    },
    config () {
      return this.chart.config
    },
    answerKeys () {
      return this.chart.answerKeys
    },
    showBenchmark () {
      return this.chart.viewSettings?.indexOf('benchmark') > -1
    },
    showErrorBars () {
      return !this.isRadar && this.chart.viewSettings?.indexOf('error_bars') > -1
    },
    chartHeight () {
      return this.height
    },
    getTitle () {
      return this.splitTitle(this.title, this.getWidth, { fontSize: '20px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    getSubTitle () {
      return this.splitTitle(this.subtitle, this.getWidth, { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    colorSheme () {
      return this.isDarkMode ? 'dark' : 'light'
    },
    hasSkala () {
      const arrayofObjects = this.chart.data
      const allObjectsMeetCondition = arrayofObjects.every(obj => obj.skala === true)
      return allObjectsMeetCondition
    },
    chartOptions () {
      return {
        title: {
          text: this.getTitle,
          align: 'center',
          offsetY: this.readOnly ? 0 : 35,
          margin: 35,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#333333'
          }
        },
        subtitle: {
          text: this.getSubTitle,
          align: 'center',
          margin: this.getSubTitle ? 5 : 0,
          offsetX: 0,
          offsetY: this.getTitle.length * 25 + (this.readOnly ? 25 : 55),
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#777777'
          }
        },
        theme: {
          mode: this.colorSheme
        },
        chart: {
          id: 'basic-bar',
          type: this.isRadar ? 'radar' : 'bar',
          fontFamily: 'Nunito, sans-serif',
          toolbar: {
            show: false,
            offsetX: -30
          },
          events: {
            mounted: (chart) => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              chart.windowResizeHandler()
            },
            updated: () => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              if (this.showErrorBars) {
                this.buildErrorBars()
              }
            }
          }
        },
        colors: this.colors,
        xaxis: {
          min: this.meanMin,
          max: this.data.length > 0 ? this.meanMax : 0,
          tickAmount: this.data.length > 0 ? (this.meanMax > 10 ? this.meanMaxLabel / 4 : this.meanMaxLabel) : 0,
          labels: {
            minHeight: 40,
            offsetY: this.isRadar ? -1 : 0,
            style: {
              fontFamily: 'Nunito, sans-serif',
              // !!! for radar -> all attributes needs a color
              colors: this.colorSheme === 'dark' ? Array(this.categories.length).fill('#fff') : Array(this.categories.length).fill('#000')
            }
          }
        },
        yaxis: {
          min: 0,
          max: this.meanMax,
          tickAmount: this.meanMax,
          labels: {
            maxWidth: 'auto',
            style: {
              fontSize: '13px'
            }
          }
        },
        fill: {
          opacity: this.isRadar ? 0.1 : 1.0,
          colors: this.isRadar ? undefined : [
            ({ seriesIndex, dataPointIndex }) => {
              if (this.colorMinus10 && this.seriesData[seriesIndex].data[dataPointIndex].total < 10) {
                return '#a6a6a6'
              } else {
                return this.colors[seriesIndex]
              }
            }
          ]
        },
        legend: {
          show: true,
          horizontalAlign: 'right',
          showForSingleSeries: true,
          customLegendItems: this.customLegendItems,
          markers: {
            fillColors: this.colors
          }
        },
        dataLabels: {
          enabled: this.showDatalabels,
          offsetX: this.isRadar ? 0 : -6
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val, { dataPointIndex, seriesIndex, series }) => {
              if (this.hasSkala === true) {
                return val
              }
              if (this.hasSkala !== true) {
                if (series === undefined || this.seriesData[seriesIndex].data[dataPointIndex].total < 1) {
                  return val
                }
                return `${val} (${this.$t('count')}: ${this.seriesData[seriesIndex].data[dataPointIndex].total})`
              }
            }
          },
          shared: !this.isRadar,
          intersect: !!this.isRadar
        },
        stroke: {
          show: true,
          width: this.isRadar ? 3 : 1,
          colors: this.isRadar ? undefined : [this.colorSheme === 'dark' ? '#aaa' : '#fff']
        },
        grid: {
          borderColor: this.colorSheme === 'dark' ? '#555' : '#90A4AE',
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        plotOptions: {
          radar: {
            size: 150,
            offsetX: 0,
            offsetY: 0,
            polygons: {
              strokeColors: '#e8e8e8',
              strokeWidth: 1,
              connectorColors: '#e8e8e8'
            }
          },
          bar: {
            horizontal: true,
            barHeight: '60%',
            columnWidth: '60%',
            dataLabels: {
              position: 'top'
            }
          }
        },
        annotations: this.getAnnotations
      }
    },
    customLegendItems () {
      var tmp = [this.$t('mean', this.language)]
      if (this.chart.groups.length) {
        tmp = []
        this.chart.groups.forEach(group => {
          group.data.forEach(v => {
            tmp.push(this.translate(v, 'label', this.language))
          })
        })
        const compare = this.seriesData.map(_ => _.name)
        if (compare.length) {
          tmp = tmp.filter(_ => compare.includes(_))
        }
      }

      if (this.showBenchmark) {
        tmp = [...tmp, ...this.benchmarkNames(this.language)]
      }
      if (this.showErrorBars) {
        tmp = [...tmp, this.$t('confidenzintervalls', this.language)]
      }
      return tmp
    },

    colors () {
      var tmp = [this.baseColors[0]]
      if (this.chart.groups.length) {
        tmp = []
        this.chart.groups.forEach((group) => {
          group.data.forEach((_, ix) => {
            tmp.push(this.baseColors[ix])
          })
        })
      }

      if (this.seriesData?.length) {
        tmp = []
        this.seriesData.forEach((group, ix) => {
          tmp.push(this.baseColors[ix])
        })
      }

      if (this.showBenchmark) {
        this.benchmarkNames(this.language).forEach((_, ix) => {
          tmp.push(this.benchmarkColors[ix])
        })
      }
      if (this.showErrorBars) {
        tmp.push('#aaaaaa')
      }
      return tmp
    },
    getAnnotations () {
      var tmp = {
        texts: []
      }

      if (this.hasSkala !== true) {
        if (this.answers.length && !this.isScaleActive) {
          tmp.texts = [{
            x: 50,
            y: this.chartHeight - 20,
            text: `${this.meanMin}  = ${[...new Set(this.answers.map(a => a[0]))].join(' | ')}`,
            appendTo: '.apexcharts-annotations',
            fontWeight: 'bold'
          },
          {
            x: 50,
            y: this.chartHeight - 5,
            text: `${this.meanMax} = ${[...new Set(this.answers.map(a => a[this.meanMaxLabel]))].join(' | ')}`,
            appendTo: '.apexcharts-annotations',
            fontWeight: 'bold'
          }]
        }
      }
      if (this.annotations.texts !== undefined) {
        tmp.texts = [...tmp.texts, ...this.annotations.texts]
      }
      return tmp
    },
    categories () {
      var result = []
      const hasMultipleMeasures = uniqBy(this.selectedSeries, v => v.measure ? v.measure.id : 0).length > 1
      this.selectedSeries.forEach(v => {
        var label = ''
        if (v.label) {
          label = this.translate(v, 'label', this.language) || v.key
        } else {
          label = this.translate(v, 'title', this.language) || v.key
        }
        if (hasMultipleMeasures && v.measure) {
          label = `${v.measure.name}: ${label}`
        }
        var tmp = this.splitter(label, this.isRadar ? 60 : 50)
        result.push(tmp)
      })
      return result
    },
    answers () {
      var objects = []
      Object.entries(this.config).forEach(([key, value]) => {
        var neg = clone(value.negativeValues)

        const tmp = neg.concat(value.positiveValues)
        objects.push({ measure: parseInt(key), values: tmp })
      })

      var result = []

      this.selectedSeries.filter(s => s.data !== undefined).forEach((serie) => {
        var labels = []
        objects.forEach(obj => {
          obj.values.forEach(value => {
            const entry = serie.data.find(d => d.measure.id === obj.measure && String(d.value) === String(value))
            if (entry !== undefined) {
              labels.push(this.translate(entry, 'label', this.language) || entry.key)
            }
          })
        })
        result.push(labels)
      })
      this.selectedSeries.filter(s => s.data === undefined).forEach((serie) => {
        var labels = []

        objects.forEach(obj => {
          obj.values.forEach(value => {
            const entry = serie.keys[0].data.find(d => d.measure.id === obj.measure && String(d.value) === String(value))
            if (entry !== undefined) {
              labels.push(this.translate(entry, 'label', this.language) || entry.key)
            }
          })
        })
        result.push(labels)
      })
      return result
    },
    meanMax () {
      var tmpMax = 0
      if (this.maxValue) {
        tmpMax = round(this.maxValue)
      }
      if (this.isScaleActive) {
        return 100
      }
      if (this.hasSkala === true) {
        return this.findMinMaxValue('max')
      }
      if (this.hasSkala !== true) {
        const config = Object.values(this.config)[0]
        if (config.meanMax !== undefined) {
          return Math.max(parseInt(config.meanMax), tmpMax)
        }

        return Math.max(config.negativeValues.length + config.positiveValues.length, tmpMax)
      } else {
        return Math.max(tmpMax)
      }
    },
    meanMaxLabel () {
      if (this.isScaleActive) {
        return 100
      }
      if (this.hasSkala === true) {
        return this.findMinMaxValue('max')
      }

      if (this.hasSkala !== true) {
        const config = Object.values(this.config)[0]

        if (config.meanMax !== undefined) {
          return parseInt(config.meanMax) - 1
        }
        return config.negativeValues.length + config.positiveValues.length - 1
      } else { return 5 }
    },
    meanMin () {
      if (this.isScaleActive) {
        return 0
      }
      if (this.hasSkala === true) {
        return this.findMinMaxValue('min')
      }
      if (this.hasSkala !== true) {
        const config = Object.values(this.config)[0]
        if (config.meanMin !== undefined) {
          return parseInt(config.meanMin)
        }
      }
      return 1
    },
    getWidth () {
      return this.width
    }
  },
  methods: {
    findMinMaxValue (criteria) {
      const data = this.seriesData
      let defaultVal = 0
      for (const key of data) {
        for (const key0 of key.data) {
          if (Array.isArray(key0.values)) {
            if (criteria === 'min') {
              const defaultArray = Math.max(...key0.values)
              defaultVal = Math.min(defaultVal, defaultArray)
            }
            if (criteria === 'max') {
              const defaultArray = Math.max(...key0.values)
              defaultVal = Math.max(defaultVal, defaultArray)
            }
          }
        }
      }
      return defaultVal
    },
    reset () {
      this.maxValue = 0
      this.checkScale()
    },
    addBenchmarks (result, results) {
      if (this.isRadar && this.showBenchmark) {
        results = [{
          name: this.$t('mean', this.language),
          data: result
        }]
        var tmp = {}
        result.forEach(r => {
          for (const goal of r.goals) {
            if (tmp[goal.name] === undefined) {
              tmp[goal.name] = []
            }
            tmp[goal.name].push({
              x: r.x,
              y: goal.value,
              total: r.total
            })
          }
        })
        Object.keys(tmp).forEach(meanName => {
          results.push({
            name: meanName,
            data: tmp[meanName]
          })
        })
        this.seriesData = results
      }
    },
    checkLimitOfCategoriesInGraphic (currentCountOfCategories) {
      if (currentCountOfCategories > this.MAX_CATEGORIES) {
        this.hideGraphic = true
        this.showCategoryLimitModal = true
      } else {
        this.showCategoryLimitModal = false
      }
    },
    setupData () {
      if (!this.visible || this.data.length <= 0) {
        this.seriesData = []
        return
      }
      this.reset()
      var result = []
      // Groupieren nach Attribut
      if (this.chart.groups.length > 0) {
        var results = []
        this.chart.groups.forEach(group => {
          group.data.forEach(v => {
            result = []
            var filtered = []
            if (v.customFunc) {
              filtered = this.data.filter(d => v.customFunc(d.attributes[group.key]))
            } else {
              filtered = this.data.filter(d => v.value && d.attributes[group.key] && d.attributes[group.key].toString() === v.value.toString())
            }
            if (filtered.length > 0) {
              if (this.isSplit) {
                result = this.buildSplitSeries(result, filtered)
              } else {
                result = this.buildSeries(result, filtered)
              }
              results.push({
                name: this.translate(v, 'label', this.language),
                data: result
              })
            }
          })
        })
        this.hideGraphic = false
        this.checkLimitOfCategoriesInGraphic(results.length)
        this.seriesData = results
        this.addBenchmarks(result, results)
        return
      }
      if (this.isSplit) {
        result = this.buildSplitSeries(result, this.data)
      } else {
        result = this.buildSeries(result, this.data)
      }

      // Scale to 100
      if (this.isScaleActive) {
        const min1 = 1
        const max1 = 5
        const min2 = 0
        const max2 = 100

        result.forEach(res => {
          res.y = round((((res.y - min1) / (max1 - min1)) * (max2 - min2)) + min2, 2)

          // Fehlerbalken
          res.goals.forEach(goal => {
            goal.value = round((((goal.value - min1) / (max1 - min1)) * (max2 - min2)) + min2, 2)
            this.maxValue = Math.max(goal.value, this.maxValue)
          })
        })
      }

      // Radar Bencmark
      this.addBenchmarks(result, results)
      if (this.isRadar && this.showBenchmark) {
        return
      }
      this.hideGraphic = false
      this.seriesData = [{
        name: this.$t('mean', this.language),
        data: result
      }]
    },
    checkScale () {
      var total = -1
      var tmp = []
      if (this.selectedSeries.keys) {
        tmp = this.selectedSeries.filter(f => f.keys).map(d => d.keys).flat()
      } else {
        tmp = [...tmp, ...this.selectedSeries.filter(f => f.keys === undefined)]
      }
      for (const serie of tmp) {
        if (serie.config !== undefined) {
          var config = Object.values(serie.config)[0]
          var tmpTotal = config.negativeValues.length + config.positiveValues.length
          if (total > -1 && tmpTotal !== total) {
            this.hasSameScale = false
            return
          }
          total = tmpTotal
        }
      }
      this.hasSameScale = true
    },
    onceHandler () {
      this.visible = true
    },
    buildErrorBars () {
      const groups = document.querySelectorAll('g[className="apexcharts-bar-goals-groups"]')
      for (var i = 0; i < groups.length; ++i) {
        var group = groups[i]

        const errorBars = group.querySelectorAll('line[stroke="#aaaaaa"]')

        if (errorBars.length === 2) {
          var errorBar1 = errorBars[0]
          var errorBar2 = errorBars[1]
          var line = document.createElement('line')
          const yVal = errorBar1.y1.baseVal.value + ((errorBar1.y2.baseVal.value - errorBar1.y1.baseVal.value) / 2)
          line.id = `${errorBar1.id}_${errorBar2.id}`
          line.setAttribute('stroke', '#aaaaaa')
          line.setAttribute('stroke-width', '2')
          line.setAttribute('x1', errorBar1.x1.baseVal.value)
          line.setAttribute('x2', errorBar2.x1.baseVal.value)
          line.setAttribute('y1', yVal)
          line.setAttribute('y2', yVal)
          line.setAttribute('stroke-dasharray', 0)
          line.setAttribute('stroke-linecap', 'butt')
          line.setAttribute('transform', 'matrix(1,0,0,1,0,0)')
          // shorten the bars
          errorBar1.setAttribute('y1', errorBar1.y1.baseVal.value + 4)
          errorBar1.setAttribute('y2', errorBar1.y2.baseVal.value - 4)
          errorBar2.setAttribute('y1', errorBar2.y1.baseVal.value + 4)
          errorBar2.setAttribute('y2', errorBar2.y2.baseVal.value - 4)

          group.append(line)
          var newVal = group.innerHTML
          group.innerHTML = newVal
        }
      }
    },
    buildSplitSeries (result, rawData) {
      this.splitSeries.forEach((splitValue) => {
        this.selectedSeries.forEach((attr) => {
          var total = 0
          var data = 0
          var values = []
          var attributes = []
          var benchmarkKeys = []
          if (attr.keys) {
            attributes = attr.keys
          } else {
            attributes = [attr]
          }
          for (const value of rawData) {
            // Skip data items that do not match the current splitValue
            if (String(value.attributes[splitValue.key]) !== String(splitValue.value) && (!splitValue.customFunc || !splitValue.customFunc(value.attributes[splitValue.key]))) {
              continue
            }

            attributes.forEach(v => {
              const measure = value.measure
              benchmarkKeys.push({ key: v.key, measureId: v.measure.id })

              if (v.answerKeys[measure]) {
                v.answerKeys[measure].forEach((answerKey) => {
                  if (value.attributes[answerKey] !== null) {
                    const answer = String(value.attributes[answerKey])
                    const isInNeg = this.config[measure].negativeValues.includes(answer)
                    const isInPos = this.config[measure].positiveValues.includes(answer)
                    if ((isInNeg || isInPos) && answerKey === v.key) {
                      var ix = -1
                      if (!isNaN(parseInt(value.attributes[answerKey]))) {
                        ix = parseInt(value.attributes[answerKey])
                        if (ix >= this.meanMin && ix <= this.meanMax) {
                          data += ix
                          values.push(ix)
                          total += 1
                        }
                      } else {
                        if (isInNeg) {
                          ix = this.config[measure].negativeValues.length - 1 - this.config[measure].negativeValues.indexOf(answer)
                        } else {
                          ix = this.config[measure].negativeValues.length + this.config[measure].positiveValues.indexOf(answer)
                        }
                        data += (ix + 1)
                        values.push(ix + 1)
                        total += 1
                      }
                    }
                  }
                })
              }
            })
          }
          const bms = this.computeBenchmarkData(benchmarkKeys, this.customLegendItems, this.colors, this.showBenchmark)
          result.push({
            x: this.translate(splitValue, 'label', this.language),
            y: round(data / total, 2),
            values: values,
            total: round(total / attributes.length, 0),
            goals: bms
          })
        })
      })

      result = this.addErrorBars(result)
      return result
    },
    buildSeries (result, rawData) {
      this.selectedSeries.forEach((attr, serieIx) => {
        var benchmarkKeys = []
        var total = 0
        var data = 0
        var values = []

        var attributes = []

        if (attr.keys) {
          attributes = attr.keys
        } else {
          attributes = [attr]
        }
        attributes.forEach(v => {
          benchmarkKeys.push({ key: v.key, measureId: v.measure.id })
          rawData.forEach(value => {
            const measure = value.measure
            if (v.answerKeys[measure]) {
              v.answerKeys[measure].forEach((answerKey) => {
                if (value.attributes[answerKey] !== null && v.config !== undefined) {
                  if (this.hasSkala === true) {
                    if (answerKey === v.key) {
                      if (!isNaN(value.attributes[answerKey])) {
                        if (value.attributes[answerKey] !== -1) {
                          const ix = value.attributes[answerKey]
                          data += ix
                          values.push(ix)
                          total += 1
                        }
                      }
                    }
                  }
                  if (this.hasSkala !== true) {
                    const isInNeg = v.config[measure].negativeValues.includes(String(value.attributes[answerKey]))
                    const isInPos = v.config[measure].positiveValues.includes(String(value.attributes[answerKey]))
                    if (answerKey === v.key) {
                      let ix = -1
                      if (!isNaN(parseInt(value.attributes[answerKey]))) {
                        ix = parseInt(value.attributes[answerKey])
                        if (ix >= this.meanMin && ix <= this.meanMax) {
                          data += ix
                          values.push(ix)
                          total += 1
                        }
                      } else if (isInNeg || isInPos) {
                        if (isInNeg) {
                          ix = v.config[measure].negativeValues.indexOf(String(value.attributes[answerKey]))
                        } else {
                          ix = v.config[measure].negativeValues.length + v.config[measure].positiveValues.indexOf(String(value.attributes[answerKey]))
                        }
                        data += (ix + 1)
                        values.push(ix + 1)
                        total += 1
                      }
                    }
                  }
                }
              })
            }
          })
        })

        const bms = this.computeBenchmarkData(benchmarkKeys, this.customLegendItems, this.colors, this.showBenchmark)

        const meanValue = total === 0 ? 0 : round(data / total, 2)
        result.push({
          x: this.categories[serieIx],
          y: meanValue,
          values: values,
          goals: bms,
          total: round(total / attributes.length, 0)
        })
      })

      result = this.addErrorBars(result)
      return result
    },
    computeBenchmarkData (benchmarkKeys, customLegendItems, colors, showBenchmark) {
      // Pre-process
      const chartMeans = {}
      const chartCounts = {}

      benchmarkKeys.forEach(value => {
        this.resultBenchmarkForMeasureId(value.measureId).forEach(bm => {
          var chartName = this.translate(bm, 'name_chart', this.language)
          if (bm.means[value.key]) {
            if (chartMeans[chartName]) {
              chartMeans[chartName] += bm.means[value.key]
            } else {
              chartMeans[chartName] = bm.means[value.key]
            }
            if (chartCounts[chartName]) {
              chartCounts[chartName] += 1
            } else {
              chartCounts[chartName] = 1
            }
          }
        })
      })

      const chartColors = {}
      customLegendItems.forEach((name, i) => {
        chartColors[name] = colors[i]
      })

      const bms = []
      if (showBenchmark) {
        Object.keys(chartMeans).forEach(chartName => {
          bms.push({
            name: chartName,
            value: round(chartMeans[chartName] / chartCounts[chartName], 2),
            strokeWidth: 5,
            strokeColor: chartColors[chartName]
          })
        })
      }
      return bms
    },
    addErrorBars (result) {
      if (this.showErrorBars) {
        for (const key in result) {
          var res = result[key]
          const ciLower = {
            name: this.$t('ci-lower'),
            value: undefined,
            strokeWidth: 2,
            strokeColor: '#aaaaaa'
          }
          const ciUpper = {
            name: this.$t('ci-upper'),
            value: undefined,
            strokeWidth: 2,
            strokeColor: '#aaaaaa'
          }
          if (res.values.length > 0 && res.total > 1) {
            // The sample standard deviation can only be calculated with a sample count greater than 1
            const answersPerRespondent = res.values.length / res.total
            const mean = res.y
            const tssOfAllAnswers = res.values.map(function (itm) { return (itm - mean) * (itm - mean) }).reduce((a, b) => a + b) // tss => total sum of squares
            const tssByRespondents = tssOfAllAnswers / answersPerRespondent // We consider each respondent as one sample and not each answered question. So we divide the tss of all answers by the number of answers per respondent (answersPerRespondent)
            const stdDev = Math.sqrt(tssByRespondents / (res.total - 1))
            const ci = 1.96 * (stdDev / Math.sqrt(res.total))
            ciLower.value = round(Math.max(mean - ci, this.meanMin), 2)
            ciUpper.value = round(Math.min(mean + ci, this.meanMax), 2)
          } else {
            // No propper confidence interval can be calculated. So the interval goes from the range's minimum to its maximum.
            ciLower.value = round(this.meanMin, 2)
            ciUpper.value = round(this.meanMax, 2)
          }
          result[key].goals.push(ciLower, ciUpper)
        }
      }
      return result
    },
    transpose (a) {
      return Object.keys(a[0]).map(c => {
        return a.map(r => { return r[c] })
      })
    },
    // swap negative values for display
    swapNegativeValues (a, middle) {
      var tmp = clone(a)
      tmp = tmp.slice(0, middle + 1).reverse().concat(a.slice(middle + 1))
      return tmp
    }
  },
  mounted () {
    this.setupData()
    this.isDarkMode = document.documentElement.classList.contains('dark')
  },
  watch: {
    selectedSeries: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    splitSeries: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    groups: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    viewSettings: {
      handler () {
        this.setupData()
      }
    },
    data: {
      handler () {
        this.setupData()
      }
    },
    visible: {
      handler () {
        this.setupData()
      }
    }
  }
}
</script>
