<style scoped>
.mainnav .active {
  @apply bg-neutral-600 text-white dark:bg-neutral-600;
}
.subnav .active {
  @apply bg-neutral-100 text-black border-neutral-800 dark:bg-neutral-900 dark:text-white dark:border-neutral-200 font-bold;
}

.subnav .activeSub a {
  @apply bg-neutral-100 border-l-4 border-neutral-800 dark:bg-neutral-900 dark:border-neutral-200;
}

.mobile-nav .active {
  @apply bg-neutral-900 text-white dark:bg-neutral-100 dark:text-black;
}
</style>
<template>
  <div>
    <Activities v-if="hasHistory" @close="onShowHistory" :contentType="this.contentType" :objectId="$route.params.primaryKey" :open="showHistory"></Activities>
    <div v-if="isNotProd" class="z-50 fixed top-4 -left-8 w-28 h-5 bg-gradient-to-r from-red-600 to-red-800 transform flex items-center justify-center font-bold text-xs text-white -rotate-45 uppercase">{{prefix}}</div>
    <div v-if="isNotProd" class="z-50 fixed top-0 right-0 w-28 pointer-events-none">
      <div class="relative w-full h-16 overflow-hidden">
        <div class="absolute top-4 -right-8 w-28 h-5 bg-gradient-to-r from-red-600 to-red-800 transform flex items-center justify-center font-bold text-xs text-white rotate-45 uppercase">{{prefix}}</div>
      </div>
    </div>
    <nav class="bg-neutral-200 dark:bg-neutral-900 fixed w-full z-40">
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <div class="shrink-0">
            <img class="h-16 w-16" src="https://www.w-hoch2.ch/wp-content/themes/whoch2/img/w2-logo_235x80.svg" alt="Logo wHoch2">
          </div>
          <div class="hidden md:block">
            <div class="mainnav ml-10 flex items-baseline space-x-4">
              <router-link
                v-for="(menuItem, index) in menuFiltered"
                :key="menuItem.path + index"
                :to="menuItem.path"
                class="text-neutral-700 dark:text-neutral-300 hover:bg-neutral-300 hover:text-black dark:hover:bg-neutral-700 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                :class="{'active': menuItem === activeMenu}"
              >
                {{menuItem.name}}
              </router-link>
           </div>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">

            <!-- Profile dropdown -->
            <div class="ml-3 relative">
              <div>
                <button @click.stop="showProfileMenu = !showProfileMenu" type="button" class="items-center w-10 h-10 max-w-xs bg-neutral-400 hover:bg-neutral-800 dark:bg-neutral-600 dark:hover:bg-neutral-700 p-0 rounded-full text-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neutral-200 dark:focus:ring-offset-neutral-800 focus:ring-black" id="user-menu" aria-expanded="false" aria-haspopup="true">
                  <span class="sr-only">Open user menu</span>
                  {{userInitials}}
                </button>
              </div>
              <div v-if="showProfileMenu" @click="showProfileMenu=false" class="fixed top-0 left-0 w-full h-full bg-opacity-0"></div>
              <transition
                enter-active-class="transition ease-out duration-100"
                leave-active-class="transition ease-in duration-75"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <div v-if="showProfileMenu"
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-black ring-1 ring-black dark:ring-neutral-600 ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                  <router-link
                    v-if="!user.is_demo"
                    class="block px-4 py-2 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:bg-neutral-900 dark:hover:bg-neutral-600"
                    :to="'/accounts/users/'+ user.id">
                    {{$t('my-profile')}}
                  </router-link>
                  <router-link
                    v-if="!user.is_demo"
                    class="block px-4 py-2 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:bg-neutral-900 dark:hover:bg-neutral-600 border-b dark:border-neutral-600"
                    :to="'/accounts/feedbacks/+'">
                    {{$t('accounts.feedback')}}
                  </router-link>
                  <button
                    class="block px-4 py-2 text-sm w-full rounded-none text-start text-neutral-700 dark:text-neutral-300 bg-white hover:bg-neutral-100 dark:bg-neutral-900 dark:hover:bg-neutral-600"
                    @click="logoutUser()"
                    href="#"
                    :disabled="isLoading"
                  >
                    {{$t('logout')}}
                </button>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <button type="button" @click="showMobileMenu = !showMobileMenu" class="bg-neutral-800 dark:bg-neutral-200 inline-flex items-center justify-center p-2 rounded-md text-neutral-400 dark:text-neutral-600 hover:text-white dark:hover:text-black hover:bg-neutral-700 dark:bg-neutral-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neutral-800 focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <!--
              Menu open: "hidden", Menu closed: "block"
            -->
            <svg class="h-6 w-6" :class="[showMobileMenu ? 'hidden' : 'block']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <!--
              Menu open: "block", Menu closed: "hidden"
            -->
            <svg class="h-6 w-6" :class="[showMobileMenu ? 'block' : 'hidden']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div v-if="showMobileMenu" class="md:hidden flex flex-col" id="mobile-menu">
      <div class="flex-auto px-2 pt-2 pb-3 sm:px-3 max-h-96 overflow-auto">
        <div
          v-if="hasRefUrl"
          class="cursor-pointer border-l-4 px-3 py-2 text-base w-full bg-red-200 hover:bg-red-300 text-black border-red-800 font-bold"
          @click="backToRefUrl()"
        >
          <span v-if="$route.query.type && this.$route.query.type === 'moniq'">
            {{ $t('back-to-moniq') }}
          </span>
          <span v-else>
            {{$t('back-admin-dashboard')}}
          </span>
        </div>
        <div v-for="(menuItem, index) in menuFiltered"  :key="menuItem.path + index" class="mobile-nav" @click="showMobileMenu = false">
          <router-link
            :to="menuItem.path"
            class="block px-3 py-2 rounded-md text-base font-bold text-neutral-700 dark:text-neutral-300 hover:bg-neutral-700 dark:bg-neutral-300 hover:text-white dark:hover:text-black"
          >
            {{menuItem.name}}
          </router-link>

          <router-link
            v-for="(menuChild, index) in children(menuItem)"
            :key="menuChild.path + index"
            :to="menuChild.path"
            class="mobile-subnav flex my-1 items-center px-3 py-2 rounded-md text-base font-medium text-neutral-700 dark:text-neutral-300 hover:bg-neutral-700 dark:bg-neutral-300 hover:text-white dark:hover:text-black"
          >
            • {{menuChild.name}}
          </router-link>
        </div>
      </div>
      <div class="flex-shrink-0 pt-4 pb-3 border-t border-neutral-700">
        <div class="flex items-center px-5">
          <div class="shrink-0 flex justify-center items-center w-10 h-10 max-w-xs bg-neutral-400 dark:bg-neutral-600 hover:bg-neutral-800 dark:hover:bg-neutral-200 p-0 rounded-full">
            {{userInitials}}
          </div>
          <div class="ml-3">
            <div class="text-base font-medium leading-none text-black">{{user.first_name}} {{user.last_name}}</div>
            <div class="text-sm font-medium leading-none text-neutral-400 dark:text-neutral-600">{{user.email}}</div>
          </div>
        </div>
        <div class="mt-3 px-2 space-y-1">
          <router-link
            v-if="!user.is_demo"
            class="block px-3 py-2 rounded-md text-base font-medium text-neutral-400 dark:text-neutral-600 hover:text-white dark:hover:text-black hover:bg-neutral-700 dark:bg-neutral-300"
            :to="'/accounts/users/'+ user.id">
            {{$t('my-profile')}}
          </router-link>
          <a
            class="block px-3 py-2 rounded-md text-base font-medium text-neutral-400 dark:text-neutral-600 hover:text-white dark:hover:text-black hover:bg-neutral-700 dark:bg-neutral-300"
            @click="logoutUser()"
            href="#">
            {{$t('logout')}}
          </a>
        </div>
      </div>
    </div>
  </nav>
  <HeaderInMain :hasSideNavigation="hasSideNavigation" @showHistory="onShowHistory" class=""/>
  <nav v-if="hasSideNavigation" class="hidden md:flex subnav w-52 flex-col py-5 fixed top-40 -mt-1 z-40" style="overflow-y:auto;height:calc(100% - 162px)">
    <div
      v-if="hasRefUrl"
      class="cursor-pointer border-l-4 mb-2 px-6 py-2 text-sm w-full bg-red-200 hover:bg-red-300 text-black border-red-800 font-bold dark:border-neutral-600"
      @click="backToRefUrl()"
    >
      <span v-if="$route.query.type && this.$route.query.type === 'moniq'">
        {{ $t('back-to-moniq') }}
      </span>
      <span v-else>
        {{$t('back-admin-dashboard')}}
      </span>
    </div>
    <div
      v-for="(menuItem, index) in children()"
      :key="menuItem.path + index"
      :class="[menuItem === activeSubMenu ? 'activeSub' : '']"
    >
      <router-link
        :to="menuItem.path"
        :class="[menuItem.class ? menuItem.class : '', menuItem === activeSubMenu ? 'active' : '']"
        class="text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-900 hover:text-black dark:hover:text-white border-l-4 border-white dark:border-black hover:border-neutral-800 dark:hover:border-neutral-200 px-6 py-2 text-sm font-medium w-full flex justify-between items-center"
        @click="resetSearch"
      >
        <span>{{menuItem.name}}</span>
        <span class="text-xs bg-neutral-200 dark:bg-neutral-900 rounded-full h-5 w-5 flex items-center justify-center" v-if="menuItem.badge">{{menuItem.badge}}</span>
      </router-link>
      <div v-if="menuItem.childs && menuItem === activeSubMenu" class="mb-3">
        <router-link
          v-for="(childItem, index) in menuItem.childs"
          :class="[childItem === activeFilterMenu ? 'active' : '']"
          :key="childItem.path + index"
          :to="childItem.path"
          class="text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-900 hover:text-black dark:hover:text-white border-l-4 border-white dark:border-black hover:border-neutral-800 dark:hover:border-neutral-200 pl-8 py-2 text-xs font-medium w-full flex justify-between items-center"
        >
          {{childItem.name}}
        </router-link>
      </div>
    </div>
  </nav>
  <main class="px-4 py-5 pt-44 z-30" :class="[!hasSideNavigation ? '' : 'md:ml-52']" style="padding-top:11rem;">
    <router-view :key="$route.fullPath"></router-view>
  </main>
  <div class="fixed bottom-0 left-0 p-1 text-xs text-neutral-500" style="white-space:pre;background:white;z-index:40;padding:6px;">{{app_version}}</div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Activities from '@/components/Activities.vue'
import HeaderInMain from '@/components/Header.vue'
export default {
  components: {
    HeaderInMain,
    Activities
  },
  data: function () {
    return {
      showProfileMenu: false,
      showMobileMenu: false,
      showHistory: false,
      openInvitations: 0,
      openFeedbacks: 0
    }
  },
  computed: {
    ...mapGetters({
      userInitials: 'userInitials',
      user: 'user',
      hasAccess: 'accounts/appPermissions/hasAccess',
      refUrl: 'refUrl',
      hasEvaluations: 'results/evaluations/hasData',
      hasMeasures: 'results/measures/hasData',
      editValues: 'editValues',
      apps: 'apps/apps/all',
      contentTypeByName: 'accounts/contenttypes/getByName',
      isLoading: 'isLoading'
    }),
    hasSideNavigation () {
      return this.hasRefUrl || this.children().length > 1
    },
    api_version () {
      let description = this.apps.filter(item => item.name == 'DJANGO_APP_VERSION')[0]?.description
      return description? `\nAPI-${description}`: ''
    },    
    app_version () {
      return this.isNotLocal ? `VER-${process.env.VUE_APP_VERSIONING}\nAPP-${process.env.VUE_APP_VERSION}${this.api_version}` : `VER-${process.env.VUE_APP_VERSION}\nAPP-${process.env.VUE_APP_VERSION}\nAPI-${process.env.VUE_APP_VERSION}`
    },
    hasHistory () {
      return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/hasHistory`]
    },
    contentType () {
      return this.$store.getters[`${this.$route.params.app}/${this.$route.params.collection}/contentType`]
    },
    isNotLocal () {
      return (process.env.VUE_APP_PREFIX !== 'local')
    },
    isNotProd () {
      return (process.env.NODE_ENV === 'development')
    },
    prefix () {
      return process.env.VUE_APP_PREFIX
    },
    activeMenu () {
      var tmp = this.$route.path.split('/')
      if (tmp.length > 3) {
        tmp.pop()
      }
      const path = tmp.join('/')
      return this.menuFiltered.find(m => m.path === path || (m.childs && m.childs.some(c => c.path === path || (c.childs && c.childs.some(cc => cc.path === path)))))
    },
    activeSubMenu () {
      var tmp = this.$route.path.split('/')
      if (tmp.length > 3) {
        tmp.pop()
      }
      const path = tmp.join('/')
      if (this.activeMenu && this.activeMenu.childs) {
        return this.activeMenu.childs.find(c => c.path === path || (c.childs && c.childs.some(cc => cc.path === path)))
      }
      return null
    },
    activeFilterMenu () {
      var tmp = this.$route.fullPath.split('/')
      if (tmp.length > 3) {
        tmp.pop()
      }
      const path = tmp.join('/')
      if (this.activeSubMenu && this.activeSubMenu.childs) {
        return this.activeSubMenu.childs.find(c => c.path === path)
      }
      return null
    },
    menuFiltered () {
      return this.menu.filter(m => m.visible === undefined || m.visible)
    },
    getOpenInvitations () {
      return this.openInvitations
    },
    getOpenFeedbacks () {
      return this.openFeedbacks
    },
    isStaff () {
      return this.user.is_staff === true
    },
    isSuperUser () {
      return this.user.is_superuser === true
    },
    hasRefUrl () {
      return this.refUrl !== null
    },
    appUserPaths () {
      var childs = []
      childs.push(
        {
          name: 'Alle',
          path: '/accounts/users',
          visible: this.isStaff
        }
      )
      childs.push(
        {
          name: 'Staff',
          path: '/accounts/users?is_staff=True',
          visible: this.isStaff
        }
      )
      this.apps.forEach(app => {
        if (app.id) {
        var path = `/accounts/users?app_permissions__content_type=${app.content_type}`
        // add role param if the app is restricted to roles
        if (app.role !== null) {
          path = `${path}&app_permissions__role=${app.role}`
        }
        // add group-id if its a group permission
        const ct = this.contentTypeByName('apps.group').id
        if (ct === app.content_type) {
          path = `${path}&app=${app.id}`
        }
        childs.push(
          {
            name: app.name,
            path: path,
            visible: this.isStaff
          }
        )
      }})
      return childs
    },
    appGroupPaths () {
      var childs = []
      childs.push(
        {
          name: 'Alle',
          path: '/apps/groups',
          visible: this.isStaff
        }
      )
      const ct = this.contentTypeByName('apps.group')
      if (ct === undefined) { return [] }
      this.apps.filter(app => app.content_type === ct.id).forEach(app => {
        childs.push(
          {
            name: app.name,
            path: `/apps/groups?app=${app.id}`,
            visible: this.isStaff
          }
        )
      })
      return childs
    },
    menu () {
      return [
        {
          name: this.$t('applications'),
          path: '/',
          visible: this.isStaff || !this.hasAccess('irp.hospital') || this.isSuperUser,
          childs: [
            {
              name: this.$t('accounts.users'),
              path: '/accounts/users',
              visible: !this.user.is_demo && (this.isStaff || this.hasAccess('patzu.hospital', true) || this.hasAccess('moniq.hospital', true) || this.hasAccess('apps.group', true)),
              childs: this.isStaff ? this.appUserPaths : undefined
            },
            {
              name: this.$t('accounts.invitations'),
              path: '/accounts/invitations',
              badge: this.getOpenInvitations > 0 ? this.getOpenInvitations : undefined,
              visible: !this.user.is_demo && (this.isStaff || this.hasAccess('patzu.hospital', true) || this.hasAccess('moniq.hospital', true) || this.hasAccess('apps.group', true))
            },
            {
              name: this.$t('accounts.feedbacks'),
              path: '/accounts/feedbacks',
              badge: this.getOpenFeedbacks > 0 ? this.getOpenFeedbacks : undefined,
              visible: this.isStaff
            },
            {
              name: this.$t('accounts.demo_users'),
              path: '/accounts/demo_users',
              visible: this.isStaff
            },
            {
              name: this.$t('apps.apps'),
              path: '/apps/apps',
              class: 'mt-8',
              visible: this.isStaff
            },
            {
              name: this.$t('apps.groups'),
              path: '/apps/groups',
              visible: this.isStaff,
              childs: this.appGroupPaths
            },
            {
              name: 'Benutzer Titel',
              path: '/accounts/usertitles',
              visible: this.isStaff
            }
          ]
        },
        {
          name: 'PatZu',
          path: '/patzu',
          visible: this.isStaff,
          childs: [
            {
              name: this.$t('dashboard'),
              path: '/patzu'
            },
            {
              name: this.$t('hospitals'),
              path: '/patzu/hospitals'
            },
            {
              name: this.$t('accounts.users'),
              path: '/patzu/users'
            },
            {
              name: this.$t('accounts.responsibles'),
              path: '/patzu/responsibles'
            },
            {
              name: this.$t('patzu.addresses'),
              path: '/patzu/addresses'
            },
            {
              name: this.$t('patzu.measures'),
              path: '/patzu/measures',
              childs: [
                {
                  name: this.$t('patzu.events'),
                  path: '/patzu/events'
                }
              ]
            },
            {
              name: this.$t('patzu.orders'),
              path: '/patzu/orders',
              childs: [
                {
                  name: this.$t('patzu.orderunits'),
                  path: '/patzu/orderunits'
                }
              ]
            },
            {
              name: this.$t('patzu.declarations'),
              path: '/patzu/declarations'
            },
            {
              name: this.$t('patzu.tokens'),
              path: '/patzu/tokens'
            }
          ]
        },
        this.prefix !== 'beta' ? {
          name: 'moniQ',
          path: '/moniq',
          visible: this.isStaff,
          childs: [
            {
              name: this.$t('dashboard'),
              path: '/moniq'
            },
            {
              name: this.$t('hospitals'),
              path: '/moniq/hospitals'
            },
            {
              name: this.$t('accounts.users'),
              path: '/moniq/users'
            },
            {
              name: this.$t('accounts.responsibles'),
              path: '/moniq/responsibles'
            }
          ]
        } : null,
        this.prefix !== 'beta' ? {
          name: 'IKA',
          path: '/ika',
          visible: this.isStaff,
          childs: [
            {
              name: this.$t('dashboard'),
              path: '/ika'
            },
            {
              name: this.$t('hospitals'),
              path: '/ika/hospitals'
            },
            {
              name: this.$t('results.evaluations'),
              path: '/ika/evaluations'
            }
          ]
        } : null,
        this.prefix !== 'beta' ? {
          name: 'IRP',
          path: '/irp',
          visible: this.isStaff || this.hasAccess('irp.hospital', false),
          childs: [
            {
              name: this.$t('dashboard'),
              path: '/irp'
            },
            {
              name: this.$t('hospitals'),
              path: '/irp/hospitals',
              visible: this.isStaff
            },
            {
              name: this.$t('irp.global'),
              path: '/irp/irpGlobalFiles',
              visible: this.isStaff
            },
            {
              name: this.$t('accounts.users'),
              path: '/irp/users',
              visible: this.isStaff
            },
            {
              name: this.$t('irp.review_year'),
              path: '/irp/review_years',
              visible: this.isStaff
            },
            {
              name: this.$t('irp.review'),
              path: '/irp/reviews',
              visible: this.isStaff
            },
            {
              name: this.$t('irp.reviewer'),
              path: '/irp/reviewers',
              visible: this.isStaff
            }
          ]
        } : null,

        this.prefix !== 'beta' ? {
          name: 'StuDek',
          path: '/studek',
          visible: this.isStaff,
          childs: [
            {
              name: this.$t('dashboard'),
              path: '/studek'
            },
            {
              name: this.$t('hospitals'),
              path: '/studek/hospitals'
            },
            {
              name: this.$t('accounts.users'),
              path: '/studek/users'
            },
            {
              name: this.$t('studek.measures'),
              path: '/studek/measures',
              childs: [
                {
                  name: this.$t('studek.events'),
                  path: '/studek/events'
                }
              ]
            }
          ]
        } : null,

        this.prefix !== 'beta' ? {
          name: this.$t('results.title'),
          path: '/results/evaluations',
          visible: (this.isStaff || this.hasEvaluations || this.hasMeasures) && !this.isSuperUser,
          childs: [
            {
              name: this.$t('results.evaluations'),
              path: '/results/evaluations',
              visible: this.isStaff || this.hasEvaluations || this.hasMeasures,
              childs: [
                {
                  name: this.$t('trash'),
                  path: '/results/evaluations?is_active=false',
                  visible: this.isStaff
                }
              ]
            },
            {
              name: this.$t('results.measures'),
              visible: this.isStaff,
              path: '/results/measures'
            },
            {
              name: this.$t('results.benchmarks'),
              visible: this.isStaff,
              path: '/results/benchmarks'
            },
            {
              name: this.$t('results.data'),
              path: '/results/data',
              visible: this.isStaff
            },
            {
              name: this.$t('results.importer'),
              path: '/results/importer',
              visible: this.isStaff
            }
          ]
        } : null,
        {
          name: 'Mailer',
          path: '/mailer/mailjobs',
          visible: this.isStaff,
          childs: [
            {
              name: this.$t('mailer.mailjobs'),
              path: '/mailer/mailjobs',
              visible: this.isStaff
            },
            {
              name: this.$t('mailer.mailtemplates'),
              path: '/mailer/mailtemplates',
              visible: this.isStaff
            },
            {
              name: this.$t('mailer.mails'),
              path: '/mailer/mails',
              visible: this.isStaff
            }
          ]
        },
        this.prefix !== 'beta' ? {
          name: 'Dateimanager',
          path: '/filemanager/files',
          visible: this.isStaff,
          childs: [
            {
              name: this.$t('filemanager.files'),
              path: '/filemanager/files',
              visible: this.isStaff
            }
          ]
        } : null,
        this.prefix !== 'beta' ? {
          name: 'Aktivitäten',
          path: '/accounts/versions',
          visible: this.isStaff,
          childs: [
            {
              name: 'Aktivitäten',
              path: '/accounts/versions',
              visible: this.isStaff
            }
          ]
        } : null
      ].filter(entry => entry !== null)
    }
  },
  methods: {
    ...mapActions(['logoutUser', 'updateRefUrl']),
    onShowHistory () {
      this.showHistory = !this.showHistory
    },
    subIsActive (input) {
      return this.$route.path.split('/')[1] === input.split('/')[1]
    },
    async resetSearch () {
      await this.$store.dispatch(`${this.path}/load`, { query: { search: '' } })
      this.$store.commit('setQuery', { search: '' })
    },
    backToRefUrl () {
      window.location = `${this.refUrl}/?access_token=${this.$store.state.accessToken}`
    },
    children (forMenu) {
      const menu = forMenu !== undefined ? forMenu : this.activeMenu
      if (menu !== undefined && menu.childs) {
        return menu.childs.filter(c => c.visible === undefined || c.visible)
      } else {
        return []
      }
    }
  },
  created () {
    if (this.$route.query.ref !== undefined) {
      this.updateRefUrl(this.$route.query.ref)
    }
  },
  async mounted () {
    // DarkMode
    if (this.user.theme === 2) {
      document.documentElement.classList.add('dark')
    // System Mode
    } else if (this.user.theme === 3 && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark')
    // Light Mode
    } else {
      document.documentElement.classList.remove('dark')
    }
    await this.$store.dispatch('results/measures/load')
    if (this.user.is_staff) {
      this.openInvitations = await this.$store.dispatch('accounts/invitations/load', { callback: true, all: true, query: { accepted: 'false' } })
      this.openInvitations = this.openInvitations.length
      this.openFeedbacks = await this.$store.dispatch('accounts/feedbacks/load', { callback: true, all: true, query: { done: 'false' } })
      this.openFeedbacks = this.openFeedbacks.length
      await this.$store.dispatch('apps/apps/load')
    }

    if ((this.hasAccess('irp.hospital') && !this.isStaff && !this.isSuperUser) && (this.$route.path === '' || this.$route.path === '/')) {
      this.$router.push({ path: '/irp' })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.showProfileMenu = false
    this.showMobileMenu = false
    next()
  }
}
</script>
