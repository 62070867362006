<template>
<div>
  <div
    @click="open=true"
    v-tooltip="'Importieren...'"
    class="flex items-center justify-center p-2 mr-2 w-10 h-10 bg-emerald-600 hover:bg-opacity-80 text-white cursor-pointer rounded-full"
    >
    <svg class="fill-current h-5 w-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 22h-20v-7h2v5h16v-16h-16v5h-2v-7h20v20zm-13-11v-4l6 5-6 5v-4h-11v-2h11z"/></svg>
  </div>
  <div v-if="open" class="fixed w-full h-full top-0 left-0 flex items-center justify-center p-20">

    <div class="flex flex-col bg-neutral-100 w-2/3 h-full mx-auto rounded shadow-2xl z-50 overflow-hidden">
      <div class="w-full flex items-center p-4 z-40 mr-10 mt-2">
        <h1 class="flex-grow font-bold border-b border-neutral-400">Daten Importieren</h1>
      </div>
      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="text-left relative h-full overflow-y-auto px-2">
        <p class="p-2 mb-10">Wähle eine <b>*.csv</b> Datei aus mit folgenden Attributen: <b>{{fields.join(', ')}}</b>
        <label class="flex items-center justify-center space-x-4 mt-6">
          <input type="file" accept=".csv" ref="csv_file" name="csv_file" class="hidden" @change="loadCSV($event)">
          <h4 v-if="fileName" class="uk-display-inline-block uk-width-1-3">{{fileName}}</h4>
          <h4 v-else class="uk-display-inline-block uk-width-1-2">{{ $t('NoChosenFile') }}</h4>
          <button class="" type="button" @click="$refs.csv_file.click()">{{ $t('ChooseFile') }}</button>
        </label>

        <div class="mt-2">
          <LoadingSpinner v-if="isLoading"/>
          <div v-else-if="getMissingFields.length" class="bg-red-200 border-red-600 border 1 p-3 text-red-800">
            <span class="block font-bold">Fehler:</span>
            Folgende Attribute fehlen: {{getMissingFields}}
          </div>
          <div v-else-if="parse_csv.length">
            Vorschau
            <v-table :headers="importFields" :items="parse_csv.slice(0, 10)"></v-table>
          </div>
          <div
            v-if="importRunning"
            class="bg-neutral-200 w-full h-10 p-1"
          >
            <div class="bg-emerald-500 h-full flex items-center justify-center" :style="'width:' + progress_value + '%;'">
              <span class="text-white">{{progress_value}}%</span>
            </div>
          </div>
          <ul v-else-if="logs.length">
            <li v-for="(log, index) in logs"  class="list-disc font-mono" :key="index">{{log}}</li>
          </ul>
        </div>
      </div>
      <div class="w-full flex space-x-3 px-3 py-3">
        <button class="cancel w-1/2" @click="open = false">Abbrechen</button>
        <button class="w-1/2" @click="importCSV()">Importieren...</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import VTable from './v-table.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
export default {
  components: {
    VTable,
    LoadingSpinner
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    importData: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      open: false,
      fileName: '',
      selected: null,
      parse_header: [],
      parse_csv: [],
      missing_fields: [],
      logs: [],
      progress_value: 40,
      importRunning: false
    }
  },
  computed: {
    isLoading () {
      return this.loading
    },
    getMissingFields () {
      return this.missing_fields.join(', ')
    },
    importFields () {
      return this.fields.map(v => { return { text: v.toUpperCase(), value: v } })
    }
  },
  methods: {
    importCSV: async function () {
      this.importRunning = true
      this.progress_value = 0
      var count = 0
      var i; var j; var temparray; var chunk = 10
      const max = Math.ceil(this.parse_csv.length / chunk)
      for (i = 0, j = this.parse_csv.length; i < j; i += chunk) {
        temparray = this.parse_csv.slice(i, i + chunk)
        var importResult = await this.importData(temparray)
        this.logs = this.logs.concat(importResult.errors)
        count = count + importResult.imported
        this.progress_value = Math.round((i / chunk + 1) / max * 100)
        if (this.open === false) {
          break
        }
      }
      this.logs.unshift(`Anzahl neu importierte Daten: ${count}`)
      this.importRunning = false
      this.parse_csv = []
      this.progress_value = 0
      this.progress_max = 0
      this.fileName = ''
    },
    csvJSON (csv) {
      var lines = csv.split(/\r\n|\n/)
      var result = []
      var headers = lines[0].split(';')
      this.parse_header = lines[0].split(';')
      this.missing_fields = []
      this.fields.forEach(element => {
        if (!this.parse_header.includes(element)) {
          this.missing_fields.push(element)
        }
      })

      lines.map((line, indexLine) => {
        if (indexLine < 1) return // Jump header line

        var obj = {}
        var currentline = line.split(';')

        if (currentline.toString().trim() === '') return // empty lines

        headers.map((header, indexHeader) => {
          obj[header] = currentline[indexHeader]
        })

        result.push(obj)
      })
      return result // JavaScript object
    },
    loadCSV (e) {
      this.logs = []
      this.loading = true
      var vm = this
      if (window.FileReader) {
        var reader = new FileReader()
        this.fileName = e.target.files[0].name
        reader.readAsText(e.target.files[0])
        // Handle errors load
        reader.onload = function (event) {
          var csv = event.target.result
          vm.parse_csv = vm.csvJSON(csv)
          vm.loading = false
        }
        reader.onerror = function (evt) {
          if (evt.target.error.name === 'NotReadableError') {
            alert("Canno't read file !")
          }
          vm.loading = false
        }
      } else {
        alert('FileReader are not supported in this browser.')
      }
    },
    onClick (item) {
      this.$emit('input', item.id)
      this.selected = item
      this.open = false
    }
  },
  async mounted () {
  }
}
</script>
