<template>
  <div>
    <div v-if="isLoading">
      <loading-spinner></loading-spinner>
    </div>
    <div v-else>
      <div v-if="hospitals" class="w-full mt-3">
        <h3 class="mb-2 font-bold">⚠️ Ungültige Anzahl Administratoren ⚠️ </h3>
        <v-table
          :headers="headersHospital"
          :items="hospitals.results"
          :selectable="true"
          @click="onClick">
        </v-table>
        <Pagination :paginationInitial="paginationAdminCheck" @on-new-page="onNewPageAdminCheck"/>
      </div>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/v-table'
import Pagination from '@/components/Pagination'
import dateMixin from '@/mixins/date'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  mixins: [dateMixin],
  components: {
    'v-table': VTable,
    Pagination,
    LoadingSpinner
  },
  data () {
    return {
      hospitals: null,
      orders: null,
      ordersError: null,
      currentOrder: null,
      isLoading: false
    }
  },
  computed: {
    headersHospital () {
      var tmp = this.$store.getters['studek/hospitals/fields'].filter(f => f.showSelect)
      tmp.unshift({ text: 'Admins', value: 'admins', width: 80 })
      return tmp
    },
    paginationAdminCheck () {
      return {
        count: this.hospitals.count,
        current: this.hospitals.current,
        next: this.hospitals.next,
        previous: this.hospitals.previous,
        size: 5
      }
    }
  },
  methods: {
    async onNewPageAdminCheck (newPage) {
      this.hospitals = await this.$store.dispatch('studek/hospitals/load', { callback: true, pagination: true, query: { checkPermissions: 1, state: 1, size: 5, page: newPage } })
    },
    onClick (item) {
      this.$router.push({ path: `studek/hospitals/${item.id}` })
    },
  },
  async mounted () {
    this.isLoading = true
    this.hospitals = await this.$store.dispatch('studek/hospitals/load', { callback: true, pagination: true, query: { checkPermissions: 1, state: 1, size: 5 } })
    this.isLoading = false
  }
}
</script>
