import Crud from '../../crud'
// import { i18n } from '@/main.js'
var crud = new Crud('irp/irpUserFiles')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    // canDeleteMulti: state => false,
    // hasHistory: () => false,
    contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('irp.irpUserFiles').id,
    extractUserFiles: (state) => state[0].files,
    // title: (state, getters, rootState, rootGetters) => (id) => {
    //   const item = getters.detailItem
    //   if (item) {
    //     return item.name
    //   } else {
    //     i18n.t('irp.hospitals')
    //   }
    // },
    // canAddMailJob: () => true,
    // queryGet: () => { return { byPermissions: 0 } },
    fields () {
      return [
        { text: 'Id', value: 'id', hideList: true, hideEdit: true, hideCreate: true },
        { text: 'Name', value: 'name', width: -1, showSelect: true },
        { text: 'Strasse / Nr', value: 'street_nr', width: 150, showSelect: false },
        { text: 'Postfach', value: 'postbox', width: 80 },
        { text: 'Plz', value: 'plz', width: 60, showSelect: false },
        { text: 'Ort', value: 'place', width: 80, showSelect: false },
        { text: 'Benutzer', value: 'users', type: 'component', componentName: 'Users', width: 120, hideCreate: true, hideList: true },
        { text: 'Dateien', value: 'files', type: 'component', componentName: 'Files', hideCreate: true, hideList: true, width: 120 }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
