import Crud from '../../crud'
import ApiClient from '@/assets/js/ApiClient.js'
var crud = new Crud('patzu/declarations')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    years () {
      var currentYear = new Date().getFullYear() + 1; var years = []
      var startYear = 2019
      while (startYear <= currentYear) {
        years.unshift(startYear++)
      }
      return years
    },
    filters (state, getters, rootState, rootGetters) {
      return [
        {
          name: 'Jahre',
          options: getters.years.map(y => { return { id: y, value: y } }),
          field: 'measure_year'
        },
        {
          name: 'Kategorien',
          options: rootGetters['patzu/hospitals/categories'],
          field: 'hospital__category'
        }
      ]
    },
    exportActions () {
      return [
        {
          name: 'Deklarationen',
          path: 'patzu/declarations/export/?byPermissions=0'
        }
      ]
    },
    fields () {
      var bfsTypes = (item) => {
        if (!item) {
          return []
        }
        // Beim erstellen ist das hospital objekt noch nicht vorhanden
        const id = item.hospital ? item.hospital.id : item.hospital_id
        return ApiClient.get(`/patzu/hospitals/${id}/bfs/?byPermissions=0`).then(response => {
          return response.data
        })
      }
      return [
        { text: 'Messung', value: 'measure_id', type: 'rel_select', getterPath: 'patzu/measures', hideList: true, hideEdit: true },
        {
          text: 'Spital',
          value: 'hospital_id',
          type: 'rel_select',
          getterPath: 'patzu/hospitals',
          hideList: true,
          hideEdit: true,
          hideCreate: (item) => {
            if (item && item.measure_id) {
              return false
            }
            return true
          },
          query: (item) => { return item && item.measure_id ? { measures__id: item.measure_id } : null }
        },
        {
          text: 'Spital',
          value: 'hospital.name',
          order: 'hospital__name',
          readOnly: true,
          hideCreate: true,
          hideEdit: true,
          showSelect: true,
          width: -1
        },
        {
          text: 'Kategorie',
          value: 'hospital.category_text',
          order: 'hospital__category',
          readOnly: true,
          hideCreate: true,
          hideEdit: true,
          showSelect: true,
          width: 250
        },
        {
          text: 'AnqId',
          value: 'hospital.anqid',
          order: 'hospital__anqid',
          readOnly: true,
          hideCreate: true,
          hideEdit: true,
          showSelect: true,
          width: 80
        },
        {
          text: 'Jahr',
          value: 'measure.year',
          order: 'measure__start_date',
          readOnly: true,
          hideCreate: true,
          hideEdit: true,
          showSelect: true,
          width: 50
        },
        { text: 'Gesendet', value: 'frabos_sent', type: 'integer', width: 100, default: -1, showSelect: true },
        { text: 'Austritte', value: 'exits_count', type: 'integer', width: 100, default: -1, showSelect: true },
        {
          text: 'BFS-Typ',
          value: 'bfs_type',
          type: 'select',
          optionsFunc: bfsTypes,
          hideList: true,
          hideCreate: (item) => {
            if (item && item.hospital_id) {
              return false
            }
            return true
          }
        },
        {
          text: 'BFS-Typ',
          value: 'bfs_type_text',
          order: 'bfs_type',
          hideCreate: true,
          hideEdit: true,
          showSelect: true,
          width: 200
        },
        { text: 'Typ', value: 'typ', type: 'integer', width: 100, default: -1 }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
