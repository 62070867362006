
<template>
  <div style="display:contents">
    <div v-if="showCategoryLimitModal" class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-start z-50">
      <div class="absolute w-full h-full bg-neutral-900 opacity-50"></div>
      <div class="bg-white dark:bg-neutral-900 w-[90%] max-w-[800px] mx-auto rounded shadow-lg z-50 overflow-y-auto mt-16">
        <div class="modal-content py-4 text-left px-6">
          <h2 class="font-semibold">
            {{$t('Das Limit der darstellbaren Kategorien für diesen Grafiktyp wurde überschritten.')}}
          </h2>
          <p class="text-sm">
            {{$t('Bitte wählen Sie einen anderen Grafiktyp oder passen Sie die für die Grafik benutzten Attribute an.')}}
          </p>
          <button class="my-2" @click="showCategoryLimitModal = false">Ok</button>
        </div>
      </div>
    </div>

    <div v-if="seriesDataLimitModal" class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-start z-50">
      <div class="absolute w-full h-full bg-neutral-900 opacity-50"></div>
      <div class="bg-white dark:bg-neutral-900 w-[90%] max-w-[800px] mx-auto rounded shadow-lg z-50 overflow-y-auto mt-16">
        <div class="modal-content py-4 text-left px-6">
          <h2 class="font-semibold">
            {{$t('Es sind keine auswertbare Daten vorhanden')}}
          </h2>
          <p class="text-sm">
            {{$t('Bitte wählen Sie einen anderen Grafiktyp oder passen Sie die für die Grafik benutzten Attribute an. Mean, Median, Summe und Unique können nur auf numerische Attribute angewandt werden.')}}
          </p>
          <button class="my-2" @click="seriesDataLimitModal = false">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['seriesLength', 'currentCountOfCategories', 'currentCountOfLabels', 'maxCategories', 'maxLabels'],
  data: function () {
    return {
      showCategoryLimitModal: false,
      seriesDataLimitModal: false,
      isMounted: false
    }
  },
  methods: {
    checkSeriesLengthInGraphic () {
      if (this.seriesLength && !this.seriesLength.length) {
        this.seriesDataLimitModal = true
      } else {
        this.seriesDataLimitModal = false
      }
    },
    checkLimitOfCategoriesInGraphic () {
      if ((this.currentCountOfCategories && this.currentCountOfCategories > this.maxCategories && this.maxCategories) ||
      this.currentCountOfLabels > this.maxLabels) {
        this.showCategoryLimitModal = true
      } else {
        this.showCategoryLimitModal = false
      }
    }
  },
  mounted () {
    this.isMounted = true
  },
  watch: {
    seriesLength () {
      // Code to run when seriesLength changes
      this.checkSeriesLengthInGraphic()
    },
    currentCountOfCategories () {
      // Code to run when currentCountOfCategories changes
      this.checkLimitOfCategoriesInGraphic()
    },
    currentCountOfLabels () {
      // Code to run when currentCountOfLabels changes
      this.checkLimitOfCategoriesInGraphic()
    }
  }
}
</script>
