import ApiClient from '../assets/js/ApiClient.js'
import Vue from 'vue'
import { i18n } from '@/main.js'

export default class {
  constructor (endpoint) {
    this.state = {
      endpoint: endpoint,
      query: '?byPermissions=true',
      all: [],
      pagination: null,
      loaded: false,
      detailItem: null,
      detailUserCharts: null,
      previousLanguageChangedState: false
    }
    this.getters = {
      canDeleteMulti: () => true,
      hasData: state => state.all.length > 0,
      isLoaded: state => state.loaded,
      pagination: state => state.pagination,
      all: state => state.all,
      getById: (state) => (id) => { return state.all.find(o => o.id === parseInt(id)) },
      detailItem: (state) => state.detailItem,
      detailUserCharts: (state) => state.detailUserCharts,
      previousLanguageChangedState: (state) => state.previousLanguageChangedState
    }
    this.actions = {
      async add ({ commit, state, dispatch }, payload) {
        // this add function is used different cases. When you click saveBtn like: in profile or saveEvaluation or in Messungen this function is working
        // payload is comming as an array only when I save Evaluation because there should be two api requests but not in all other cases
        if (Array.isArray(payload)) {
          payload = payload[0]
        }
        if (this._actions[`${state.endpoint}/beforeAdd`]) {
          payload = await dispatch('beforeAdd', payload)
        }
        var prom = new Promise((resolve) => {
          dispatch('startLoading', null, { root: true })
          ApiClient.post(state.endpoint + '/', payload).then(response => {
            commit('add', response.data)
            const title = state.endpoint.replace('/', '.').slice(0, -1)
            const param = i18n.te(title) ? i18n.t(title) : i18n.t('object')
            dispatch('addNotification', { title: i18n.te(title) ? i18n.t(title) : i18n.t('new-object'), message: i18n.t('new-object-text', { object: param }) }, { root: true })
            resolve(response.data)
          }).catch((err) => {
            Object.entries(err.response.data).forEach(([key, value]) => {
              dispatch('addNotification', { title: `Feld: ${key}`, message: value[0] || value, error: true }, { root: true })
            })
            dispatch('endLoading', null, { root: true })
          }).finally(() => {
            dispatch('endLoading', null, { root: true })
          })
        })
        return prom
      },
      async load ({ commit, state, dispatch, rootGetters }, payload) {
        if (!payload || !payload.ignorePreloads) {
          if (this.getters[`${state.endpoint}/neededCollections`] !== undefined) {
            for (const collection of this.getters[`${state.endpoint}/neededCollections`]) {
              await dispatch(collection + '/load', { ignorePreloads: true, all: true }, { root: true })
            }
          }
        }

        var prom = new Promise((resolve) => {
          dispatch('startLoading', null, { root: true })

          var query = this.getters[`${state.endpoint}/queryGet`] !== undefined
            ? JSON.parse(JSON.stringify(this.getters[`${state.endpoint}/queryGet`]))
            : {}
          if (payload && payload.query && payload.query.size === undefined && payload.all === undefined) {
            payload.query.size = 15
          }
          if (payload && payload.all && payload.query && payload.query.size) {
            delete payload.query.size
          }
          if (payload && payload.query && rootGetters.sortKey) {
            payload.query.ordering = rootGetters.sortOrder > 0 ? rootGetters.sortKey : `-${rootGetters.sortKey}`
          }
          if (payload && payload.query && Object.keys(payload.query).length) {
            query = Object.assign(query, payload.query)
          }
          const queryStr = '?' + Object.keys(query).map(key => key + '=' + query[key]).join('&')
          ApiClient.get(state.endpoint + '/' + queryStr).then(response => {
            if (response.data.results) {
              if (payload && payload.callback) {
                if (payload.pagination) {
                  commit('setPagination', {
                    count: response.data.count,
                    current: response.data.current,
                    next: response.data.next,
                    previous: response.data.previous,
                    size: payload.query.size
                  })
                  resolve(response.data)
                } else {
                  resolve(response.data.results)
                }
              } else {
                commit('store', response.data.results)
                commit('setPagination', {
                  count: response.data.count,
                  current: response.data.current,
                  next: response.data.next,
                  previous: response.data.previous,
                  size: payload.query.size
                })
              }
            } else {
              if (payload && payload.callback) {
                resolve(response.data)
              } else if (!payload || payload.expand === undefined) {
                commit('store', response.data)
              } else {
                commit('storeOrUpdate', response.data)
              }
            }
          }).catch((err) => {
            Object.entries(err.response.data).forEach(([value]) => {
              const msg = Array.isArray(value) ? value[0] : value
              dispatch('addNotification', { title: 'Fehler', message: msg, error: true, permanent: false }, { root: true })
            })
            dispatch('endLoading', null, { root: true })
          }).finally(() => {
            dispatch('endLoading', null, { root: true })
            resolve()
          })
        })
        return prom
      },
      // this function is running when userProfile is loading => /accounts/users/id or I am loading /accounts/demo_users/id or /accounts/deedbacks/id, maybe there is more urls where this function is running
      async loadDetail ({ commit, state }, id) {
        commit('storeDetail', null)
        var prom = new Promise((resolve) => {
          const query = this.getters[`${state.endpoint}/queryGet`] !== undefined ? this.getters[`${state.endpoint}/queryGet`] : {}
          const queryStr = '?' + Object.keys(query).map(key => key + '=' + query[key]).join('&')
          ApiClient.get(state.endpoint + '/' + id + '/' + queryStr).then(response => {
            if (state.endpoint === 'moniq/hospitals') {
              response.data.files.sort((a, b) => {
                const dateA = new Date(a.modified)
                const dateB = new Date(b.modified)
                return dateB - dateA
              })
            }
            commit('storeDetail', response.data)
            resolve(response.data)
          }).finally(() => {
            resolve()
          })
        })
        return prom
      },
      // this function is running when evaluation is loading. There are two fetches inside: 1. get evaluation data 2.get UserCharts
      async loadDetailEvaluation ({ commit, state, dispatch, rootGetters }, id) {
        commit('storeDetail', null)
        const query = this.getters[`${state.endpoint}/queryGet`] !== undefined ? this.getters[`${state.endpoint}/queryGet`] : {}
        const queryStr = '?' + Object.keys(query).map(key => key + '=' + query[key]).join('&')
        let evaluationInfo = {}
        const request1 = ApiClient.get(state.endpoint + '/' + id + '/' + queryStr)
        const request2 = ApiClient.get(`results/user_charts/?evaluation=${id}&user=${rootGetters.user.id}/`)
        await Promise.all([request1, request2])
          .then(([response1, response2]) => {
            evaluationInfo = response1.data
            commit('storeDetail', evaluationInfo)

            // second fetch
            const userChartInfo = response2.data[0] === undefined
              ? {
                charts_info: [],
                evaluation: parseInt(id),
                user: parseInt(rootGetters.user.id)
              }
              : { ...response2.data[0] }
            const userChartArrCharts = response2.data[0] ? [...response2?.data[0]?.charts_info] : []
            const newUserChartArr = []
            const langs = ['de', 'fr', 'it']
            // get default language
            const userLanguage = langs[rootGetters.user.language - 1]
            const userLanguagePrevious = langs[rootGetters.user.language_previous - 1]
            function renameUserChartInfo () {
              // Assign new key
              userChartInfo.evaluation = userChartInfo.evaluation_id
              userChartInfo.user = userChartInfo.user_id
              // Delete old key
              delete userChartInfo.evaluation_id
              delete userChartInfo.user_id
            }
            if (response2.data.length !== 0) {
              renameUserChartInfo()
            }
            // function that sort charts if it is chart and add there from response.data[0].chart_info every chartTitle and chartSubtitle and add it to the storeDetail in charts
            if (userChartArrCharts.length === 0) {
              // create new object with languageTabTitle and languageTabSubtitle and put them in store
              const newCharts = evaluationInfo.charts.map((chart) => {
                // eslint-disable-next-line no-prototype-builtins
                if (chart.hasOwnProperty('title')) {
                  chart.languageTabTitle = userLanguage
                  chart.languageTabSubtitle = userLanguage

                  newUserChartArr.push({
                    i: chart.i,
                    languageTabTitle: userLanguage,
                    languageTabSubtitle: userLanguage
                  })
                }

                return chart
              })
              // change old charts without fields: languageTabTitle and languageTabSubtitle to new one
              evaluationInfo.charts = newCharts
              userChartInfo.charts_info = newUserChartArr
              commit('storeDetail', evaluationInfo)
              commit('storeDetailUserCharts', userChartInfo)
            } else if (userChartArrCharts !== 0) {
              // find coincidence by id in chartInfoArr and charts and join two objects
              const newCharts = evaluationInfo.charts.map((chart) => {
                let isCoinidence = 0

                for (let userChart = 0; userChart < userChartArrCharts.length; userChart++) {
                  // put from languageTabTitle and languageTabSubtitle to the chart
                  if (userChartArrCharts[userChart].i === chart.i) {
                    isCoinidence++
                    // Are there values in userChartArr from db and did user change his language previously in settings before he load evaluation?
                    if (userChartArrCharts[userChart].languageTabTitle && userLanguage === userLanguagePrevious) {
                      chart.languageTabTitle = userChartArrCharts[userChart].languageTabTitle
                      chart.languageTabSubtitle = userChartArrCharts[userChart].languageTabSubtitle

                      newUserChartArr.push({
                        i: chart.i,
                        languageTabTitle: userChartArrCharts[userChart].languageTabTitle,
                        languageTabSubtitle: userChartArrCharts[userChart].languageTabSubtitle
                      })
                    } else {
                      chart.languageTabTitle = userLanguage
                      chart.languageTabSubtitle = userLanguage

                      newUserChartArr.push({
                        i: chart.i,
                        languageTabTitle: userLanguage,
                        languageTabSubtitle: userLanguage
                      })
                    }
                  }
                }

                // check if there were created new charts but user doesn't save it to db yet and they doesn't have languageTabTitle and languageTabSubtitle and there wasn't coincidence above
                // eslint-disable-next-line no-prototype-builtins
                // eslint-disable-next-line no-prototype-builtins
                if (isCoinidence === 0 && chart.hasOwnProperty('title')) {
                  chart.languageTabTitle = userLanguage
                  chart.languageTabSubtitle = userLanguage

                  newUserChartArr.push({
                    i: chart.i,
                    languageTabTitle: userLanguage,
                    languageTabSubtitle: userLanguage
                  })
                }
                return chart
              })
              // change old charts without fields: languageTabTitle and languageTabSubtitle to new one
              evaluationInfo.charts = newCharts
              userChartInfo.charts_info = newUserChartArr
              commit('storeDetail', evaluationInfo)
              commit('storeDetailUserCharts', userChartInfo)
            }
            if (userLanguage !== userLanguagePrevious) {
              // save to variable in crud.js where I said that I complete change to preveious language in user profile
              // This is local variabale that every time created when evaluation is loading
              commit('previousLanguageChanged', true)
              // change to user Profile previousLanguage to current Lnaguage
              dispatch('changeUserPreviousLanguageToCurrent', rootGetters.user.language, { root: true })
            }
          })
      },
      async getAll ({ state }, payload) {
        var prom = new Promise((resolve) => {
          var query = payload.query
          const queryStr = '?' + Object.keys(query).map(key => key + '=' + query[key]).join('&')
          ApiClient.get(state.endpoint + '/' + queryStr).then(response => {
            resolve(response.data)
          }).finally(() => {
            resolve()
          })
        })
        return prom
      },
      async getDetail ({ state }, id) {
        var prom = new Promise((resolve) => {
          const query = this.getters[`${state.endpoint}/queryGet`] !== undefined ? this.getters[`${state.endpoint}/queryGet`] : {}
          const queryStr = '?' + Object.keys(query).map(key => key + '=' + query[key]).join('&')
          ApiClient.get(state.endpoint + '/' + id + '/' + queryStr).then(response => {
            resolve(response.data)
          }).finally(() => {
            resolve()
          })
        })
        return prom
      },
      delete ({ commit, state, dispatch }, payload) {
        const id = payload.id ? payload.id : payload
        var prom = new Promise((resolve) => {
          dispatch('startLoading', null, { root: true })
          ApiClient.delete(`${state.endpoint}/${id}/`).then(() => {
            commit('remove', id)
            commit('storeDetail', null)
            dispatch('addNotification', { title: i18n.t('deleted'), message: i18n.t('deleted-succeed-text') }, { root: true })
            resolve()
          }).catch((err) => {
            Object.entries(err.response.data).forEach(([key, value]) => {
              const msg = Array.isArray(value) ? value[0] : value
              dispatch('addNotification', { title: `Feld: ${key}`, message: msg, error: true }, { root: true })
            })
            dispatch('endLoading', null, { root: true })
          }).finally(() => {
            dispatch('endLoading', null, { root: true })
          })
        })
        return prom
      },
      restore ({ state, dispatch }, payload) {
        const id = payload.id ? payload.id : payload
        var prom = new Promise((resolve) => {
          dispatch('startLoading', null, { root: true })
          ApiClient.put(`${state.endpoint}/${id}/restore/`).then(() => {
            dispatch('addNotification', { title: i18n.t('restored'), message: i18n.t('restored-succeed-text') }, { root: true })
            resolve()
          }).catch((err) => {
            Object.entries(err.response.data).forEach(([key, value]) => {
              const msg = Array.isArray(value) ? value[0] : value
              dispatch('addNotification', { title: `Feld: ${key}`, message: msg, error: true }, { root: true })
            })
            dispatch('endLoading', null, { root: true })
          }).finally(() => {
            dispatch('endLoading', null, { root: true })
          })
        })
        return prom
      },
      async update ({ commit, state, dispatch, rootGetters }, payload) {
        dispatch('startLoading', null, { root: true })
        let payloadEvaluationOrUserInfo = payload[0]
        const payloadUserCharts = payload[1]
        if (this._actions[`${state.endpoint}/beforeUpdate`]) {
          // payload[0] is's editEvaluation array, payload[1] it's userChart array
          payload = await dispatch('beforeUpdate', payloadEvaluationOrUserInfo)
        }
        if (!payloadEvaluationOrUserInfo && !payloadUserCharts) {
          payloadEvaluationOrUserInfo = payload
        }
        var prom = new Promise((resolve) => {
          ApiClient.put(`${state.endpoint}/${payloadEvaluationOrUserInfo.id}/`, payloadEvaluationOrUserInfo).then(response => {
            commit('update', response.data)
            if (state.endpoint !== 'results/measures') {
              commit('storeDetail', response.data)
            }
            dispatch('endLoading', null, { root: true })
            if (this._actions[`${state.endpoint}/afterUpdate`]) {
              dispatch('afterUpdate', response.data)
            }
            dispatch('addNotification', { title: i18n.t('saved'), message: i18n.t('saved-succeed-text') }, { root: true })
            resolve(true)
          }).then(() => {
            // change it that in url is onl id of the USERCHART
            // based on get request when app loaded, if I don't get anything in get request then UserChart doesn't have id because id is automaticly created when there is post reqest to db
            if (state.endpoint.includes('evaluations')) {
              if (payloadUserCharts.id) {
                // make put request if userChart has already exist in db
                ApiClient.put(`results/user_charts/${payloadUserCharts.id}/`, payloadUserCharts).then(response => {
                  commit('storeDetailUserCharts', response.data)
                })
              } else {
                // make post request if userChart hasn't exist in db yet
                ApiClient.post('results/user_charts/', payloadUserCharts).then(response => {
                  commit('storeDetailUserCharts', response.data)
                })
              }
            }
          }).then(() => {
            // save user profile if previously user changed language and are in evaluation tab because THIS UPDATE FUNCTION IS ALSO CALLED WHEN I CLICK SAVE BTN IN USER PROFILE!!!
            // AND I DON'T NEED TO DOUBLE SAVE USER!!!
            if (state.endpoint.includes('evaluations') && state.previousLanguageChangedState) {
              ApiClient.patch(`accounts/users/${rootGetters.user.id}/`, { language_previous: rootGetters.user.language }).then(() => {
                dispatch('changeUserPreviousLanguageToCurrent', rootGetters.user.language, { root: true })
              })
            }
          }).catch((err) => {
            Object.entries(err.response.data).forEach(([key, value]) => {
              dispatch('addNotification', { title: `Feld: ${key}`, message: value, error: true }, { root: true })
            })
            dispatch('endLoading', null, { root: true })
            resolve(false)
          }).finally(() => {
            resolve(true)
          })
        })
        return prom
      }
    }
    this.mutations = {
      setPagination (state, payload) {
        state.pagination = payload
      },
      reset (state) {
        state.all = []
        state.loaded = false
      },
      add (state, objects) {
        state.all.push(objects)
      },
      store (state, objects) {
        state.all = objects
        state.loaded = true
      },
      storeOrUpdate (state, objects) {
        objects.forEach(item => {
          const i = objects.findIndex(_item => _item.id === item.id)
          if (i > -1) state.all[i] = item
          else state.all.push(item)
        })
        state.loaded = true
      },
      storeDetail (state, item) {
        state.detailItem = item
      },
      storeDetailUserCharts (state, item) {
        state.detailUserCharts = item
      },
      remove (state, id) {
        const index = state.all.findIndex(item => item.id === id)
        if (index !== -1) state.all.splice(index, 1)
      },
      previousLanguageChanged (state, item) {
        state.previousLanguageChangedState = item
      },
      update (state, object) {
        const index = state.all.findIndex(item => item.id === object.id)
        if (index !== -1) Vue.set(state.all, index, object)
      }
    }
  }
}
