<template>

<div class="fixed inset-0 overflow-hidden z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true"
 :class="[open ? '': 'pointer-events-none']">
  <div class="absolute inset-0 overflow-hidden">
    <transition
      enter-active-class="ease-in-out duration-500"
      leave-active-class="ease-in-out duration-500"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      >

    <div v-if="open" class="absolute inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    </transition>

    <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
      <transition
      enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full"
      >
      <div v-if="open" class="relative w-screen max-w-md">
        <transition
          enter-active-class="ease-in-out duration-500"
          leave-active-class="ease-in-out duration-500"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-if="open" class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
            <button @click="close()" class="bg-transparent p-0 hover:bg-transparent rounded-md text-neutral-300 hover:text-white">
              <span class="sr-only">Close panel</span>
              <!-- Heroicon name: outline/x -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </transition>

        <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
          <div class="px-4 sm:px-6">
            <h2 class="text-2xl font-bold text-neutral-900" id="slide-over-title">
              Versionen
            </h2>
          </div>
          <div class="mt-6 relative flex-1 px-4 sm:px-6">
            <div class="relative">
              <div class="border-r-2 border-neutral-500 absolute h-full top-0" style="left: 7px"></div>
              <ul class="list-none m-0 p-0">
                <li v-for="(history, index) in versionsCurrent" :key="history.history_id" class="mt-4">
                  <div class="flex items-center">
                    <div class="bg-neutral-500 rounded-full h-4 w-4"></div>
                    <div class="flex-1 ml-2 font-medium">{{formatUser(history.revision.user)}}</div>
                  </div>
                  <div class="ml-6 text-neutral-600">
                    {{formatRelativeTime(history.revision.date_created)}}
                  </div>
                  <div v-if="history.revision.comment" class="ml-6 text-neutral-500 italic">
                    {{history.revision.comment}}
                  </div>
                  <div v-else-if="getChangedValues(index).length === 0" class="ml-6 text-neutral-500 italic">
                    Erstellt
                  </div>
                  <div v-for="(change, ix) in getChangedValues(index)" :key="ix" class="ml-6 mt-1">
                    <div class="p-1 text-xs" >{{getPath(change.path)}}</div>
                    <template v-if="change.op === 'update'">
                      <div class="m-1 p-1 mt-0 text-sm bg-white text-red-500">{{change.oldVal}}</div>
                      <div class="m-1 p-1 text-sm bg-white text-emerald-500">{{change.val}}</div>
                    </template>
                    <template v-if="change.op === 'delete'">
                      <div class="m-1 p-1 mt-0 text-sm bg-white text-red-500 line-through">{{change.oldVal}}</div>
                    </template>
                    <template v-if="change.op === 'add'">
                      <div class="m-1 p-1 text-sm bg-white text-emerald-500">{{change.val}}</div>
                    </template>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getDiff } from 'recursive-diff'
import dateMixin from '@/mixins/date'
export default {
  props: ['open', 'contentType', 'objectId'],
  mixins: [dateMixin],
  data: function () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      versions: 'accounts/versions/all'
    }),
    formatUser () {
      return user => {
        if (user) {
          return `${user.first_name} ${user.last_name}`
        } else {
          return '🤖'
        }
      }
    },
    versionsCurrent () {
      return this.versions.filter(v => v.content_type.id === this.contentType && v.object_id === this.objectId)
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    getChangedValues (index) {
      var previous = this.versionsCurrent[index + 1]
      if (previous === undefined) {
        return []
      }

      const previousData = JSON.parse(previous.serialized_data)[0].fields
      // Find related objects to this revision
      const objectsPrev = this.getRelationObjectsForRevision(previous.revision.id)
      objectsPrev.forEach(obj => {
        previousData[obj.object_repr] = JSON.parse(obj.serialized_data)[0].fields
      })
      const thisData = JSON.parse(this.versionsCurrent[index].serialized_data)[0].fields
      const objectsCurrent = this.getRelationObjectsForRevision(this.versionsCurrent[index].revision.id)
      objectsCurrent.forEach(obj => {
        thisData[obj.object_repr] = JSON.parse(obj.serialized_data)[0].fields
      })
      return getDiff(previousData, thisData, true)
    },
    getRelationObjectsForRevision (revisionId) {
      return this.versions.filter(v => v.content_type.id !== this.contentType && v.object_id !== this.objectId && v.revision.id === revisionId)
    },
    getPath (path) {
      if (path.length === 1) {
        return path[0]
      } else {
        return path.join(' ⇒ ')
      }
    }
  },
  watch: {
    open: {
      async handler (val) {
        if (val) {
          await this.$store.dispatch('accounts/versions/load', { all: true, query: { content_type: this.contentType, object_id: this.objectId } })
        }
      }
    }
  }
}
</script>
