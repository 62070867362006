import ApiClient from '@/assets/js/ApiClient.js'
import Vue from 'vue'

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    )
    return result
  }, {})
}

export default {
  namespaced: true,
  state: () => ({
    attributes: {},
    questions: {},
    groups: {}
  }),
  getters: {
    getAttributesById: (state) => (id, lang) => { return state.attributes[id] ? state.attributes[id][lang] : [] },
    getQuestionsById: (state) => (id, lang) => { return state.questions[id] ? state.questions[id][lang] : [] },
    getGroupsById: (state) => (id, lang) => {
      return state.groups[id][lang].filter(g => g.language === lang).sort((a, b) => parseInt(a.group_order) - parseInt(b.group_order))
    },
    getQuestionsByIdGrouped: (state) => (id, lang) => {
      var tmp = groupBy(state.questions[id][lang].filter(q => q.parent_qid === '0').sort((a, b) => parseInt(a.question_order) - parseInt(b.question_order)), 'gid')
      // merge other language
      Object.keys(state.questions[id]).forEach(newLang => {
        if (lang !== newLang) {
          // Iterate group
          Object.keys(tmp).forEach(groupId => {
            tmp[groupId].forEach((question, index) => {
              const item = state.questions[id][newLang].find(q => q.qid === question.id)
              tmp[groupId][index][`question_${newLang}`] = item.question
            })
          })
        }
      })
      return tmp
    },
    getQuestionsByIdAndQId: (state) => (id, qid, lang = 'de') => {
      return state.questions[id][lang].filter(q => q.parent_qid === qid).sort((a, b) => parseInt(a.question_order) - parseInt(b.question_order))
    }
  },
  mutations: {
    setAttributes (state, { surveyId, attributes, language }) {
      if (!state.attributes[surveyId]) {
        Vue.set(state.attributes, surveyId, {})
      }
      Vue.set(state.attributes[surveyId], language, attributes)
    },
    setQuestions (state, { surveyId, questions, language }) {
      if (!state.questions[surveyId]) {
        Vue.set(state.questions, surveyId, {})
      }
      Vue.set(state.questions[surveyId], language, questions)
    },
    setGroups (state, { surveyId, groups, language }) {
      if (!state.groups[surveyId]) {
        Vue.set(state.groups, surveyId, {})
      }
      Vue.set(state.groups[surveyId], language, groups)
    },
    reset (state) {
      state.attributes = {}
      state.questions = {}
      state.groups = {}
    }
  },
  actions: {
    async getQuestionProperties ({ questionId, languages }) {
      var tmp = null
      for (const lang of languages) {
        var response = await ApiClient.get(`ls/questions/${questionId}?language=${lang}`)
        if (tmp) {
          Object.keys(tmp.answeroptions).forEach(key => {
            tmp.answeroptions[key][`answer_${lang}`] = response.data.properties.answeroptions[key].answer
          })
        } else {
          tmp = { type: response.data.properties.type, answeroptions: typeof response.data.properties.answeroptions === 'object' ? response.data.properties.answeroptions : {} }
        }
      }
      return tmp
    },
    load ({ commit, dispatch, state }, { surveyId, language = 'de' }) {
      if (state.attributes[surveyId] && state.attributes[surveyId][language]) {
        return
      }
      var prom = new Promise((resolve) => {
        ApiClient.get(`ls/${surveyId}/attributes?language=${language}`).then(response => {
          commit('setAttributes', { surveyId: surveyId, attributes: response.data.attributes, language: language })
        }).finally(() => {
          ApiClient.get(`ls/${surveyId}/questions?language=${language}`).then(response => {
            commit('setQuestions', { surveyId: surveyId, questions: response.data.questions, language: language })
          }).finally(() => {
            ApiClient.get(`ls/${surveyId}/groups?language=${language}`).then(response => {
              commit('setGroups', { surveyId: surveyId, groups: response.data.groups, language: language })
            }).catch(() => {
              dispatch('addNotification', { title: 'Fehler', message: 'Die Attribute konnten nicht geladen werden. Überprüfe die Zugriffsrechte in Limesurvey', error: true }, { root: true })
              resolve()
            }).finally(() => {
              resolve()
            })
          })
        })
      })
      return prom
    }
  }
}
