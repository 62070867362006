import Crud from '../../crud'

var crud = new Crud('accounts/usertitles')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    canDeleteMulti: () => false,
    fields () {
      return [
        { text: 'Title DE', value: 'title_de' },
        { text: 'Title FR', value: 'title_fr' },
        { text: 'Title IT', value: 'title_it' }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
