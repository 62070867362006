import Crud from '../../crud'

import { i18n } from '@/main.js'
var crud = new Crud('ika/evaluations')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    hasHistory: () => true,
    canDeleteMulti: () => false,
    queryGet: () => { return { byPermissions: 0 } },
    title: (state, getters) => () => {
      const item = getters.detailItem
      if (item) {
        return item.evaluation_name
      } else {
        i18n.t('ika.evaluations')
      }
    },
    fields (state, getters, rootState, rootGetters) {
      return [
        {
          text: 'Auswertung',
          value: 'evaluation',
          type: 'rel_select',
          getterPath: 'results/evaluations',
          hideList: true,
          query: (item) => { return item && item.evaluation ? { evaluation: item.evaluation.id } : null }
        },
        {
          text: 'Auswertung',
          value: 'evaluation.name',
          hideEdit: true,
          hideCreate: true
        },
        {
          text: 'Typ',
          value: 'type',
          type: 'select',
          width: 220,
          options: rootGetters['ika/hospitals/types']
        }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
