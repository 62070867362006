import Crud from '../../crud'

import { i18n } from '@/main.js'
var crud = new Crud('moniq/hospitals')

function measureYears () {
  var currentYear = new Date().getFullYear() - 2
  var years = []
  var untilYear = currentYear + 4
  while (currentYear <= untilYear) {
    years.push({ id: currentYear, value: currentYear })
    currentYear++
  }
  return years
}

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    canDeleteMulti: () => false,
    hasHistory: () => true,
    contentType: (state, getters, rootState, rootGetters) => rootGetters['accounts/contenttypes/getByName']('moniq.hospital').id,
    exportActions (state, getters, rootState, rootGetters) {
      const ct = rootGetters['accounts/contenttypes/getByName']('moniq.hospital').id
      return [
        {
          name: 'Spitalliste',
          path: 'moniq/hospitals/export/?byPermissions=0'
        },
        {
          name: 'Berechtigungsliste',
          path: `accounts/app_permissions/export/?content_type=${ct}`,
          relationship_id_field: 'object_ids'
        }
      ]
    },
    filters (state, getters) {
      return [
        {
          name: 'Kategorien',
          options: getters.categories,
          field: 'categories'
        },
        {
          name: 'Dispensiert',
          options: [{ selected: true, value: 'Ja' }, { selected: false, value: 'Nein' }],
          field: 'is_dispensed'
        }
      ]
    },
    title: (state, getters) => () => {
      const item = getters.detailItem
      if (item) {
        return item.name
      } else {
        i18n.t('moniq.hospitals')
      }
    },
    canAddMailJob: () => true,
    queryGet: () => { return { byPermissions: 0 } },
    categories: () => [
      { id: 10, value: 'Erwachsene Psychiatrie' },
      { id: 11, value: 'Kinder und Jugendpsychiatrie' },
      { id: 12, value: 'Forensische Psychiatrie' },
      { id: 13, value: 'Alterspsychiatrie' }
    ],
    fields (state, getters) {
      return [
        { text: 'Name', value: 'name', width: -2, showSelect: true },
        { text: 'ANQ-Id', value: 'anqid', type: 'number', width: 80, showSelect: true },
        {
          text: 'Kategorie',
          value: 'category',
          type: 'select',
          width: 220,
          showSelect: true,
          options: getters.categories
        },
        { text: 'Dispensiert', textHeader: 'Disp.', value: 'is_dispensed', format: 'BooleanView', type: 'boolean', width: 60 },
        {
          text: 'Dispensiert ab Messjahr',
          textHeader: 'Disp. ab',
          value: 'dispensed_from',
          type: 'select',
          options: measureYears(),
          width: 80,
          hideEdit: (item) => !item || item.is_dispensed === false
        },
        {
          text: 'Dispensiert bis einschliesslich Messjahr',
          textHeader: 'Disp. bis',
          value: 'dispensed_until',
          type: 'select',
          options: measureYears(),
          width: 80,
          label: 'Bis auf weiteres...',
          hideEdit: (item) => !item || item.is_dispensed === false
        },
        {
          text: 'EP Spital',
          value: 'ref_hospital',
          type: 'rel_select',
          isMulti: false,
          getterPath: 'moniq/hospitals',
          hideList: true,
          query: (item) => { return item ? { category: 10 } : null },
          initQuery: (item) => { return item ? { category: 10 } : null },
          hideEdit: (item) => !item || item.category !== 13
        },
        {
          text: 'AP Spital',
          value: 'ap_hospital_name',
          type: 'link',
          width: 100,
          hideList: true,
          hideCreate: true,
          getLink: (item) => `/moniq/hospitals/${item.ap_hospital}`
        },
        { text: 'Benutzer', value: 'users', type: 'component', componentName: 'Users', width: 120, hideCreate: true, hideList: true },
        { text: 'Dateien', value: 'files', type: 'component', componentName: 'Files', hideCreate: true, width: 120 }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
