import Crud from '../../crud'

var crud = new Crud('accounts/contenttypes')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    fields () {
      return [
        { text: 'App', value: 'app_label' },
        { text: 'Model', value: 'model' }
      ]
    },
    getByName: (state) => (name) => { return state.all.find(ct => ct.app_label === name.split('.')[0] && ct.model === name.split('.')[1]) },
    results: (state) => {
      return state.all.filter(ct => (ct.model === 'hospital' && ct.app_label === 'patzu') || (ct.model === 'hospital' && ct.app_label === 'moniq') || (ct.model === 'hospital' && ct.app_label === 'ika') || (ct.model === 'group' && ct.app_label === 'apps'))
    },
    appPermissions: (state) => {
      const supportedModels = ['patzu.hospital', 'moniq.hospital', 'ika.hospital', 'apps.group', 'irp.hospital', 'studek.hospital']
      return state.all.filter(ct => {
        return supportedModels.indexOf(`${ct.app_label}.${ct.model}`) > -1
      })
    },
    objects: (state, getters, rootState, rootGetters) => (contentType, objectId) => {
      const ct = getters.getById(contentType)
      if (ct !== undefined) {
        if (ct.model === 'hospital' && ct.app_label === 'patzu') {
          return rootGetters['patzu/hospitals/getById'](objectId)
        } else if (ct.model === 'hospital' && ct.app_label === 'moniq') {
          return rootGetters['moniq/hospitals/getById'](objectId)
        } else if (ct.model === 'hospital' && ct.app_label === 'ika') {
          return rootGetters['ika/hospitals/getById'](objectId)
        } else if (ct.model === 'group' && ct.app_label === 'apps') {
          return rootGetters['apps/groups/getById'](objectId)
        } else {
          return undefined
        }
      } else {
        return undefined
      }
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
