<template>
<loading-spinner v-if="isLoading"></loading-spinner>
<div v-else>
    <div class="">
    <v-table :headers="fields" :items="selected" :language="language" :pickTranslationFromJson="pickTranslationFromJson" :ignoreLink="true" :ignoreHideList="true" :canDelete="!isReadOnly" @item-delete="removeItem"> </v-table>
    <template v-if="!isReadOnly">
      <button v-if="selected.length === 0" class="w-full mt-2" @click="openModal">{{isInEvaluation ? (pickTranslationFromJson ? pickTranslationFromJson(language, 'grants') : $t('grants')) : (pickTranslationFromJson ? pickTranslationFromJson(language, 'add') : $t('add'))+ '...' }}</button>
      <button v-else class="w-full mt-2" @click="openModal">{{isInEvaluation ? (pickTranslationFromJson ? pickTranslationFromJson(language, 'grants') : $t('grants')) :  (pickTranslationFromJson ? pickTranslationFromJson(language, 'change'): $t('change')) + '...' }}</button>
    </template>
    </div>
  <div v-if="open" class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center p-12 z-50">
    <div class="absolute w-full h-full bg-neutral-900 opacity-50"></div>

    <div class="flex flex-col bg-white w-2/3 h-full mx-auto rounded shadow-lg z-50 overflow-hidden">
      <div class="w-full flex items-center p-4 bg-white z-40 mr-10">
      <h1 class="flex-grow">{{ pickTranslationFromJson ? pickTranslationFromJson(language, 'object') : $t('object') }}</h1>
      </div>
      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="p-3" v-if="searchable">
        <input type="text" :placeholder="pickTranslationFromJson ? pickTranslationFromJson(language, 'search') : $t('search')" v-model="searchInput" />
      </div>
      <div class="text-left relative h-full overflow-y-auto px-2">
          <v-table @update-entries="handleEntriesUpdate" :searchText="searchInput" :headers="fields" :items="itemsraw" :language="language" :pickTranslationFromJson="pickTranslationFromJson" :showSelect="true"  :ignoreHideList="true" :selection="selected" :itemKey="null" :ignoreLink="true" @click="onClick"> </v-table>
      </div>
      <div class="w-full flex space-x-3 px-3 py-3 bg-white">
        <button class="primary w-full" @click="addAll">{{ pickTranslationFromJson ? pickTranslationFromJson(language, 'add-all') : $t('add-all') }} ({{items.length}})</button>
        <button class="primary w-full" @click="removeAll">{{ pickTranslationFromJson ? pickTranslationFromJson(language, 'remove-all') : $t('remove-all') }} ({{items.length}})</button>
        <button class="primary w-full" @click="done">{{ pickTranslationFromJson ? pickTranslationFromJson(language, 'ok') : $t('ok') }}</button>
        <button class="cancel w-full" @click="open = false">{{ pickTranslationFromJson ? pickTranslationFromJson(language, 'cancel') : $t('cancel') }}</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import VTable from '@/components/v-table.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    'v-table': VTable,
    LoadingSpinner
  },
  props: ['value', 'field', 'isReadOnly', 'fields', 'itemsraw', 'searchable', 'language', 'pickTranslationFromJson'],
  data () {
    return {
      loading: false,
      open: false,
      selected: [],
      searchInput: '',
      items: [...this.itemsraw],
      currentURL: window.location.pathname
    }
  },
  computed: {
    ...mapGetters({
      currentEvaluation: 'results/evaluations/detailItem'
    }),
    pathForMoniq () {
      return this.restrictToMoniq ? 'moniq/hospitals' : null
    },
    restrictToMoniq () {
      return this.currentEvaluation && this.currentEvaluation.content_type === 14
    },
    isLoading () {
      return this.loading
    }
  },
  methods: {
    isInEvaluation () {
      return this.currentUrl.startsWith('/results/evaluations/')
    },
    handleEntriesUpdate (entries) {
      this.items = entries
    },
    onEscKeyPress (event) {
      if (event.key === 'Escape' && this.open) {
        this.open = false
      }
    },
    removeAll () {
      this.selected = []
      this.$emit('input', this.selected.map(s => this.itemField ? s[this.itemField] : s.id))
      this.open = false
    },
    removeItem (item) {
      const index = this.selected.findIndex(s => s.id === item.id)
      this.selected.splice(index, 1)
      this.$emit('input', this.selected.map(s => s.id))
    },
    async openModal () {
      this.open = true
    },
    onClick (item) {
      this.selected.push(item)
      this.$emit('input', this.selected.map(s => this.itemField ? s[this.itemField] : s.id))
      this.open = false
    },
    async addAll () {
      this.selected = []
      this.selected.push(...this.items)
      this.$emit('input', this.selected.map(s => this.itemField ? s[this.itemField] : s.id))
      this.open = false
    },
    done () {
      this.$emit('input', this.selected.map(s => this.itemField ? s[this.itemField] : s.id))
      this.open = false
    }
  },
  async mounted () {
    this.loading = true
    const beforeFiltering = this.value ? this.value.map(v => { return { ...this.items.find(item => item.id === v) } }) : []
    this.selected = beforeFiltering.filter(_ => _.id != null)
    this.loading = false
    window.addEventListener('keydown', this.onEscKeyPress)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.onEscKeyPress)
  }

}
</script>
