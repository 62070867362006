import Crud from '../../crud'
import { i18n } from '@/main.js'
var crud = new Crud('results/imports')

export default {
  namespaced: true,
  state: () => ({
    ...crud.state
  }),
  getters: {
    ...crud.getters,
    canDeleteMulti: () => false,
    customAddView: () => 'results/AddImportFile',
    customItemsView: () => 'results/ImportListFiles',
    fields () {
      return [
        { text: i18n.t('created'), value: 'created', format: 'DateTime', type: 'DateTime', readOnly: true, hideCreate: true, width: 155 },
        { text: i18n.t('name'), value: 'name', width: -1 },
        { text: 'Resultate', value: 'lines_count', width: 100 }
      ]
    }
  },
  mutations: {
    ...crud.mutations
  },
  actions: {
    ...crud.actions
  }
}
