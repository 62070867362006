<template>
  <div class="flex flex-col gap-4" v-view.once="onceHandler">
      <chart-menu class='' v-if="!!chart" :chart="chart"></chart-menu>
      <div class="flex flex-col self-center">
          <h1 class="self-center">{{ getTranslatedTitle }}</h1>
          <h2 class="self-center">{{ getTranslatedSubtitle }}</h2>
      </div>
      <!-- display table -->
      <ve-table
          v-if="visible && !hideGraphic"
          :columns='getTableColumns'
          :table-data='getTableData'
          :sort-option='getSortOption'
      />
      <div v-if="hideGraphic" class="absolute top-1/2 left-1/2 -translate-x-1/2 text-lg font-bold">
          {{ $t('no-data') }}
      </div>
      <LimitModal
        :seriesLength="seriesLength"
        :currentCountOfCategories="currentCountOfCategories"
        :currentCountOfLabels="currentCountOfLabels"
        :maxCategories="MAX_CATEGORIES"
        :maxLabels="MAX_CATEGORIES_LABELS"
      />
  </div>
</template>

<script>
import ChartMenu from './ChartMenu.vue'
import LimitModal from '@/components/chartsFSP/LimitModal.vue'
import { mapGetters } from 'vuex'
import utilsMixin from '@/mixins/utils'

export default {
  mixins: [utilsMixin],
  components: {
    LimitModal,
    ChartMenu
  },
  props: {
    chart: {
      type: Object,
      default: null
    },
    language: {
      type: String,
      default: 'de'
    },
    isEditedChart: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      MAX_CATEGORIES: 23,
      MAX_CATEGORIES_LABELS: 200,
      currentCountOfCategories: 0,
      currentCountOfLabels: 0,
      seriesLength: null,
      hideGraphic: false,
      tableColumns: [],
      tableData: [],
      tableColumnsVariableValues: [],
      visible: false,
      categoriesCount: [],
      countObservationsArr: [],
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params)
        }

      }
    }
  },
  computed: {
    ...mapGetters({
      filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId'
    }),
    computedData () {
      return this.filteredDataForChartId(this.chart.i, this.chart.filters)
    },
    getTableColumns () {
      return this.tableColumns
    },
    getTableData () {
      return this.tableData
    },
    getSortOption () {
      return this.sortOption
    },
    getTranslatedTitle () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'title', this.language)
      } else {
        return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
      }
    },
    getTranslatedSubtitle () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'subtitle', this.language)
      } else {
        return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
      }
    },
    getSelectedSeries () {
      return this.chart.data
    },
    getSplitSeries () {
      return this.chart.splits.length > 0 ? this.chart.splits[0].data : []
    },
    getGroupsSeries () {
      return this.chart.groups.length > 0 ? this.chart.groups[0].data : []
    },
    getAdditionFunc () {
      return this.chart.addition
    },
    isSplit () {
      return this.chart.splits.length > 0
    },
    isDoubleSplit () {
      return this.chart.splits.length > 1
    },
    isGrouped () {
      return this.chart.groups.length > 0
    },
    isDoubleGrouped () {
      return this.chart.groups.length > 1
    },
    isHorizontal () {
      return this.chart.viewSettings[1] === 'horizontal'
    },
    splitSeries () {
      return this.isSplit ? this.chart.splits[0].data : []
    },
    splitSeriesDouble () {
      return this.isSplit ? this.chart.splits[1].data : []
    }
  },
  methods: {
    checkLimitOfCategoriesInGraphic (currentCountOfCategories, currentCountOfLabels) {
      if (currentCountOfCategories > this.MAX_CATEGORIES ||
      currentCountOfLabels > this.MAX_CATEGORIES_LABELS) {
        this.hideGraphic = true
      }
    },
    setupData () {
      if (!this.visible || this.computedData.length <= 0) {
        this.tableColumns = []
        this.tableData = []
        return
      }

      this.getCategoriesCount = []
      // resultTableColumns and resultTableData - this is data for the table
      let resultTableColumns = []
      let resultTableData = []
      // run two methods to fullfill arrays with the data
      if (this.isSplit && this.isDoubleGrouped) { // when there is 1 question + splitby + double groupby
        resultTableColumns = this.buildSplitAndTwiceGroupedSeriesTableColumns(resultTableColumns, this.computedData)
        resultTableData = this.buildSplitAndTwiceGroupedSeriesTableData(resultTableData, this.computedData)
      } else if (this.isDoubleSplit && this.isGrouped) { // when there is 1 question + double splitby + groupby
        resultTableColumns = this.buildDoubleSplitAndGroupedSeriesTableColumns(resultTableColumns, this.computedData)
        resultTableData = this.buildDobleSplitAndGroupedSeriesTableData(resultTableData, this.computedData)
      } else if (this.isDoubleSplit) { // when there is 1 question and it's twice splitby
        resultTableColumns = this.buildDoubleSplitSeriesTableColumns(resultTableColumns, this.computedData)
        resultTableData = this.buildDoubleSplitSeriesTableData(resultTableData, this.computedData)
      } else if (this.isSplit && this.isGrouped) {
        resultTableColumns = this.buildSplitAndGroupedSeriesTableColumns(resultTableColumns, this.computedData)
        resultTableData = this.buildSplitAndGroupedSeriesTableData(resultTableData, this.computedData)
      } else if (this.isSplit) { // when there is 1 question and it's splitby
        resultTableColumns = this.buildSplitSeriesTableColumns(resultTableColumns, this.computedData)
        resultTableData = this.buildSplitSeriesTableData(resultTableData, this.computedData)
      } else if (this.isGrouped || this.isDoubleGrouped) { // when there are 1 question and it's groupedBy or 1 question and groupedBy twice
        resultTableColumns = this.buildGroupedOrGroupedTwiceTableColumns(resultTableColumns, this.computedData)
        resultTableData = this.buildGroupedSeriesTableData(resultTableData, this.computedData)
      } else if (this.isHorizontal) { // run when it's horizontal and just one question is there
        resultTableColumns = this.buildSeriesTableColumnsHorizontal(resultTableColumns, this.computedData)
        resultTableData = this.buildSeriesTableDataHorizontal(resultTableData, this.computedData)
      } else { // when there is one question
        resultTableColumns = this.buildSeriesTableColumns(resultTableColumns, this.computedData)
        resultTableData = this.buildSeriesTableData(resultTableData, this.computedData)
      }

      this.hideGraphic = false
      this.checkLimitOfCategoriesInGraphic(resultTableColumns.length, resultTableData.length)
      this.currentCountOfCategories = resultTableColumns.length
      this.currentCountOfLabels = resultTableData.length

      this.tableColumns = resultTableColumns
      this.tableData = resultTableData
    },
    sortChange (params) {
      this.tableData.sort((a, b) => {
        if (params.percent) {
          if (params.percent === 'asc') {
          // delete last character from string that is: '%'
            const first = a.percent.substring(0, a.percent.length - 1)
            // delete last character from string that is: '%'
            const second = b.percent.substring(0, b.percent.length - 1)
            return first - second
          } else if (params.percent === 'desc') {
          // delete last character from string that is: '%'
            const first = a.percent.substring(0, a.percent.length - 1)
            // delete last character from string that is: '%'
            const second = b.percent.substring(0, b.percent.length - 1)
            return second - first
          } else {
            return 0
          }
        } else if (params.number) {
          if (params.number === 'asc') {
            return a.number - b.number
          } else if (params.number === 'desc') {
            return b.number - a.number
          } else {
            return 0
          }
        }
      })
    },
    calculateMean (array, totalAnswers) {
      const sum = array.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      return (sum / totalAnswers).toFixed(2)
    },
    calculateMedian (array) {
      const sortedArray = array.slice().sort((a, b) => a - b)
      const middle = Math.floor(sortedArray.length / 2)

      if (sortedArray.length % 2 === 0) {
        return (sortedArray[middle - 1] + sortedArray[middle]) / 2
      } else {
        return sortedArray[middle]
      }
    },
    calculateSum (array) {
      return array.reduce((acc, currValue) => acc + currValue, 0)
    },
    calculateUnique (array) {
      return [...new Set(array)].length
    },
    buildSeriesTableColumns (resultTableColumns) {
      const arrTableColumns = []
      let indexCount = 0

      // push question-title to the first cell of the column and push empty stirng to the second cell of the column
      arrTableColumns.push({ field: 'question', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart, 'title', this.language), align: 'center', width: 200 })
      arrTableColumns.push({ field: 'row_name', key: this.alphabetByNumber(indexCount++), title: '', align: 'center', width: 140 })
      if (this.getAdditionFunc !== 'Mean' && this.getAdditionFunc !== 'Median' && this.getAdditionFunc !== 'Sum' && this.getAdditionFunc !== 'Unique') {
        // push names to the cells in columns from getSelectedSeries if it's count or percentage
        this.getSelectedSeries[0].data.forEach((title, count) => {
          arrTableColumns.push({ field: count, key: this.alphabetByNumber(indexCount++), title: this.translate(title, 'label', this.language), align: 'center', width: 100 })
        })
      } else {
        // if this is mean median sum unqie, create an array with all values
        this.tableColumnsVariableValues = []
        this.getSelectedSeries[0].data.forEach((title) => {
          this.tableColumnsVariableValues.push(this.translate(title, 'label', this.language))
        })
      }
      // push in the last cell in column string-total
      arrTableColumns.push({ field: 'totalAnswers', key: this.alphabetByNumber(indexCount++), title: 'Total', align: 'center', width: 100 })
      resultTableColumns = arrTableColumns
      // this is recurtion, when columns are fullfilled with data => return it back\
      return resultTableColumns
    },
    buildSeriesTableColumnsHorizontal (resultTableColumns) {
      const arrTableColumns = []
      let indexCount = 0

      arrTableColumns.push({ field: 'question', key: this.alphabetByNumber(indexCount++), title: this.translate(this.getSelectedSeries[0], 'label', this.language), align: 'left', width: 200 })
      arrTableColumns.push({ field: 'percent', key: this.alphabetByNumber(indexCount++), title: 'Prozent', align: 'center', sortBy: '', width: 140 })
      arrTableColumns.push({ field: 'number', key: this.alphabetByNumber(indexCount++), title: 'Anzahl', align: 'center', sortBy: '', width: 140 })

      resultTableColumns = arrTableColumns

      return resultTableColumns
    },
    buildSplitSeriesTableColumns (resultTableColumns) {
      const arrTableColumns = []
      let indexCount = 0

      // push question-title to the first cell of the column, push to second cell of the column splitby-title
      // and push empty stirng to the third cell of the column
      // arrTableColumns.push({ field: 'question', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart, 'title', this.language), align: 'center', width: 200 })
      arrTableColumns.push({ field: 'split_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.splits[0], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'row_name', key: this.alphabetByNumber(indexCount++), title: '', align: 'center', width: 100 })

      if (this.getAdditionFunc !== 'Mean' && this.getAdditionFunc !== 'Median' && this.getAdditionFunc !== 'Sum' && this.getAdditionFunc !== 'Unique') {
      // push names to the cells in columns from getSelectedSeries
        this.getSelectedSeries[0].data.forEach((title, count) => {
          arrTableColumns.push({ field: count, key: this.alphabetByNumber(indexCount++), title: this.translate(title, 'label', this.language), align: 'center', width: 100 })
        })
      } else {
        // if this is mean median sum unqie, create an array with all values
        this.tableColumnsVariableValues = []
        this.getSelectedSeries[0].data.forEach((title) => {
          this.tableColumnsVariableValues.push(this.translate(title, 'label', this.language))
        })
      }

      // push in the last cell in column string-total
      arrTableColumns.push({ field: 'totalAnswers', key: this.alphabetByNumber(indexCount++), title: 'Total', align: 'center', width: 100 })

      resultTableColumns = arrTableColumns

      // this is recurtion, when columns are fullfilled with data => return it back
      return resultTableColumns
    },
    // currently we are not using if the value is double grouped
    buildGroupedOrGroupedTwiceTableColumns (resultTableColumns) {
      const arrTableColumns = []
      let indexCount = 0

      arrTableColumns.push({ field: 'question', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart, 'title', this.language), align: 'center', width: 200 })
      arrTableColumns.push({ field: 'grouped_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.groups[0], 'label', this.language), align: 'center', width: 140 })
      if (this.isDoubleGrouped) {
        arrTableColumns.push({ field: 'grouped_by_twice', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.groups[1], 'label', this.language), align: 'center', width: 140 })
      }
      arrTableColumns.push({ field: 'row_name', key: this.alphabetByNumber(indexCount++), title: '', align: 'center', width: 100 })

      // change it for computeddata insted using this.getSelectedSeries
      if (this.getAdditionFunc !== 'Mean' && this.getAdditionFunc !== 'Median' && this.getAdditionFunc !== 'Sum' && this.getAdditionFunc !== 'Unique') {
      // push names to the cells in columns from getSelectedSeries
        this.getSelectedSeries[0].data.forEach((title, count) => {
          arrTableColumns.push({ field: count, key: this.alphabetByNumber(indexCount++), title: this.translate(title, 'label', this.language), align: 'center', width: 100 })
        })
      } else {
        // if this is mean median sum unqie, create an array with all values
        this.tableColumnsVariableValues = []
        this.getSelectedSeries[0].data.forEach((title) => {
          this.tableColumnsVariableValues.push(this.translate(title, 'label', this.language))
        })
      }

      arrTableColumns.push({ field: 'totalAnswers', key: this.alphabetByNumber(indexCount++), title: 'Total', align: 'center', width: 100 })

      resultTableColumns = arrTableColumns
      return resultTableColumns
    },
    buildSplitAndGroupedSeriesTableColumns (resultTableColumns) {
      const arrTableColumns = []
      let indexCount = 0

      // push question-title to the first cell of the column, push to second cell of the column splitby-title
      // push to third cell of the column grouped_by-title and push empty string to the forth cell of the column
      // arrTableColumns.push({ field: 'question', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart, 'title', this.language), align: 'center', width: 200 })
      arrTableColumns.push({ field: 'split_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.splits[0], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'grouped_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.groups[0], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'row_name', key: this.alphabetByNumber(indexCount++), title: '', align: 'center', width: 140 })

      // push names to the cells in columns from getSelectedSeries
      if (this.getAdditionFunc !== 'Mean' && this.getAdditionFunc !== 'Median' && this.getAdditionFunc !== 'Sum' && this.getAdditionFunc !== 'Unique') {
      // push names to the cells in columns from getSelectedSeries
        this.getSelectedSeries[0].data.forEach((title, count) => {
          arrTableColumns.push({ field: count, key: this.alphabetByNumber(indexCount++), title: this.translate(title, 'label', this.language), align: 'center', width: 100 })
        })
      } else {
        // if this is mean median sum unqie, create an array with all values
        this.tableColumnsVariableValues = []
        this.getSelectedSeries[0].data.forEach((title) => {
          this.tableColumnsVariableValues.push(this.translate(title, 'label', this.language))
        })
      }

      // push in the last cell in column string-total
      arrTableColumns.push({ field: 'totalAnswers', key: this.alphabetByNumber(indexCount++), title: 'Total', align: 'center', width: 100 })

      resultTableColumns = arrTableColumns

      // this is recurtion, when columns are fullfilled with data => return it back
      return resultTableColumns
    },
    buildDoubleSplitSeriesTableColumns (resultTableColumns) {
      const arrTableColumns = []
      let indexCount = 0
      // push question-title to the first cell of the column, push to second cell of the column splitby-title
      // push to third cell of the column grouped_by-title and push empty string to the forth cell of the column
      // arrTableColumns.push({ field: 'question', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart, 'title', this.language), align: 'center', width: 200 })
      arrTableColumns.push({ field: 'split_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.splits[0], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'split_by_twice', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.splits[1], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'row_name', key: this.alphabetByNumber(indexCount++), title: '', align: 'center', width: 140 })

      // push names to the cells in columns from getSelectedSeries
      if (this.getAdditionFunc !== 'Mean' && this.getAdditionFunc !== 'Median' && this.getAdditionFunc !== 'Sum' && this.getAdditionFunc !== 'Unique') {
      // push names to the cells in columns from getSelectedSeries
        this.getSelectedSeries[0].data.forEach((title, count) => {
          arrTableColumns.push({ field: count, key: this.alphabetByNumber(indexCount++), title: this.translate(title, 'label', this.language), align: 'center', width: 100 })
        })
      } else {
        // if this is mean median sum unqie, create an array with all values
        this.tableColumnsVariableValues = []
        this.getSelectedSeries[0].data.forEach((title) => {
          this.tableColumnsVariableValues.push(this.translate(title, 'label', this.language))
        })
      }

      // push in the last cell in column string-total
      arrTableColumns.push({ field: 'totalAnswers', key: this.alphabetByNumber(indexCount++), title: 'Total', align: 'center', width: 100 })

      resultTableColumns = arrTableColumns

      // this is recurtion, when columns are fullfilled with data => return it back
      return resultTableColumns
    },
    buildSplitAndTwiceGroupedSeriesTableColumns (resultTableColumns) {
      const arrTableColumns = []
      let indexCount = 0

      // push question-title to the first cell of the column, push to second cell of the column splitby-title
      // push to third cell of the column grouped_by-title and push empty string to the forth cell of the column
      // arrTableColumns.push({ field: 'question', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart, 'title', this.language), align: 'center', width: 200 })
      arrTableColumns.push({ field: 'split_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.splits[0], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'grouped_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.groups[0], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'grouped_by_twice', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.groups[1], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'row_name', key: this.alphabetByNumber(indexCount++), title: '', align: 'center', width: 140 })

      // push names to the cells in columns from getSelectedSeries
      if (this.getAdditionFunc !== 'Mean' && this.getAdditionFunc !== 'Median' && this.getAdditionFunc !== 'Sum' && this.getAdditionFunc !== 'Unique') {
      // push names to the cells in columns from getSelectedSeries
        this.getSelectedSeries[0].data.forEach((title, count) => {
          arrTableColumns.push({ field: count, key: this.alphabetByNumber(indexCount++), title: this.translate(title, 'label', this.language), align: 'center', width: 100 })
        })
      } else {
        // if this is mean median sum unqie, create an array with all values
        this.tableColumnsVariableValues = []
        this.getSelectedSeries[0].data.forEach((title) => {
          this.tableColumnsVariableValues.push(this.translate(title, 'label', this.language))
        })
      }

      // push in the last cell in column string-total
      arrTableColumns.push({ field: 'totalAnswers', key: this.alphabetByNumber(indexCount++), title: 'Total', align: 'center', width: 100 })

      resultTableColumns = arrTableColumns

      // this is recurtion, when columns are fullfilled with data => return it back
      return resultTableColumns
    },
    buildDoubleSplitAndGroupedSeriesTableColumns (resultTableColumns) {
      const arrTableColumns = []
      let indexCount = 0

      // push question-title to the first cell of the column, push to second cell of the column splitby-title
      // push to third cell of the column grouped_by-title and push empty string to the forth cell of the column
      // arrTableColumns.push({ field: 'question', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart, 'title', this.language), align: 'center', width: 200 })
      arrTableColumns.push({ field: 'split_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.splits[0], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'split_by_twice', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.splits[1], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'grouped_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.groups[0], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'row_name', key: this.alphabetByNumber(indexCount++), title: '', align: 'center', width: 140 })

      // push names to the cells in columns from getSelectedSeries
      if (this.getAdditionFunc !== 'Mean' && this.getAdditionFunc !== 'Median' && this.getAdditionFunc !== 'Sum' && this.getAdditionFunc !== 'Unique') {
      // push names to the cells in columns from getSelectedSeries
        this.getSelectedSeries[0].data.forEach((title, count) => {
          arrTableColumns.push({ field: count, key: this.alphabetByNumber(indexCount++), title: this.translate(title, 'label', this.language), align: 'center', width: 100 })
        })
      } else {
        // if this is mean median sum unqie, create an array with all values
        this.tableColumnsVariableValues = []
        this.getSelectedSeries[0].data.forEach((title) => {
          this.tableColumnsVariableValues.push(this.translate(title, 'label', this.language))
        })
      }

      // push in the last cell in column string-total
      arrTableColumns.push({ field: 'totalAnswers', key: this.alphabetByNumber(indexCount++), title: 'Total', align: 'center', width: 100 })

      resultTableColumns = arrTableColumns

      // this is recurtion, when columns are fullfilled with data => return it back
      return resultTableColumns
    },
    buildSeriesTableData (resultTableData, computedData) {
      let calculatedResults = []

      // run loop from selectedSeries
      this.getSelectedSeries.forEach((serie) => {
        if (serie.data) {
        // on each iteration run calculateResults function
          calculatedResults = this.calculateResults(calculatedResults, serie, computedData)
        }
      })

      resultTableData = calculatedResults
      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    buildSeriesTableDataHorizontal (resultTableData, computedData) {
      let calculatedResults = []

      this.getSelectedSeries.forEach((serie) => {
        if (serie.data) {
        // on each iteration run calculateResults function
          calculatedResults = this.calculateResultsHorizontal(calculatedResults, serie, computedData)
        }
      })

      resultTableData = calculatedResults
      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    buildSplitSeriesTableData (resultTableData, computedData) {
      let calculatedResults = []

      // run loop on splitSeries for example when it's splitby geschlect or age
      this.splitSeries.forEach((splitValue) => {
      // run loop on selectedSeries for example when user choose any question
        this.getSelectedSeries.forEach((serie) => {
          if (serie.data) {
          // in function calculateGroupedResults will sort all values and put it in right cells in table.
          // Also this function will run a lot times because there are few layout looping
            calculatedResults = this.calculateSplitResults(calculatedResults, serie, computedData, splitValue)
          }
        })
      })
      resultTableData = calculatedResults

      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    // currently we are not using if value is double grouped
    buildGroupedSeriesTableData (resultTableData) {
      const groupsData = this.chart.groups
      let calculatedFinalResults = []

      // loop throw groups for example when two qustions are groupedby-language
      function makeDataArrWithAllCombinationsInGroups (groupsArr, index = 0, currentCombination = [], allCombinations = []) {
        if (index === groupsArr.length) {
          allCombinations.push([...currentCombination])
        }

        if (groupsArr[index]?.data !== undefined && groupsArr[index]?.data !== null) {
          for (const item of groupsArr[index].data) {
            currentCombination.push(item)
            makeDataArrWithAllCombinationsInGroups(groupsArr, index + 1, currentCombination, allCombinations)
            currentCombination.pop() // Backtrack to try the next combination
          }
        }
        return allCombinations
      }

      const allGroupsCombinations = makeDataArrWithAllCombinationsInGroups(groupsData)

      allGroupsCombinations.forEach((groupCombinationData) => {
        const filteredArr = []
        const currentGroupCombinationLenght = groupCombinationData.length
        const groupsKeys = []

        for (let indexOfGroup = 0; indexOfGroup < currentGroupCombinationLenght; indexOfGroup++) {
          groupsKeys.push(groupCombinationData[indexOfGroup].key)
          // this function implemented Manuel Roth for filtering. It's filtered all data based on values from group. This function should be running every time when user choose groupby
          if (groupCombinationData[indexOfGroup]?.customFunc) {
            filteredArr.push(this.computedData.filter(d => groupCombinationData[indexOfGroup].customFunc(d.attributes[groupCombinationData[indexOfGroup].key])))
          } else {
            filteredArr.push(this.computedData.filter(d => groupCombinationData[indexOfGroup].value && d.attributes[groupCombinationData[indexOfGroup].key] && d.attributes[groupCombinationData[indexOfGroup].key].toString() === groupCombinationData[indexOfGroup].value.toString()))
          }
        }

        // this.splitSeries.forEach((splitValue, splitCount) => {
        // run loop on selectedSeries for example when user choose any question
        this.getSelectedSeries.forEach((serie) => {
          if (serie.data && filteredArr[0].length > 0) {
            // in function calculateSplitAndGroupedResults will sort all values and put it in right cells in table.
            // Also this function will run a lot times because there are few layout looping
            calculatedFinalResults = this.calculateGroupedResults(calculatedFinalResults, serie, filteredArr, groupCombinationData, groupsKeys)
          }
        })
        // })
      })

      resultTableData = calculatedFinalResults
      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    buildSplitAndGroupedSeriesTableData (resultTableData) {
      let calculatedFinalResults = []

      // loop throw groups for example when two qustions are groupedby-language
      this.chart.groups.forEach(group => {
        group.data.forEach((valueGroup) => {
          var filtered = []

          // this function implemented Manuel Roth for filtering. It's filtered all data based on values from group. This function should be running every time when user choose groupby
          if (valueGroup.customFunc) {
            filtered = this.computedData.filter(d => valueGroup.customFunc(d.attributes[group.key]))
          } else {
            filtered = this.computedData.filter(d => valueGroup.value && d.attributes[group.key] && d.attributes[group.key].toString() === valueGroup.value.toString())
          }

          // run loop on splitSeries for example when it's splitby geschlect or age
          this.splitSeries.forEach((splitValue) => {
          // run loop on selectedSeries for example when user choose any question
            this.getSelectedSeries.forEach((serie) => {
              if (serie.data && filtered.length > 0) {
              // in function calculateSplitAndGroupedResults will sort all values and put it in right cells in table.
              // Also this function will run a lot times because there are few layout looping
                calculatedFinalResults = this.calculateSplitAndGroupedResults(calculatedFinalResults, serie, splitValue, filtered, valueGroup)
              }
            })
          })
        })
      })

      resultTableData = calculatedFinalResults

      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    buildDoubleSplitSeriesTableData (resultTableData) {
      let calculatedResults = []

      this.splitSeriesDouble.forEach((splitSecondValue) => {
        var filtered = []

        // this function implemented Manuel Roth for filtering. It's filtered all data based on values from group. This function should be running every time when user choose groupby
        if (splitSecondValue.customFunc) {
          filtered = this.computedData.filter(d => splitSecondValue.customFunc(d.attributes[splitSecondValue.key]))
        } else {
          filtered = this.computedData.filter(d => splitSecondValue.value && d.attributes[splitSecondValue.key] && d.attributes[splitSecondValue.key].toString() === splitSecondValue.value.toString())
        }
        // run loop on splitSeries for example when it's splitby geschlect or age
        this.splitSeries.forEach((splitValue) => {
          // run loop on selectedSeries for example when user choose any question
          this.getSelectedSeries.forEach((serie) => {
            if (serie.data) {
            // in function calculateGroupedResults will sort all values and put it in right cells in table.
            // Also this function will run a lot times because there are few layout looping
              calculatedResults = this.calculateDoubleSplitResults(calculatedResults, serie, splitValue, filtered, splitSecondValue)
            }
          })
        })
      })
      resultTableData = calculatedResults

      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    buildSplitAndTwiceGroupedSeriesTableData (resultTableData) {
      const groupsData = this.chart.groups
      let calculatedFinalResults = []

      // loop throw groups for example when two qustions are groupedby-language
      function makeDataArrWithAllCombinationsInGroups (groupsArr, index = 0, currentCombination = [], allCombinations = []) {
        if (index === groupsArr.length) {
          allCombinations.push([...currentCombination])
        }

        if (groupsArr[index]?.data !== undefined && groupsArr[index]?.data !== null) {
          for (const item of groupsArr[index].data) {
            currentCombination.push(item)
            makeDataArrWithAllCombinationsInGroups(groupsArr, index + 1, currentCombination, allCombinations)
            currentCombination.pop() // Backtrack to try the next combination
          }
        }
        return allCombinations
      }

      const allGroupsCombinations = makeDataArrWithAllCombinationsInGroups(groupsData)

      allGroupsCombinations.forEach((groupCombinationData) => {
        const filteredArr = []
        const currentGroupCombinationLenght = groupCombinationData.length
        const groupsKeys = []

        for (let indexOfGroup = 0; indexOfGroup < currentGroupCombinationLenght; indexOfGroup++) {
          groupsKeys.push(groupCombinationData[indexOfGroup].key)
          // this function implemented Manuel Roth for filtering. It's filtered all data based on values from group. This function should be running every time when user choose groupby
          if (groupCombinationData[indexOfGroup]?.customFunc) {
            filteredArr.push(this.computedData.filter(d => groupCombinationData[indexOfGroup].customFunc(d.attributes[groupCombinationData[indexOfGroup].key])))
          } else {
            filteredArr.push(this.computedData.filter(d => groupCombinationData[indexOfGroup].value && d.attributes[groupCombinationData[indexOfGroup].key] && d.attributes[groupCombinationData[indexOfGroup].key].toString() === groupCombinationData[indexOfGroup].value.toString()))
          }
        }

        this.splitSeries.forEach((splitValue) => {
          // run loop on selectedSeries for example when user choose any question
          this.getSelectedSeries.forEach((serie) => {
            if (serie.data && filteredArr[0].length > 0 && filteredArr[1].length > 0) {
              // in function calculateSplitAndGroupedResults will sort all values and put it in right cells in table.
              // Also this function will run a lot times because there are few layout looping
              calculatedFinalResults = this.calculateSplitAndTwiceGroupedResults(calculatedFinalResults, serie, splitValue, filteredArr, groupCombinationData, groupsKeys)
            }
          })
        })
      })

      resultTableData = calculatedFinalResults
      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    buildDobleSplitAndGroupedSeriesTableData (resultTableData) {
      const secondSplitDataAndGroupData = [this.chart?.splits[1], this.chart?.groups[0]] || []
      let calculatedFinalResults = []

      // loop throw groups for example when two qustions are groupedby-language
      function makeDataArrWithAllCombinationsInGroups (secondSplitDataAndGroupData, index = 0, currentCombination = [], allCombinations = []) {
        if (index === secondSplitDataAndGroupData.length) {
          allCombinations.push([...currentCombination])
        }

        if (secondSplitDataAndGroupData[index]?.data !== undefined && secondSplitDataAndGroupData[index]?.data !== null) {
          for (const item of secondSplitDataAndGroupData[index].data) {
            currentCombination.push(item)
            makeDataArrWithAllCombinationsInGroups(secondSplitDataAndGroupData, index + 1, currentCombination, allCombinations)
            currentCombination.pop() // Backtrack to try the next combination
          }
        }

        return allCombinations
      }

      const allSplitsCombinations = makeDataArrWithAllCombinationsInGroups(secondSplitDataAndGroupData)

      allSplitsCombinations.forEach((splitAndGroupCombinationData) => {
        const filteredArr = []
        const currentSplitAndGroupCombinationLenght = splitAndGroupCombinationData.length
        const splitAndGroupKeys = []

        for (let indexOfGroup = 0; indexOfGroup < currentSplitAndGroupCombinationLenght; indexOfGroup++) {
          splitAndGroupKeys.push(splitAndGroupCombinationData[indexOfGroup].key)
          // this function implemented Manuel Roth for filtering. It's filtered all data based on values from group. This function should be running every time when user choose groupby
          if (splitAndGroupCombinationData[indexOfGroup]?.customFunc) {
            filteredArr.push(this.computedData.filter(d => splitAndGroupCombinationData[indexOfGroup].customFunc(d.attributes[splitAndGroupCombinationData[indexOfGroup].key])))
          } else {
            filteredArr.push(this.computedData.filter(d => splitAndGroupCombinationData[indexOfGroup].value && d.attributes[splitAndGroupCombinationData[indexOfGroup].key] && d.attributes[splitAndGroupCombinationData[indexOfGroup].key].toString() === splitAndGroupCombinationData[indexOfGroup].value.toString()))
          }
        }

        this.splitSeries.forEach((splitValue) => {
          // run loop on selectedSeries for example when user choose any question
          this.getSelectedSeries.forEach((serie) => {
            if (serie.data && filteredArr[0].length > 0 && filteredArr[1].length > 0) {
              // in function calculateSplitAndGroupedResults will sort all values and put it in right cells in table.
              // Also this function will run a lot times because there are few layout looping
              calculatedFinalResults = this.calculateDoubleSplitAndGroupedResults(calculatedFinalResults, serie, splitValue, filteredArr, splitAndGroupCombinationData, splitAndGroupKeys)
            }
          })
        })
      })

      resultTableData = calculatedFinalResults
      // // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    calculateResults (result, serie, data) {
      const resultObjCalulatedData = {}
      let calculatedResultsPercentagesOrMeanMedianSumUnique = {}

      // put question to cell to first row in Table
      calculatedResultsPercentagesOrMeanMedianSumUnique.question = this.translate(serie, 'label', this.language)
      // put string-Prozent to first row in Table
      if (this.getAdditionFunc === 'Count' || this.getAdditionFunc === 'Percentage') {
        calculatedResultsPercentagesOrMeanMedianSumUnique.row_name = 'Prozent'
      } else {
        calculatedResultsPercentagesOrMeanMedianSumUnique.row_name = this.getAdditionFunc
      }
      // put string-Anzahl to second row in Table
      resultObjCalulatedData.row_name = 'Anzahl'
      // put all calculated values in the second row in Table
      serie.data.forEach((v, ix) => {
        resultObjCalulatedData[ix] = resultObjCalulatedData[ix] || 0
        if (v.customFunc) {
          resultObjCalulatedData[ix] += data.filter(value => v.measure.id === value.measure && v.customFunc(value.attributes[v.key])).length
        } else {
          resultObjCalulatedData[ix] += data.filter(value => v.measure.id === value.measure && value.attributes[v.key] != null && String(value.attributes[v.key]) === String(v.value)).length
        }
      })

      // run calculateResultsPercentages function and put all calculated Percentages in first row in Table
      calculatedResultsPercentagesOrMeanMedianSumUnique = this.calculateResultsPercentagesOrSpecialFunc(calculatedResultsPercentagesOrMeanMedianSumUnique, resultObjCalulatedData)
      // fulfill result array with two new objects
      result.push(calculatedResultsPercentagesOrMeanMedianSumUnique)
      result.push(resultObjCalulatedData)

      return result
    },
    calculateResultsHorizontal (result, serie, data) {
      let calculatedResultsPercentages = {}

      const allCalculatedValues = {}

      // calculate percents and put them in one object
      serie.data.forEach((v, ix) => {
        allCalculatedValues[ix] = allCalculatedValues[ix] || 0
        if (v.customFunc) {
          allCalculatedValues[ix] += data.filter(value => v.measure.id === value.measure && v.customFunc(value.attributes[v.key])).length
        } else {
          allCalculatedValues[ix] += data.filter(value => v.measure.id === value.measure && value.attributes[v.key] != null && String(value.attributes[v.key]) === String(v.value)).length
        }
      })

      calculatedResultsPercentages = this.calculateResultsPercentagesHorizontal(calculatedResultsPercentages, allCalculatedValues)

      serie.data.forEach((v, ix) => {
        const resultObjCalulatedData = {}

        resultObjCalulatedData.number = resultObjCalulatedData[ix] || 0
        if (v.customFunc) {
          resultObjCalulatedData.number += data.filter(value => v.measure.id === value.measure && v.customFunc(value.attributes[v.key])).length
        } else {
          resultObjCalulatedData.number += data.filter(value => v.measure.id === value.measure && value.attributes[v.key] != null && String(value.attributes[v.key]) === String(v.value)).length
        }

        resultObjCalulatedData.question = v.value || v.label || 0
        // put percent in object that I'm looping now
        resultObjCalulatedData.percent = calculatedResultsPercentages[ix]

        result.push(resultObjCalulatedData)
      })

      return result
    },
    calculateSplitResults (result, serie, data, splitValue) {
      const resultObjCalulatedData = {}
      let calculatedResultsPercentages = {}

      // put question to cell to first row in Table
      // calculatedResultsPercentages.question = this.translate(serie, 'label', this.language)
      // put splitby value to cell to first row in Table
      calculatedResultsPercentages.split_by = this.translate(splitValue, 'label', this.language)
      // put string-Prozent to first row in Table
      if (this.getAdditionFunc === 'Count' || this.getAdditionFunc === 'Percentage') {
        calculatedResultsPercentages.row_name = 'Prozent'
      } else {
        calculatedResultsPercentages.row_name = this.getAdditionFunc
      }
      // put string-Anzahl to second row in Table
      resultObjCalulatedData.row_name = 'Anzahl'

      // put all calculated values in the second row in Table
      serie.data.forEach((v, ix) => {
        resultObjCalulatedData[ix] = result[ix] || 0
        var value = 0
        // function that Manuel implemented and it sorted correctly all splitby values
        value = data.filter(value => {
          var resultObjCalulatedData = false
          if (v.customFunc) {
            resultObjCalulatedData = v.customFunc(v.measure.id === value.measure && value.attributes[v.key])
          } else {
            resultObjCalulatedData = String(v.measure.id === value.measure && value.attributes[v.key]) === String(v.value)
          }

          if (splitValue.customFunc) {
            resultObjCalulatedData = splitValue.customFunc(value.attributes[splitValue.key]) && resultObjCalulatedData
          } else {
            resultObjCalulatedData = value.attributes[splitValue.key] != null && String(value.attributes[splitValue.key]) === String(splitValue.value) && resultObjCalulatedData
          }

          return resultObjCalulatedData
        }).length
        resultObjCalulatedData[ix] = value
      })

      // run calculateSplitResultsPercentages function and put all calculated Percentages in first row in Table
      calculatedResultsPercentages = this.calculateSplitResultsPercentagesOrSpecialFunc(calculatedResultsPercentages, resultObjCalulatedData)

      // fulfill result array with two new objects
      result.push(calculatedResultsPercentages)
      result.push(resultObjCalulatedData)

      return result
    },
    calculateDoubleSplitResults (result, serie, splitValue, filtered, splitSecondValue) {
      const resultObjCalulatedData = {}
      let calculatedResultsAll = {}

      // put question to cell to first row in Table
      // calculatedResultsAll.question = this.translate(serie, 'label', this.language)
      // put splitby value to cell to first row in Table
      calculatedResultsAll.split_by = this.translate(splitValue, 'label', this.language)
      // put groupby value to cell to first row in Table
      calculatedResultsAll.split_by_twice = this.translate(splitSecondValue, 'label', this.language)
      // put strings: Prozent and An to first row in Table
      // \n\n => I use it to put two words in one string but it will be in newline(line break)
      if (this.getAdditionFunc === 'Count' || this.getAdditionFunc === 'Percentage') {
        calculatedResultsAll.row_name = 'Prozent\n\nAnzahl'
      } else {
        calculatedResultsAll.row_name = `${this.getAdditionFunc}\n\nAnzahl`
      }

      // put all calculated values in the FIRST row in Table
      serie.data.forEach((v, ix) => {
        resultObjCalulatedData[ix] = result[ix] || 0
        var value = 0
        value = filtered.filter(value => {
          var resultObjCalulatedData = false
          // function that Manuel implemented and it sorted correctly all splitby values and grupby values
          if (v.customFunc) {
            resultObjCalulatedData = v.customFunc(v.measure.id === value.measure && value.attributes[v.key])
          } else {
            resultObjCalulatedData = String(v.measure.id === value.measure && value.attributes[v.key]) === String(v.value)
          }

          if (splitValue.customFunc) {
            resultObjCalulatedData = splitValue.customFunc(value.attributes[splitValue.key]) && resultObjCalulatedData
          } else {
            resultObjCalulatedData = value.attributes[splitValue.key] != null && String(value.attributes[splitValue.key]) === String(splitValue.value) && resultObjCalulatedData
          }

          return resultObjCalulatedData
        }).length
        resultObjCalulatedData[ix] = value
      })

      // run calculateSplitAndGroupedResultsPercentages function and put all calculated Percentages and all values in one row in Table
      calculatedResultsAll = this.calculateSplitAndGroupedResultsPercentagesOrSpecialFunc(calculatedResultsAll, resultObjCalulatedData)

      // here is only onw raw per iteration
      result.push(calculatedResultsAll)

      return result
    },
    calculateSplitAndGroupedResults (result, serie, splitValue, filtered, valueGroup) {
      const resultObjCalulatedData = {}
      let calculatedResultsAll = {}

      // put question to cell to first row in Table
      // calculatedResultsAll.question = this.translate(serie, 'label', this.language)
      // put splitby value to cell to first row in Table
      calculatedResultsAll.split_by = this.translate(splitValue, 'label', this.language)
      // put groupby value to cell to first row in Table
      calculatedResultsAll.grouped_by = this.translate(valueGroup, 'label', this.language)
      // put strings: Prozent and An to first row in Table
      // \n\n => I use it to put two words in one string but it will be in newline(line break)
      if (this.getAdditionFunc === 'Count' || this.getAdditionFunc === 'Percentage') {
        calculatedResultsAll.row_name = 'Prozent\n\nAnzahl'
      } else {
        calculatedResultsAll.row_name = `${this.getAdditionFunc}\n\nAnzahl`
      }
      // put all calculated values in the FIRST row in Table
      serie.data.forEach((v, ix) => {
        resultObjCalulatedData[ix] = result[ix] || 0
        var value = 0
        value = filtered.filter(value => {
          var resultObjCalulatedData = false
          // function that Manuel implemented and it sorted correctly all splitby values and grupby values
          if (v.customFunc) {
            resultObjCalulatedData = v.customFunc(v.measure.id === value.measure && value.attributes[v.key])
          } else {
            resultObjCalulatedData = String(v.measure.id === value.measure && value.attributes[v.key]) === String(v.value)
          }

          if (splitValue.customFunc) {
            resultObjCalulatedData = splitValue.customFunc(value.attributes[splitValue.key]) && resultObjCalulatedData
          } else {
            resultObjCalulatedData = value.attributes[splitValue.key] != null && String(value.attributes[splitValue.key]) === String(splitValue.value) && resultObjCalulatedData
          }

          return resultObjCalulatedData
        }).length
        resultObjCalulatedData[ix] = value
      })

      // run calculateSplitAndGroupedResultsPercentages function and put all calculated Percentages and all values in one row in Table
      calculatedResultsAll = this.calculateSplitAndGroupedResultsPercentagesOrSpecialFunc(calculatedResultsAll, resultObjCalulatedData)

      // here is only onw raw per iteration
      result.push(calculatedResultsAll)

      return result
    },
    calculateSplitAndTwiceGroupedResults (result, serie, splitValue, filteredArr, groupCombinationData, groupsKeys) {
      const resultObjCalulatedData = {}
      let calculatedResultsAll = {}
      // put question to cell to first row in Table
      // calculatedResultsAll.question = this.translate(serie, 'label', this.language)
      calculatedResultsAll.question = ''
      // put splitby value to cell to first row in Table
      calculatedResultsAll.split_by = this.translate(splitValue, 'label', this.language)
      // put groupby value to cell to first row in Table
      calculatedResultsAll.grouped_by = this.translate(groupCombinationData[0], 'label', this.language)
      // put seconde groupby value to cell to first row in Table
      calculatedResultsAll.grouped_by_twice = this.translate(groupCombinationData[1], 'label', this.language)
      // put strings: Prozent and An to first row in Table
      // \n\n => I use it to put two words in one string but it will be in newline(line break)
      if (this.getAdditionFunc === 'Count' || this.getAdditionFunc === 'Percentage') {
        calculatedResultsAll.row_name = 'Prozent\n\nAnzahl'
      } else {
        calculatedResultsAll.row_name = `${this.getAdditionFunc}\n\nAnzahl`
      }

      const firstGroupValue = filteredArr[0][0].attributes[groupsKeys[0]]
      const secondGroupValue = filteredArr[1][0].attributes[groupsKeys[1]]

      function filterByKey (arr, filterKey, targetValue) {
        const filterArray = (arr, key, value) => {
          return arr.filter(obj => obj.attributes[key] === value)
        }
        const filteredArray = filterArray(arr, filterKey, targetValue)

        return [...filteredArray]
      }

      let finalArray = []

      if (filteredArr[0]?.length > filteredArr[1]?.length) {
        finalArray = filterByKey(filteredArr[1], groupsKeys[0], firstGroupValue)
      } else if (filteredArr[0]?.length <= filteredArr[1]?.length) {
        finalArray = filterByKey(filteredArr[0], groupsKeys[1], secondGroupValue)
      } else {
        finalArray = filterByKey(filteredArr[0], groupsKeys[1], secondGroupValue)
      }

      // put all calculated values in the FIRST row in Table
      serie.data.forEach((v, ix) => {
        resultObjCalulatedData[ix] = result[ix] || 0
        var value = 0
        value = finalArray?.filter(value => {
          var resultObjCalulatedData = false
          // function that Manuel implemented and it sorted correctly all splitby values and grupby values
          if (v.customFunc) {
            resultObjCalulatedData = v.customFunc(v.measure.id === value.measure && value.attributes[v.key])
          } else {
            resultObjCalulatedData = String(v.measure.id === value.measure && value.attributes[v.key]) === String(v.value)
          }

          if (splitValue.customFunc) {
            resultObjCalulatedData = splitValue.customFunc(value.attributes[splitValue.key]) && resultObjCalulatedData
          } else {
            resultObjCalulatedData = value.attributes[splitValue.key] != null && String(value.attributes[splitValue.key]) === String(splitValue.value) && resultObjCalulatedData
          }

          return resultObjCalulatedData
        }).length
        resultObjCalulatedData[ix] = value
      })

      // run calculateSplitAndGroupedResultsPercentages function and put all calculated Percentages and all values in one row in Table
      calculatedResultsAll = this.calculateSplitAndGroupedResultsPercentagesOrSpecialFunc(calculatedResultsAll, resultObjCalulatedData)

      // here is only onw raw per iteration
      result.push(calculatedResultsAll)

      return result
    },
    calculateDoubleSplitAndGroupedResults (result, serie, splitValue, filteredArr, splitAndGroupCombinationData) {
      const resultObjCalulatedData = {}
      let calculatedResultsAll = {}
      // put question to cell to first row in Table
      // calculatedResultsAll.question = this.translate(serie, 'label', this.language)
      calculatedResultsAll.question = ''
      // put splitby value to cell to first row in Table
      calculatedResultsAll.split_by = this.translate(splitValue, 'label', this.language)
      // put groupby value to cell to first row in Table
      calculatedResultsAll.split_by_twice = this.translate(splitAndGroupCombinationData[0], 'label', this.language)
      // put seconde groupby value to cell to first row in Table
      calculatedResultsAll.grouped_by = this.translate(splitAndGroupCombinationData[1], 'label', this.language)
      // put strings: Prozent and An to first row in Table
      // \n\n => I use it to put two words in one string but it will be in newline(line break)
      if (this.getAdditionFunc === 'Count' || this.getAdditionFunc === 'Percentage') {
        calculatedResultsAll.row_name = 'Prozent\n\nAnzahl'
      } else {
        calculatedResultsAll.row_name = `${this.getAdditionFunc}\n\nAnzahl`
      }

      const firstGroupValue = filteredArr[0][0].attributes[splitAndGroupCombinationData[0]]
      const secondGroupValue = filteredArr[1][0].attributes[splitAndGroupCombinationData[1]]

      function filterByKey (arr, filterKey, targetValue) {
        const filterArray = (arr, key, value) => {
          return arr.filter(obj => obj.attributes[key] === value)
        }
        const filteredArray = filterArray(arr, filterKey, targetValue)

        return [...filteredArray]
      }

      let finalArray = []

      if (filteredArr[0]?.length > filteredArr[1]?.length) {
        finalArray = filterByKey(filteredArr[1], splitAndGroupCombinationData[0], firstGroupValue)
      } else if (filteredArr[0]?.length <= filteredArr[1]?.length) {
        finalArray = filterByKey(filteredArr[0], splitAndGroupCombinationData[1], secondGroupValue)
      } else {
        finalArray = filterByKey(filteredArr[0], splitAndGroupCombinationData[1], secondGroupValue)
      }

      // put all calculated values in the FIRST row in Table
      serie.data.forEach((v, ix) => {
        resultObjCalulatedData[ix] = result[ix] || 0
        var value = 0
        value = finalArray?.filter(value => {
          var resultObjCalulatedData = false
          // function that Manuel implemented and it sorted correctly all splitby values and grupby values
          if (v.customFunc) {
            resultObjCalulatedData = v.customFunc(v.measure.id === value.measure && value.attributes[v.key])
          } else {
            resultObjCalulatedData = String(v.measure.id === value.measure && value.attributes[v.key]) === String(v.value)
          }

          if (splitValue.customFunc) {
            resultObjCalulatedData = splitValue.customFunc(value.attributes[splitValue.key]) && resultObjCalulatedData
          } else {
            resultObjCalulatedData = value.attributes[splitValue.key] != null && String(value.attributes[splitValue.key]) === String(splitValue.value) && resultObjCalulatedData
          }

          return resultObjCalulatedData
        }).length
        resultObjCalulatedData[ix] = value
      })

      // run calculateSplitAndGroupedResultsPercentages function and put all calculated Percentages and all values in one row in Table
      calculatedResultsAll = this.calculateSplitAndGroupedResultsPercentagesOrSpecialFunc(calculatedResultsAll, resultObjCalulatedData)

      // here is only onw raw per iteration
      result.push(calculatedResultsAll)

      return result
    },
    // currently we are not using if value is double grouped
    calculateGroupedResults (result, serie, filteredArr, groupCombinationData, groupsKeys) {
      const resultObjCalulatedData = {}
      let calculatedResultsAll = {} // new
      let finalArray = []

      // put question to cell to first row in Table
      calculatedResultsAll.question = this.translate(serie, 'label', this.language)
      // put groupby value to cell to first row in Table
      calculatedResultsAll.grouped_by = this.translate(groupCombinationData[0], 'label', this.language)
      // put strings: Prozent and An to first row in Table
      // put seconde groupby value to cell to first row in Table
      if (this.isDoubleGrouped) {
        calculatedResultsAll.grouped_by_twice = this.translate(groupCombinationData[1], 'label', this.language)
      }
      // \n\n => I use it to put two words in one string but it will be in newline(line break)
      if (this.getAdditionFunc === 'Count' || this.getAdditionFunc === 'Percentage') {
        calculatedResultsAll.row_name = 'Prozent\n\nAnzahl'
      } else {
        calculatedResultsAll.row_name = `${this.getAdditionFunc}\n\nAnzahl`
      }

      function filterByKey (arr, filterKey, targetValue) {
        const filterArray = (arr, key, value) => {
          return arr.filter(obj => obj.attributes[key] === value)
        }
        const filteredArray = filterArray(arr, filterKey, targetValue)

        return [...filteredArray]
      }

      if (this.isDoubleGrouped) {
        const firstGroupValue = filteredArr[0][0].attributes[groupsKeys[0]]
        const secondGroupValue = filteredArr[1][0].attributes[groupsKeys[1]]

        if (filteredArr[0]?.length > filteredArr[1]?.length) {
          finalArray = filterByKey(filteredArr[1], groupsKeys[0], firstGroupValue)
        } else if (filteredArr[0]?.length <= filteredArr[1]?.length) {
          finalArray = filterByKey(filteredArr[0], groupsKeys[1], secondGroupValue)
        } else {
          finalArray = filterByKey(filteredArr[0], groupsKeys[1], secondGroupValue)
        }
      } else {
        finalArray = filteredArr[0]
      }

      // put all calculated values in the FIRST row in Table
      serie.data.forEach((v, ix) => {
        resultObjCalulatedData[ix] = result[ix] || 0
        var value = 0

        value = finalArray?.filter(value => {
        // function that Manuel implemented and it sorted correctly all splitby values and groupby values
          var resultObjCalulatedData = false
          if (v.customFunc) {
            resultObjCalulatedData = v.customFunc(v.measure.id === value.measure && value.attributes[v.key])
          } else {
            resultObjCalulatedData = String(v.measure.id === value.measure && value.attributes[v.key]) === String(v.value)
          }

          return resultObjCalulatedData
        }).length
        resultObjCalulatedData[ix] = value
      })

      // run calculateSplitAndGroupedResultsPercentages function and put all calculated Percentages and all values in one row in Table
      calculatedResultsAll = this.calculateSplitAndGroupedResultsPercentagesOrSpecialFunc(calculatedResultsAll, resultObjCalulatedData) // new

      // here is only onw raw per iteration
      result.push(calculatedResultsAll)

      return result
    },
    calculateResultsPercentagesOrSpecialFunc (calculatedResultsPercentagesOrMeanMedianSumUnique, result) {
      let totalAnswers = 0
      let specialFunc = 0
      const categorieMultipleValueArrForMean = []
      const allCategoriesArrForMedian = []

      // sum all answers together
      for (const key in result) {
        if (typeof result[key] === 'number') {
          if (this.getAdditionFunc === 'Mean' || this.getAdditionFunc === 'Sum') {
            // put in array all values for Mean
            categorieMultipleValueArrForMean.push(result[key] * this.tableColumnsVariableValues[key])
          } else if (this.getAdditionFunc === 'Median' || this.getAdditionFunc === 'Unique') {
            // put in array all values for Median
            for (let i = 0; i < result[key]; i++) {
              allCategoriesArrForMedian.push(this.tableColumnsVariableValues[key])
            }
          }

          totalAnswers += result[key]
        }
      }

      // put all calculated percentages
      for (const key in result) {
        if (typeof result[key] === 'number') {
          calculatedResultsPercentagesOrMeanMedianSumUnique[key] = totalAnswers > 0 ? ((result[key] / totalAnswers) * 100).toFixed(1) + '%' : 0
        }
      }

      // total prorcentages it's always 100% so can write it manualy
      if (this.getAdditionFunc === 'Count' || this.getAdditionFunc === 'Percentage') {
        calculatedResultsPercentagesOrMeanMedianSumUnique.totalAnswers = '100%'
      } else if (this.getAdditionFunc === 'Mean') {
        specialFunc = this.calculateMean(categorieMultipleValueArrForMean, totalAnswers)
        calculatedResultsPercentagesOrMeanMedianSumUnique.totalAnswers = specialFunc
      } else if (this.getAdditionFunc === 'Median') {
        specialFunc = this.calculateMedian(allCategoriesArrForMedian)
        calculatedResultsPercentagesOrMeanMedianSumUnique.totalAnswers = specialFunc
      } else if (this.getAdditionFunc === 'Sum') {
        specialFunc = this.calculateSum(categorieMultipleValueArrForMean)
        calculatedResultsPercentagesOrMeanMedianSumUnique.totalAnswers = specialFunc
      } else if (this.getAdditionFunc === 'Unique') {
        specialFunc = this.calculateUnique(allCategoriesArrForMedian)
        calculatedResultsPercentagesOrMeanMedianSumUnique.totalAnswers = specialFunc
      }
      // total answers it's caluclated value - sum of all answers
      result.totalAnswers = totalAnswers

      // return it back
      return calculatedResultsPercentagesOrMeanMedianSumUnique
    },
    calculateResultsPercentagesHorizontal (calculatedResultsPercentages, result) {
      let totalAnswers = 0

      // sum all answers together
      for (const key in result) {
        if (typeof result[key] === 'number') {
          totalAnswers += result[key]
        }
      }

      // put all calculated percentages
      for (const key in result) {
        if (typeof result[key] === 'number') {
          calculatedResultsPercentages[key] = totalAnswers > 0 ? ((result[key] / totalAnswers) * 100).toFixed(1) + '%' : 0
        }
      }

      // return it back
      return calculatedResultsPercentages
    },
    calculateSplitResultsPercentagesOrSpecialFunc (calculatedResultsPercentages, result) {
      let totalAnswers = 0
      let specialFunc = 0
      const categorieMultipleValueArrForMean = []
      const allCategoriesArrForMedian = []

      // sum all answers together
      for (const key in result) {
        if (typeof result[key] === 'number') {
          if (this.getAdditionFunc === 'Mean' || this.getAdditionFunc === 'Sum') {
            // put in array all values for Mean
            categorieMultipleValueArrForMean.push(result[key] * this.tableColumnsVariableValues[key])
          } else if (this.getAdditionFunc === 'Median' || this.getAdditionFunc === 'Unique') {
            // put in array all values for Median
            for (let i = 0; i < result[key]; i++) {
              allCategoriesArrForMedian.push(this.tableColumnsVariableValues[key])
            }
          }

          totalAnswers += result[key]
        }
      }

      // put all calculated percentages
      for (const key in result) {
        if (typeof result[key] === 'number') {
          calculatedResultsPercentages[key] = totalAnswers > 0 ? ((result[key] / totalAnswers) * 100).toFixed(1) + '%' : 0
        }
      }

      // total prorcentages it's always 100% so can write it manualy
      // calculatedResultsPercentages.totalAnswers = '100%'
      // total prorcentages it's always 100% so can write it manualy
      if (this.getAdditionFunc === 'Count' || this.getAdditionFunc === 'Percentage') {
        calculatedResultsPercentages.totalAnswers = '100%'
      } else if (this.getAdditionFunc === 'Mean') {
        specialFunc = this.calculateMean(categorieMultipleValueArrForMean, totalAnswers)
        calculatedResultsPercentages.totalAnswers = specialFunc
      } else if (this.getAdditionFunc === 'Median') {
        specialFunc = this.calculateMedian(allCategoriesArrForMedian)
        calculatedResultsPercentages.totalAnswers = specialFunc
      } else if (this.getAdditionFunc === 'Sum') {
        specialFunc = this.calculateSum(categorieMultipleValueArrForMean)
        calculatedResultsPercentages.totalAnswers = specialFunc
      } else if (this.getAdditionFunc === 'Unique') {
        specialFunc = this.calculateUnique(allCategoriesArrForMedian)
        calculatedResultsPercentages.totalAnswers = specialFunc
      }

      result.totalAnswers = totalAnswers

      return calculatedResultsPercentages
    },
    calculateSplitAndGroupedResultsPercentagesOrSpecialFunc (calculatedResultsAll, result) {
      let totalAnswers = 0
      let specialFunc = 0
      const categorieMultipleValueArrForMean = []
      const allCategoriesArrForMedian = []

      // sum all answers together
      for (const key in result) {
        if (typeof result[key] === 'number') {
          if (this.getAdditionFunc === 'Mean' || this.getAdditionFunc === 'Sum') {
            // put in array all values for Mean
            categorieMultipleValueArrForMean.push(result[key] * this.tableColumnsVariableValues[key])
          } else if (this.getAdditionFunc === 'Median' || this.getAdditionFunc === 'Unique') {
            // put in array all values for Median
            for (let i = 0; i < result[key]; i++) {
              allCategoriesArrForMedian.push(this.tableColumnsVariableValues[key])
            }
          }

          totalAnswers += result[key]
        }
      }

      // each iteration put calculated percentage and one calculated answer that are in one string and has newline separator(/n/n)
      for (const key in result) {
        if (typeof result[key] === 'number') {
          calculatedResultsAll[key] = totalAnswers > 0 ? ((result[key] / totalAnswers) * 100).toFixed(1) + '%' + `\n\n${result[key]}` : 0
        }
      }

      // total prorcentages it's always 100% so can write it manualy and add to this string total answers that are with newline separator(/n/n)
      if (this.getAdditionFunc === 'Count' || this.getAdditionFunc === 'Percentage') {
        calculatedResultsAll.totalAnswers = '100%' + `\n\n${totalAnswers}`
      } else if (this.getAdditionFunc === 'Mean') {
        specialFunc = this.calculateMean(categorieMultipleValueArrForMean, totalAnswers)
        calculatedResultsAll.totalAnswers = specialFunc + `\n\n${totalAnswers}`
      } else if (this.getAdditionFunc === 'Median') {
        specialFunc = this.calculateMedian(allCategoriesArrForMedian)
        calculatedResultsAll.totalAnswers = specialFunc + `\n\n${totalAnswers}`
      } else if (this.getAdditionFunc === 'Sum') {
        specialFunc = this.calculateSum(categorieMultipleValueArrForMean)
        calculatedResultsAll.totalAnswers = specialFunc + `\n\n${totalAnswers}`
      } else if (this.getAdditionFunc === 'Unique') {
        specialFunc = this.calculateUnique(allCategoriesArrForMedian)
        calculatedResultsAll.totalAnswers = specialFunc + `\n\n${totalAnswers}`
      }

      return calculatedResultsAll
    },
    onceHandler () {
      this.visible = true
    }
  },
  watch: {
    getSelectedSeries: {
      handler () {
        this.setupData()
      }
    },
    getSplitSeries: {
      handler () {
        this.setupData()
      }
    },
    // sliceSeries: {
    //   handler (val) {
    //     this.setupData()
    //   }
    // },
    isHorizontal: {
      handler () {
        this.setupData()
      }
    },
    getGroupsSeries: {
      handler () {
        this.setupData()
      }
    },
    getAdditionFunc: {
      handler () {
        this.setupData()
      }
    },
    computedData: {
      handler () {
        this.setupData()
      }
    },
    visible: {
      handler () {
        this.setupData()
      }
    }
  },
  mounted () {
    this.setupData()
  }
}
</script>

<style>

</style>
