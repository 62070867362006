<style type="text/css">
  .apexcharts-bar-area {
    margin-top: 30px;
  }
</style>
<template>
  <div class="relative border-2 p-2 h-full w-full bg-white dark:bg-chart-dark dark:border-neutral-600" v-view.once="onceHandler">
    <VueApexCharts v-if="visible || print" ref="chart"  width="100%" height="100%" :options="chartOptions" :series="series"></VueApexCharts>
    <chart-menu v-if="readOnly === false" :chart="chart"></chart-menu>
    <div v-if="!visible" class="bg-neutral-200 dark:bg-neutral-800 animate-pulse h-full w-full"></div>
    <div v-if="this.data.length === 0" class="absolute top-1/2 left-1/2 -translate-x-1/2 text-lg font-bold">
      {{ $t('no-data') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { clone, uniqBy, round } from 'lodash'
import utilsMixin from '@/mixins/utils'
import dayjs from 'dayjs'
import ChartMenu from '@/components/charts/ChartMenu.vue'

var de = require('apexcharts/dist/locales/de.json')

var quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)

export default {
  mixins: [utilsMixin],
  name: 'TimelineChart',
  components: {
    VueApexCharts,
    ChartMenu
  },
  props: {
    chart: {
      type: Object,
      required: true
    },
    isEditedChart: {
      type: Boolean,
      default: false
    },
    annotations: {
      type: Object,
      required: false,
      default: () => {}
    },
    language: {
      type: String,
      default: 'de'
    },
    print: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      height: 0,
      width: null,
      visible: false,
      maxValue: 0,
      hasSameScale: false,
      baseColors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#3F51B5', '#4CAF50', '#F9CE1D', '#FF9800', '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B'],
      seriesData: [],
      isDarkMode: false
    }
  },
  computed: {
    ...mapGetters({
      filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId'
    }),
    data () {
      return this.filteredDataForChartId(this.chart.i, this.chart.filters)
    },
    series () {
      return this.seriesData
    },
    isScaleActive () {
      return !this.hasSameScale
    },
    title () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'title', this.language)
      } else {
        return this.translate(this.chart, 'title', this.chart.languageTabTitle ? this.chart.languageTabTitle : this.language)
      }
    },
    subtitle () {
      if (!this.isEditedChart) {
        return this.translate(this.chart, 'subtitle', this.language)
      } else {
        return this.translate(this.chart, 'subtitle', this.chart.languageTabSubtitle ? this.chart.languageTabSubtitle : this.language)
      }
    },
    isSplit () {
      return this.chart.splits.length > 0
    },
    isGroupped () {
      return this.chart.groups.length
    },
    selectedSeries () {
      return this.chart.data
    },
    splitSeries () {
      return this.chart.splits.length > 0 ? this.chart.splits[0].data : []
    },
    timelineAttribute () {
      return this.chart.timelineAttribute
    },
    canLikert () {
      return this.chart.config !== undefined
    },
    colorMinus10 () {
      return this.chart.colorMinus10
    },
    config () {
      return this.chart.config
    },
    answerKeys () {
      return this.chart.answerKeys
    },
    chartHeight () {
      return this.height
    },
    getTitle () {
      return this.splitTitle(this.title, this.getWidth, { fontSize: '20px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    getSubTitle () {
      return this.splitTitle(this.subtitle, this.getWidth, { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Nunito, sans-serif' })
    },
    colorSheme () {
      return this.isDarkMode ? 'dark' : 'light'
    },
    chartOptions () {
      return {
        title: {
          text: this.getTitle,
          align: 'center',
          offsetY: this.readOnly ? 0 : 35,
          margin: 35,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#333333'
          }
        },
        subtitle: {
          text: this.getSubTitle,
          align: 'center',
          margin: this.getSubTitle ? 5 : 0,
          offsetX: 0,
          offsetY: this.getTitle.length * 25 + (this.readOnly ? 25 : 55),
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Nunito, sans-serif',
            color: this.colorSheme === 'dark' ? '#3a92cf' : '#777777'
          }
        },
        legend: {
          show: true,
          horizontalAlign: 'left',
          offsetX: 250,
          offsetY: 5
        },
        theme: {
          mode: this.colorSheme
        },
        markers: {
          size: 5,
          colors: this.baseColors,
          hover: {
            size: 7,
            sizeOffset: 3
          }
        },
        chart: {
          id: 'basic-bar',
          type: 'line',
          locales: [de],
          defaultLocale: 'de',
          zoom: {
            type: 'x',
            enabled: false,
            autoScaleYaxis: false
          },
          toolbar: {
            show: false,
            offsetX: -35,
            tools: {
              download: false
            }
          },

          events: {
            mounted: (chart) => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              chart.windowResizeHandler()
            },
            updated: () => {
              if (this.$refs.chart === undefined) return
              this.height = this.$refs.chart.$el.clientHeight
              this.width = this.$refs.chart.$el.clientWidth
              if (this.showErrorBars) {
                this.buildErrorBars()
              }
            }
          }
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime'
          // tickAmount: ,
          // min: dayjs('01.01.2022', 'DD.MM.YYYY').quarter(1).format('DD.MM.YYYY'),
          // max: dayjs('01.01.2024', 'DD.MM.YYYY').quarter(2).format('DD.MM.YYYY'),
          // categories: [dayjs('01.01.2022', 'DD.MM.YYYY').quarter(1).format('DD.MM.YYYY')],
          // tickAmount: 6
        },
        yaxis: {
          min: 0,
          max: this.data.length > 0 ? this.meanMax : 0,
          tickAmount: this.data.length > 0 ? this.meanMax : 0
        },
        tooltip: {
          y: {
            formatter: (val, { dataPointIndex, seriesIndex }) => {
              if (this.seriesData[seriesIndex].data[dataPointIndex] !== undefined) {
                return `${val} (${this.$t('count')}: ${this.seriesData[seriesIndex].data[dataPointIndex].total})`
              } else {
                return ''
              }
            }
          },
          x: {
            formatter: (val) => {
              const dateTmp = dayjs(val)
              return `Q${dateTmp.quarter()} ${dateTmp.year()}`
            }
          },
          shared: true,
          intersect: false
        },
        annotations: this.getAnnotations,
        // Add space for the annotation to avoid overlapping with the x-axis labels
        grid: {
          padding: {
            bottom: 25
          }
        }
      }
    },
    customLegendItems () {
      var tmp = [this.$t('mean', this.language)]
      if (this.chart.groups.length) {
        tmp = []
        this.chart.groups.forEach(group => {
          group.data.forEach(v => {
            tmp.push(this.translate(v, 'label', this.language))
          })
        })
      }
      return tmp
    },

    getAnnotations () {
      var tmp = {
        texts: []
      }
      if (this.answers.length && !this.isScaleActive) {
        tmp.texts = [{
          y: this.isSplit ? this.chartHeight - 20 : this.chartHeight - 15,
          x: 18,
          text: `${this.meanMin}  = ${[...new Set(this.answers.map(a => a[0]))].join(' | ')}`,
          appendTo: '.apexcharts-annotations',
          fontWeight: 'bold'
        },
        {
          x: 18,
          y: this.isSplit ? this.chartHeight - 6 : this.chartHeight - 3,
          text: `${this.meanMax} = ${[...new Set(this.answers.map(a => a[this.meanMaxLabel]))].join(' | ')}`,
          appendTo: '.apexcharts-annotations',
          fontWeight: 'bold'
        }]
      }
      if (this.annotations.texts !== undefined) {
        tmp.texts = [...tmp.texts, ...this.annotations.texts]
      }
      return tmp
    },
    categories () {
      var result = []
      const hasMultipleMeasures = uniqBy(this.selectedSeries, v => v.measure ? v.measure.id : 0).length > 1
      this.selectedSeries.forEach(v => {
        var label = ''
        if (v.label) {
          label = this.translate(v, 'label', this.language) || v.key
        } else {
          label = this.translate(v, 'title', this.language) || v.key
        }
        if (hasMultipleMeasures && v.measure) {
          label = `${v.measure.name}: ${label}`
        }
        var tmp = this.splitter(label, this.isRadar ? 30 : 60)
        result.push(tmp)
      })
      return result
    },
    answers () {
      var objects = []
      Object.entries(this.config).forEach(([key, value]) => {
        var neg = clone(value.negativeValues)
        neg.reverse()

        const tmp = neg.concat(value.positiveValues)
        objects.push({ measure: parseInt(key), values: tmp })
      })

      var result = []

      this.selectedSeries.filter(s => s.data !== undefined).forEach((serie) => {
        var labels = []
        objects.forEach(obj => {
          obj.values.forEach(value => {
            const entry = serie.data.find(d => d.measure.id === obj.measure && String(d.value) === String(value))
            if (entry !== undefined) {
              labels.push(this.translate(entry, 'label', this.language) || entry.key)
            }
          })
        })
        result.push(labels)
      })
      return result
    },
    meanMax () {
      if (this.maxValue) {
        return round(this.maxValue + 2)
      }
      if (this.isScaleActive) {
        return 100
      }
      const config = Object.values(this.config)[0]

      if (config.meanMax !== undefined) {
        return parseInt(config.meanMax)
      }

      return config.negativeValues.length + config.positiveValues.length
    },
    meanMaxLabel () {
      if (this.isScaleActive) {
        return 100
      }
      const config = Object.values(this.config)[0]

      if (config.meanMax !== undefined) {
        return parseInt(config.meanMax)
      }
      return config.negativeValues.length + config.positiveValues.length - 1
    },
    meanMin () {
      if (this.isScaleActive) {
        return 0
      }
      const config = Object.values(this.config)[0]
      if (config.meanMin !== undefined) {
        return parseInt(config.meanMin)
      }
      return 1
    },
    getWidth () {
      return this.width
    }
  },
  methods: {
    setupData () {
      if (!this.visible || this.data.length <= 0) {
        this.seriesData = []
        return
      }
      this.reset()
      var result = []
      // Groupieren nach Attribut
      if (this.chart.groups.length > 0) {
        var results = []
        this.chart.groups.forEach(group => {
          group.data.forEach(v => {
            result = []
            const filtered = this.data.filter(d => v.value ? d.attributes[group.key]?.toString() === v.value.toString() : v.customFunc ? v.customFunc(d.attributes[group.key]) : false)
            if (filtered.length > 0) {
              if (this.isSplit) {
                result = this.buildSplitSeries(result, filtered)
              } else {
                result = this.buildSeries(result, filtered)
              }
              result.forEach(r => {
                results.push({
                  name: this.translate(v, 'label', this.language) + ' ' + r.name,
                  data: r.data
                })
              })
            }
          })
        })
        this.seriesData = results
        return
      }
      if (this.isSplit) {
        result = this.buildSplitSeries(result, this.data)
      } else {
        result = this.buildSeries(result, this.data)
      }
      this.seriesData = result
    },
    reset () {
      this.maxValue = 0
      this.checkScale()
    },
    checkScale () {
      var total = -1
      var tmp = []
      if (this.selectedSeries.keys) {
        tmp = this.selectedSeries.filter(f => f.keys).map(d => d.keys).flat()
      } else {
        tmp = [...tmp, ...this.selectedSeries.filter(f => f.keys === undefined)]
      }
      for (const serie of tmp) {
        var config = Object.values(serie.config)[0]
        var tmpTotal = config.negativeValues.length + config.positiveValues.length
        if (total > -1 && tmpTotal !== total) {
          this.hasSameScale = false
          return
        }
        total = tmpTotal
      }
      this.hasSameScale = true
    },
    onceHandler () {
      this.visible = true
    },
    buildSplitSeries (result, rawData) {
      this.splitSeries.forEach((splitValue) => {
        this.selectedSeries.forEach((attr) => {
          var data = []
          var attributes = []
          if (attr.keys) {
            attributes = attr.keys
          } else {
            attributes = [attr]
          }
          attributes.forEach(v => {
            rawData.forEach(value => {
              if (String(value.attributes[splitValue.key]) === String(splitValue.value)) {
                const measure = value.measure
                if (v.answerKeys[measure]) {
                  const isInNeg = this.config[measure].negativeValues.includes(String(value.attributes[v.key]))
                  const isInPos = this.config[measure].positiveValues.includes(String(value.attributes[v.key]))
                  var ix = 0
                  if ((isInNeg || isInPos)) {
                    if (isInNeg) {
                      ix = this.config[measure].negativeValues.length - 1 - this.config[measure].negativeValues.indexOf(String(value.attributes[v.key]))
                    } else {
                      ix = this.config[measure].negativeValues.length + this.config[measure].positiveValues.indexOf(String(value.attributes[v.key]))
                    }
                    data.push({ x: value.attributes[this.timelineAttribute], y: ix + 1 })
                  }
                }
              }
            })
          })

          var tmp = this.setupTimeData(data, attributes.length)

          result.push({
            name: this.translate(splitValue, 'label', this.language),
            data: tmp
          })
        })
      })
      return result
    },
    buildSeries (result, rawData) {
      this.selectedSeries.forEach((attr, serieIx) => {
        var data = []
        var attributes = []
        if (attr.keys) {
          attributes = attr.keys
        } else {
          attributes = [attr]
        }
        attributes.forEach(v => {
          rawData.forEach(value => {
            const measure = value.measure
            if (v.answerKeys[measure]) {
              const isInNeg = this.config[measure].negativeValues.includes(String(value.attributes[v.key]))
              const isInPos = this.config[measure].positiveValues.includes(String(value.attributes[v.key]))
              var ix = 0
              if ((isInNeg || isInPos)) {
                if (isInNeg) {
                  ix = this.config[measure].negativeValues.length - 1 - this.config[measure].negativeValues.indexOf(String(value.attributes[v.key]))
                } else {
                  ix = this.config[measure].negativeValues.length + this.config[measure].positiveValues.indexOf(String(value.attributes[v.key]))
                }
                data.push({ x: value.attributes[this.timelineAttribute], y: ix + 1 })
              }
            }
          })
        })
        var tmp = this.setupTimeData(data, attributes.length)

        result.push({
          name: this.categories[serieIx],
          data: tmp
        })
      })
      return result
    },
    setupTimeData (data, attributesCount) {
      var tmp = data.filter(v => v.x !== '').map(v => { return { x: dayjs(v.x, 'DD.MM.YYYY').startOf('quarter').format('DD.MM.YYYY'), y: v.y } })
      // Calculate the sums and group data (while tracking count)
      const reduced = tmp.reduce(function (m, d) {
        if (!m[d.x]) {
          m[d.x] = { ...d, count: 1 }
          return m
        }
        m[d.x].y += d.y
        m[d.x].count += 1
        return m
      }, {})

      // Create new array from grouped data and compute the average
      var avg = Object.keys(reduced).map(function (k) {
        const item = reduced[k]
        return {
          x: item.x,
          y: round(item.y / item.count, 2),
          total: round(item.count / attributesCount, 0)
        }
      })

      tmp = avg.map(v => { return { x: dayjs(v.x, 'DD.MM.YYYY').toDate(), y: v.y, total: v.total } })
      tmp = tmp.sort((a, b) => dayjs(a.x) - dayjs(b.x))
      return tmp
    }
  },
  mounted () {
    this.setupData()
    this.isDarkMode = document.documentElement.classList.contains('dark')
  },
  watch: {
    selectedSeries: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    config: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    splitSeries: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    groups: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    isGroupped: {
      handler () {
        if (this.$parent.isPreview) {
          this.setupData()
        }
      }
    },
    data: {
      handler () {
        this.setupData()
      }
    },
    visible: {
      handler () {
        this.setupData()
      }
    }
  }
}
</script>
