<template>
<loading-spinner v-if="isLoading"></loading-spinner>
<div v-else>
    <div class="">
      <!-- table in tab: einstellungen -->
       <template v-if="restrictToMoniq">
          <v-table-moniq
            v-if="selected"
            :headers="fields"
            :items="isMulti ? selected : [selected]" :language="language" :pickTranslationFromJson="pickTranslationFromJson">
          </v-table-moniq>

          <label v-tip="'evaluations'">{{ pickTranslationFromJson(currentEvaluationLang, 'view-permissions') }}</label>
          <v-table-moniq
            v-if="selectedUsers"
            :headers="fields"
            :items="isMulti ? selectedUsers : [selectedUsers]" :language="language" :pickTranslationFromJson="pickTranslationFromJson">
          </v-table-moniq>
        </template>
        <template v-else>
          <v-table
            v-if="selected"
            :headers="fields"
            :items="isMulti ? selected : [selected]" :language="language" :pickTranslationFromJson="pickTranslationFromJson"
            :ignoreLink="true"
            :ignoreHideList="true"
            :canDelete="!isReadOnly"
            @item-delete="removeItem">
          </v-table>
      </template>
    <div v-if="!selected">
      Keine Daten
    </div>
    <template v-if="!isReadOnly && condition && !restrictToMoniq">
      <template v-if="isMulti && !hideAdd">
        <!-- open btn -->
        <button v-if="selected" class="w-full mt-2" @click="openModal">{{isInEvaluation() ?  (pickTranslationFromJson() ? pickTranslationFromJson(language, 'grant'): $t('grant')) : (pickTranslationFromJson() ? pickTranslationFromJson(language, 'change-selection') : $t('change-selection'))  }}{{isInEvaluation() ? '' :' ' + $t(`${getterPath.replace('/', '.')}`) }}</button>
        <button v-else class="w-full mt-2" @click="openModal">{{defaultLabelNone}}</button>
      </template>
      <template v-else-if="!hideAdd">
        <button v-if="selected" class="w-full mt-2" @click="openModal">{{  pickTranslationFromJson(language, 'do-change') }} {{$t(`${getterPath.replace('/', '.')}`)}}...</button>
        <button v-else class="w-full mt-2" @click="openModal">{{defaultLabelNone}}</button>
      </template>
    </template>
    </div>
  <div v-if="open" class="mpointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center p-12 z-50">
    <div class="absolute w-full h-full bg-neutral-900 opacity-50"></div>

    <div class="flex flex-col bg-white dark:bg-neutral-800 w-2/3 h-full mx-auto rounded shadow-lg z-50 overflow-hidden">
      <div class="w-full flex items-center p-4 z-40 mr-10">
        <h1 class="flex-grow">{{pickTranslationFromJson() !== '' ? pickTranslationFromJson(language, 'accounts.users'): $t(`${getterPath.replace('/', '.')}`)}}</h1>
        <div class="relative mr-2">
          <input class="rounded-full pl-10" :placeholder="pickTranslationFromJson() !== '' ? pickTranslationFromJson(language, 'search-for'): $t('search-for')" v-debounce:300ms="searchObject">
          <svg class="absolute top-0 left-0 mt-3 ml-3 text-neutral-600 h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px"><path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" /></svg>
          <i v-if="searchText !== ''" class="absolute top-0 right-0 shrink mr-3 mt-2 text-neutral-700 hover:text-red-600 material-icons cursor-pointer"
              @click="searchText = ''"
          >
          close
          </i>
        </div>
      </div>
      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="text-left relative h-full overflow-y-auto px-2">
        <p v-if="isMulti && noSizeParams !== true" class="p-2 bg-neutral-200 dark:bg-neutral-600">
        {{ pickTranslationFromJson() !== '' ? pickTranslationFromJson(language, 'relation-select-text'):  $t('relation-select-text') }}
    </p>
    <!-- pop-up window table in einstellungen tab when I click: Administratitionsrechte erteilen -->
          <v-table
            v-if="isMulti"
            :headers="fields" :items="items" :showSelect="true" :language="language" :pickTranslationFromJson="pickTranslationFromJson"
            :ignoreHideList="true"
            :selection="selected"
            :itemKey="null"
            :ignoreLink="true"
          >
          </v-table>
          <v-table
            v-else
            :headers="fields" :items="items" :language="language" :pickTranslationFromJson="pickTranslationFromJson"
            :selectable="true"
            :ignoreHideList="true"
            :ignoreLink="true"
            @click="onClick"
          >
          </v-table>
      </div>
      <div class="w-full flex space-x-3 px-3 py-3 bg-white dark:bg-neutral-800">
        <button v-if="isMulti" class="primary w-full" @click="addAll">{{ pickTranslationFromJson() !== '' ? pickTranslationFromJson(language, 'add-all') : $t('add-all') }} ({{count}})</button>
        <button v-if="isMulti" class="primary w-full" @click="done">{{ pickTranslationFromJson() !== '' ? pickTranslationFromJson(language, 'ok') : $t('ok') }}</button>
        <button v-if="!isMulti" class="cancel w-full" @click="open = false">{{ pickTranslationFromJson() !== '' ? pickTranslationFromJson(language, 'cancel') : $t('cancel') }}</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import VTable from '@/components/v-table.vue'
import VTableMoniq from '@/components/moniqNEU/v-table.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    'v-table': VTable,
    'v-table-moniq': VTableMoniq,
    LoadingSpinner
  },
  props: {
    value: {},
    field: {},
    getterPath: {},
    query: {},
    initQuery: {},
    isMulti: {},
    hideAdd: {},
    isReadOnly: {},
    labelNone: {},
    itemField: {},
    customFields: {},
    noSizeParams: {},
    condition: {
      type: Boolean,
      default: true
    },
    language: {
      type: String,
      default: 'de'
    },
    pickTranslationFromJson: {
      type: Function,
      default: () => ''
    }
  },
  data () {
    return {
      loading: false,
      open: false,
      searchText: '',
      selected: this.isMulti ? [] : null,
      selectedUsers: this.isMulti ? [] : null,
      currentURL: window.location.pathname,
      currentEvaluationLang: null
    }
  },
  computed: {
    ...mapGetters({
      currentEvaluation: 'results/evaluations/detailItem'
    }),
    restrictToMoniq () {
      return this.currentEvaluation && this.currentEvaluation.content_type === 14
    },
    getLanguage () {
      if (this.language) {
        return this.language
      }
      if (!this.user) {
        return 'de'
      }
      if (this.user.language === 2) {
        return 'fr'
      }
      if (this.user.language === 3) {
        return 'it'
      }
      return 'de'
    },
    fields () {
      return this.customFields || this.$store.getters[`${this.getterPath}/fields`].filter(h => h.showSelect)
    },
    isLoading () {
      return this.loading
    },
    items () {
      return this.$store.getters[`${this.getterPath}/all`]
    },
    count () {
      return this.$store.getters[`${this.getterPath}/pagination`] ? this.$store.getters[`${this.getterPath}/pagination`].count : this.$store.getters[`${this.getterPath}/all`].length
    },
    defaultLabelNone () {
      return this.labelNone || 'Wähle ' + this.$t(`${this.getterPath.replace('/', '.').substring(0, this.getterPath.length - 1)}`) + '...'
    }
  },
  methods: {
    isInEvaluation () {
      return this.$route.path.startsWith('/results/evaluations/')
    },
    onEscKeyPress (event) {
      if (event.key === 'Escape' && this.open) {
        this.open = false
      }
    },
    removeItem (item) {
      if (this.isMulti) {
        const index = this.selected.findIndex(s => s.id === item.id)
        this.selected.splice(index, 1)
        this.$emit('input', this.selected.map(s => s.id))
      } else {
        this.$emit('input', null)
        this.selected = null
      }
    },
    async openModal () {
      this.open = true
    },
    onClick (item) {
      if (!this.isMulti) {
        this.$emit('input', this.itemField ? item[this.itemField] : item.id)
        this.selected = item
        this.open = false
      }
    },
    async addAll () {
      const tmp = await this.$store.dispatch(`${this.getterPath}/load`, { all: true, callback: true, query: { ...this.query } })
      this.selected = []
      this.selected.push(...tmp)
      this.$emit('input', this.selected.map(s => this.itemField ? s[this.itemField] : s.id))
      this.open = false
    },
    done () {
      this.$emit('input', this.selected.map(s => this.itemField ? s[this.itemField] : s.id))
      this.open = false
    },
    async searchObject (val) {
      await this.$store.dispatch(`${this.getterPath}/load`, { query: { ...this.query, size: 15, search: val }, all: true })
    }
  },
  async mounted () {
    this.currentEvaluationLang = this.getLanguage
    this.$root.$on('usersAdminChanged', () => {
      // this.selected = newValue
    })
    const removeSizeParams = this.noSizeParams && this.noSizeParams === true ? { all: true } : {}
    await this.$store.dispatch(`${this.getterPath}/load`, { query: { ...this.query, size: 15 }, all: true, ...removeSizeParams })
    this.loading = true
    // here need to filter selected and items compare all coincidence and fullfill selected
    if (this.isMulti) {
      if (this.value) {
        if (this.initQuery) {
          const items = await this.$store.dispatch(`${this.getterPath}/load`, { callback: true, all: true, query: this.initQuery })
          this.selected.push(...items)
        } else {
          let checker = this.value
          if (this.getterPath === 'accounts/users') {
            const check = this.items.map(_ => _.id)
            checker = this.value.filter(_ => check.includes(_))
          }

          if (this.restrictToMoniq) {
            checker = this.currentEvaluation.view_access_admins || []
            const checkers = this.currentEvaluation.view_access_users || []
            for (const element of checkers) {
              const items = await this.$store.dispatch(`${this.getterPath}/getDetail`, element)
              if (items) {
                this.selectedUsers.push(items)
              }
            }
          }
          for (const element of checker) {
            const item = await this.$store.dispatch(`${this.getterPath}/getDetail`, element)
            if (item) {
              this.selected.push(item)
            }
          }
        }
        if (this.restrictToMoniq) { this.$emit('input', []) } else {
          this.$emit('input', this.selected.map(s => s.id))
        }
      }
    } else {
      if (this.$route.query[this.field] || this.value) {
        var id = null
        if (this.value) {
          id = Object.keys(this.value).length > 0 ? this.value.id : this.value
        }
        this.selected = await this.$store.dispatch(`${this.getterPath}/getDetail`, this.$route.query[this.field] || id)
        this.$emit('input', this.selected.id)
      }
    }
    this.loading = false
    window.addEventListener('keydown', this.onEscKeyPress)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.onEscKeyPress)
  }

}
</script>
