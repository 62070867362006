<template>
  <div>
    <div v-for="measure in measures" :key="measure">
      <div class="bg-neutral-200 dark:bg-neutral-700 p-3 mb-2 mt-2 font-bold">
        {{measureById(measure).name}}
      </div>
      <b>{{ translate(last_import_translation, 'lastImport', language) }}</b> {{formatDateTime(measureById(measure).last_import_at)}}<br>
      <b>{{ translate(data_count_translation, 'dataCount', language) }}</b> {{rawItems[measure].length}}<br>
      <b>{{ translate(data_count_translation_full, 'dataCountFull', language) }}</b> {{fullItems[measure].length}}
    </div>
    <div class = "mt-4">
      <button @click="download_filter(false)" class="primary mb-2 w-full">{{ translate(download_raw_data_translation_full, 'downloadRawDataFull', language) }}</button>
      <button @click="download_filter(true)" class="primary w-full">{{ translate(download_raw_data_translation, 'downloadRawData', language) }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateMixin from '@/mixins/date'
import utilsMixin from '@/mixins/utils'
import { uniqBy } from 'lodash'
import JSZip from 'jszip'

export default {
  mixins: [dateMixin, utilsMixin],
  props: {
    fullItems: {
      type: Object,
      required: true
    },
    rawItems: {
      type: Object,
      required: true
    },
    rawHeaders: {
      type: Object,
      required: true
    },
    measures: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      default: 'de'
    }
  },
  components: {

  },
  data: function () {
    return {
      last_import_translation: { lastImport: 'Letzter Import:', lastImport_fr: 'Dernière importation:', lastImport_it: 'Ultima importazione:' },
      data_count_translation: { dataCount: 'Anzahl Daten (nach Filter Global):', dataCount_fr: 'Nombre de données (après filtre global):', dataCount_it: 'Numero di dati (filtro globale):' },
      data_count_translation_full: { dataCountFull: 'Anzahl Daten:', dataCountFull_fr: 'Nombre de données:', dataCountFull_it: 'Numero di dati:' },
      download_raw_data_translation: { downloadRawData: 'Rohdaten (nach Filter Global) herunterladen', downloadRawData_fr: 'Télécharger les données brutes (après filte global)', downloadRawData_it: 'Scaricare i dati grezzi (filtro globale)' },
      download_raw_data_translation_full: { downloadRawDataFull: 'Rohdaten herunterladen', downloadRawDataFull_fr: 'Télécharger les données brutes', downloadRawDataFull_it: 'Scaricare i dati grezzi' },
      title: ''
    }
  },
  computed: {
    ...mapGetters({
      measureById: 'results/measures/getById',
      sizeDataForMeasureId: 'results/evaluationStore/sizeDataForMeasureId',
      titleOfEvaluation: 'results/evaluations/title'
    }),
    allAttributes () {
      return Object.values(this.series).flat()
    },
    measureSelection () {
      return this.measureSelect.filter(m => this.measures.indexOf(m.id) > -1)
    }
  },
  methods: {
    measureSelected () {
      this.measureObject = this.measureById(this.measure)
    },
    escapeSemicolonsAndQuote(str) {
      const escapedStr = str
        .replace(/;/g, '\\;') // Escape semicolons
        .replace(/"/g, '""'); // Escape quotes
      return `"${escapedStr}"`;
    },
    convertObjectToCSV (obj, headers) {
      let csv = ''
      const headerSet = new Set(headers.map(header => header.value))

      // this.rawHeaders sometiems contains missing keys - optional
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach(item => {
            Object.keys(item).forEach(k => {
              if (!headerSet.has(k)) {
                headerSet.add(k)
              }
            })
          })
        }
      }

      const finalHeaders = Array.from(headerSet)
      csv += finalHeaders.join(';') + '\n'

      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item) => {
            const row = finalHeaders.map(header => {
              let value = item[header] !== undefined ? item[header] : ''
              if (typeof value === 'string') {
                value = this.escapeSemicolonsAndQuote(value).replace(/\n/g, ' ').replace(/\r/g, ' ').replace(/\t/g, ' ').replace(/,/g, ' ')
              }
              return value
            })
              .join(';')
            csv += row + '\n'
          })
        }
      }
      // console.log('csv',csv)
      return csv
    },
    generateCSVFromAttributes (measure) {
      const dataValues = []
      const keysToDelete = []

      measure.default_attributes.forEach(group => {
        let groupname = group.name

        group.data.forEach((data, ix) => {
          if (data.map && data.map.length > 0) {
            if (!keysToDelete.includes(data)) {
              const filterAttributes = uniqBy(data.map, v => [v.to].join())
              filterAttributes.forEach((mapValue, index) => {
                if (index === 0) {
                  dataValues.push({
                    Gruppe: groupname,
                    Frage: data.label,
                    FrageCode: data.name_new,
                    Antwort: mapValue.label,
                    AntwortCode: mapValue.to || mapValue.from
                  })
                  groupname = ''
                } else {
                  dataValues.push({
                    Gruppe: '',
                    Frage: '',
                    FrageCode: '',
                    Antwort: mapValue.label,
                    AntwortCode: mapValue.to || mapValue.from
                  })
                }
              })
            }
          } else if (data.options && data.options.length > 0) {
            data.options.forEach((option, index) => {
              if (index === 0) {
                dataValues.push({
                  Gruppe: groupname,
                  Frage: data.label,
                  FrageCode: data.name_new,
                  Antwort: option.label,
                  AntwortCode: option.name_new
                })
                groupname = ''
              } else {
                dataValues.push({
                  Gruppe: '',
                  Frage: '',
                  FrageCode: '',
                  Antwort: option.label,
                  AntwortCode: option.name_new
                })
              }
            })
          } else {
            if (ix === 0) {
              dataValues.push({
                Gruppe: groupname,
                Frage: data.label,
                FrageCode: data.name_new,
                Antwort: '',
                AntwortCode: ''
              })
              groupname = ''
            } else {
              dataValues.push({
                Gruppe: '',
                Frage: data.label,
                FrageCode: data.name_new,
                Antwort: '',
                AntwortCode: ''
              })
            }
          }
        })
      })

      let csv = 'Gruppe;Frage;FrageCode;Antwort;AntwortCode\n'

      dataValues.forEach(row => {
        const cleanedRow = {
          Gruppe: row.Gruppe.replace(/\n/g, ' ').replace(/\r/g, ' ').replace(/\t/g, ' '),
          Frage: row.Frage.replace(/\n/g, ' ').replace(/\r/g, ' ').replace(/\t/g, ' '),
          FrageCode: row.FrageCode.replace(/\n/g, ' ').replace(/\r/g, ' ').replace(/\t/g, ' '),
          Antwort: row.Antwort.replace(/\n/g, ' ').replace(/\r/g, ' ').replace(/\t/g, ' '),
          AntwortCode: row.AntwortCode.replace(/\n/g, ' ').replace(/\r/g, ' ').replace(/\t/g, ' ')
        }

        csv += `${cleanedRow.Gruppe};${cleanedRow.Frage};${cleanedRow.FrageCode};${cleanedRow.Antwort};${cleanedRow.AntwortCode}\n`
      })

      return csv
    },
    openDatabase () {
      return new Promise((resolve, reject) => {
        const dbName = 'myDatabase'
        const dbVersion = 1
        const request = indexedDB.open(dbName, dbVersion)

        request.onupgradeneeded = (event) => {
          const db = event.target.result
          if (!db.objectStoreNames.contains('myObjectStore')) {
            db.createObjectStore('myObjectStore', {
              keyPath: 'id',
              autoIncrement: true
            }) // Assuming 'id' as the primary key
          }
        }

        request.onsuccess = (event) => {
          this.db = event.target.result
          resolve(this.db)
        }

        request.onerror = (event) => {
          console.error('Error opening database', event)
          reject(event)
        }
      })
    },
    async download_filter (param) {
      const items = param ? this.rawItems : this.fullItems
      const zip = new JSZip()

      const timestamp = new Date().toISOString().replace(/[-:.]/g, '')
      const filterFilename = `Daten_mit_Filter_Global_${timestamp}.zip`
      const fullFilename = `Daten_ohne_Filter_${timestamp}.zip`
      const filename = param ? filterFilename : fullFilename

      for (const measureId in items) {
        let csvContent1 = '\uFEFF'
        let csvContent2 = '\uFEFF'
        const headers = this.rawHeaders[measureId]
        csvContent1 += this.convertObjectToCSV({ [measureId]: items[measureId] }, headers)
        const measure = this.measureById(measureId)
        csvContent2 += this.generateCSVFromAttributes(measure)

        const maxLength = 30
        const safeTitle = this.title.replace(/[^a-zA-Z0-9]/g, '_')
        const truncatedTitle = safeTitle.length > maxLength
          ? safeTitle.slice(0, maxLength - 3) + '...'
          : safeTitle

        const measureFolder = zip.folder(`${truncatedTitle}_${measureId}`)

        measureFolder.file(`${truncatedTitle}_${measureId}_data.csv`, csvContent1, { compression: 'STORE' })
        measureFolder.file(`${truncatedTitle}_${measureId}_codebook.csv`, csvContent2, { compression: 'STORE' })
      }

      const blob = await zip.generateAsync({ type: 'blob' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    getFullSizeofMeasure (measureId) {
      return this.sizeDataForMeasureId(measureId)
    }
  },
  mounted () {
    this.title = this.titleOfEvaluation()
  }
}
</script>
